////
/// @author Tim Hartmann
////

/// Clear your floats with this simple mixin
/// @name clearfix
@mixin clearfix() {
	&:after {
		content: '';
		clear: both;
		display: table;
	}
}

/// Reset default styles of lists
/// @name list-reset
@mixin list-reset() {
	list-style: none;
	margin: 0;
	padding: 0;
}

/// Calculate the width of a column with ease
/// @name col
@mixin col($col, $max-cols: 12) {
  width: calc((100%/$max-cols))*$col;
}

/// Add a breakpoint with ease
/// @name respond
@mixin respond($breakpoint) {
	$value: map-get($breakpoints, $breakpoint);

	@if $value != null {
		@media screen and (min-width: $value) {
			@content;
		}
	} @else {
		@warn 'Unfortunately, no value could be retrieved from `#{$breakpoint}`. '
        + 'Please make sure it is defined in `$breakpoints` map.';
	}
}

/// Create an arrow with pure CSS
/// @name triangle
/// @param {string} $direction - Possible values are 'bottom', 'top', 'left' or 'right'
/// @param {string} $size - How big should be you triangle?
/// @param {string} $color - Add your required color
@mixin triangle($direction, $size, $color) {
	  @if $direction == 'bottom' {
    border-left: $size solid transparent;
    border-right: $size solid transparent;
    border-top: $size solid $color;

    @content;
  }
  @if $direction == 'top' {
    border-left: $size solid transparent;
    border-right: $size solid transparent;
    border-bottom: $size solid $color;

    @content;
  }
  @if $direction == 'left' {
    border-bottom: $size solid transparent;
    border-right: $size solid $color;
    border-top: $size solid transparent;

    @content;
  }
  @if $direction == 'right' {
    border-bottom: $size solid transparent;
    border-left: $size solid $color;
    border-top: $size solid transparent;

    @content;
  }
}

/// @name font-reset
@mixin font-reset() {
  -webkit-font-smoothing: antialiased;
     -moz-font-smoothing: grayscale;
}

/// @name box-sizing
@mixin box-sizing($value: border-box) {
  -webkit-box-sizing: $value;
          box-sizing: $value;
}

/// @name placeholder
@mixin placeholder() {
    &:-moz-placeholder { @content; }
    &::-moz-placeholder { @content; }
    &:-ms-input-placeholder { @content; }
    &::-webkit-input-placeholder { @content; }
}