// Same styles used in .case (Cases-Manager caselist) and #case-header (Open case)
// The id selectors are used in #case-header, the class selectors in .case

#case-status-display,
.case-status-display {
  background: $light-bg;
  width: 200px;
}

#case-result-label,
.case-result-label {
  display: none;
}
#case-result-sign,
.case-result-sign {
  display: block;
  float: left;
  margin-right: 5px;
  margin-top: 0;
  font-size: 14px;


  #case-result-positive &,
  .case-result-positive & {
    color: $case-result-positive-font-color;
  }
  #case-result-negative &,
  .case-result-negative & {
    color: $case-result-negative-font-color;
  }
}
#case-result-value,
.case-result-value {
  display: inline-block;
  padding: 3px 6px;
  vertical-align: middle;
  border-radius: $base-border-radius;
  background: $case-result-positive-bg;
  color: $case-result-positive-font-color;
  font-size: 38px;
  font-weight: normal;
  line-height: 1;
  white-space: nowrap;

  #case-result-positive &,
  .case-result-positive & {
    background: $case-result-positive-bg;
    color: $case-result-positive-font-color;
  }
  #case-result-negative &,
  .case-result-negative & {
    background: $case-result-negative-bg;
    color: $case-result-negative-font-color;
    font-weight: normal;
  }
}
#case-result-positive,
.case-result-positive {
  #case-result-label,
  #case-result-sign {
    margin-top: 0;
  }
}

.case-result-value-potential {
  background-color: grey !important;
}

#case-result-sign {
  display: inline-block;
  margin-right: 10px;
  float: none;
  vertical-align: middle;
  color: get-font(color);
  font-size: 21px;
  font-weight: normal;
}

#case-result-negative,
.case-result-negative {
  #case-result-label,
  #case-result-sign {
    margin-top: 0;
    color: get-font(color);
  }
}
