////
/// @group Notifications
/// @author Tim Hartmann
////

// 1.Config
$notifications: (
	background: $alert-bg,
	color: $alert-color
) !default;

// 2.Base
/// @name Notification
.case-editor-interview-alert,
.smst-warn-dirty-filled {
	background: map-get($notifications, background);
	color: map-get($notifications, color);

	a {
		border-bottom-color: map-get($notifications, color);
		color: map-get($notifications, color);

		&:hover {
			border-bottom: none;
		}
	}

	.smst-warn {
		border: none;
	}
}

.smst-warn-dirty-filled {
	&:before {
		content: '';
		display: block;
		height: 100%;
		left: -1px;
		position: absolute;
		top: -1px;
		width: 100%;
		z-index: 1;
		border: 1px solid map-get($notifications, background);
		background: map-get($notifications, background);
	}
	> * {
		position: relative;
		z-index: 2;
	}
}

.smst-install-banner {
	position: fixed;
	bottom: 0px;
	left: 50%;
	width: 318px;
	font-size: 16px;
	text-align: left;
	-webkit-transform: translateX(-50%);
	-moz-transform: translateX(-50%);
	-ms-transform: translateX(-50%);
	-o-transform: translateX(-50%);
	transform: translateX(-50%);
	z-index: 1001;
	background-color: #f0f0f0;
	border: 1px solid #c0c0c0;
	display: none;

	button {
		color: rgb(208, 106, 17);
		background-color: #f0f0f0;
		border: none;
		padding-top: 5px;
		padding-bottom: 5px;
		padding-left: 10px;
	}

	.close-button {
		position: absolute;
		right: 0px;
		top: 4px;
		color: rgb(208, 106, 17);
		font-size: 22px;
		padding-right: 5px;
	}
}