// 1.Config
$column-wrapper: 'l-units' !default;
$column-prefix: 'l-u--' !default;
$section-wrapper: 'l-constrained' !default;

// 2.Base
// Name: Constrained-Container
// Description:
.#{$section-wrapper} {
  margin-left: auto;
  margin-right: auto;
  max-width: $max-wrapper-width;
  padding-left: $prevent-spacing;
  padding-right: $prevent-spacing;
}

// Name: Units-Wrapper
// Description: Each columns-area get a wrapper
.#{$column-wrapper} {
  @include clearfix();
  @include list-reset();
  margin-left: -$gutter-width;

  &:first-child {
    margin-top: -20px;
  }

  &-zero {
    margin-left: 0;

    [class^="#{$column-prefix}"],
    [class*="#{$column-prefix}"] {
      padding-left: 0;
    }
  }
  &--equal {
    display: flex;

    [class^="#{$column-prefix}"],
    [class*="#{$column-prefix}"] {
      display: flex;
    }
  }
}

// Name: Units
// Description: Basic styling for each column
[class^="#{$column-prefix}"],
[class*="#{$column-prefix}"] {
  @include box-sizing(border-box);
  float: left;
  margin-top: 20px;
  padding-left: $gutter-width;
}

// Name: Mobile-Grid
@for $sum from 1 through $max-cols {
  @for $i from 1 through $sum {
    .#{$column-prefix}#{$i}-#{$sum} {
      @include col($i, $sum);
    }
  }
}

// Name: Create columns for breakpoints
// Description: Loop through your max-columns and create your basic grid
@each $breakpoint in $breakpoints {
  $name: nth($breakpoint, 1);

  @for $sum from 1 through $max-cols {
    @for $i from 1 through $sum {
      .#{$column-prefix}#{unquote($name)}-#{$i}-#{$sum} {
        @include respond(#{$name}) {
          @include col($i, $sum);
        }
      }
    }
  }
}