// 1.Config
$widget-hd: get-brand(primary) !default;

// 2.Base
// YUI3 Overlays and Panels

// taken from the yui sources. We don't use thier css so we have to reimplenent some of thier styles...
.yui3-overlay {
  position: absolute;
}

.yui3-overlay-mask {
  background: rgba($main-bg, .8);
}

.yui3-overlay,
.yui3-panel {
  background: $light-bg;
  box-shadow: 0 0 20px $gray;
}
.yui3-overlay-focused {
  outline: none;
}
.yui3-widget-stdmod {
  .yui3-widget-ft {
    .btn-s {
      padding: 12px 25px;
      font-size: 16px;
    }
  }
}
.yui3-widget-hd {
  background: $main-bg;
  cursor: move;
  font-size: 17px;
  font-weight: bold;
  overflow: hidden;
  padding: 20px;

  .yui3-overlay-xbutton,
  .yui3-button-close {
    cursor: pointer;
    float: right;
    padding: 6px;
    position: absolute;
    right: 0;
    top: 0;
    font-size: 28px;
    line-height: 0.8;
    @include transition(all .3s);

    &:hover {
      @include scale(1.1);
    }
  }
}
.yui3-widget-bd {
  padding: 10px;

  h2, p {
    margin-bottom: 10px;
  }
  h2 {
    font-weight: bold;
  }
  .app-alert {
    margin-top: 0;
  }
}
.yui3-widget-ft {
  background: $light-bg;
  overflow: hidden;
  padding: 20px 10px;

  button {
    float: right;
    // NOTE: don't use :last-child -- IE8 does not support it.
    &:first-child {
      float: left;
    }
  }
}

// Styling for dialog content
.app-dialog-lock-case-password-allowed-non-alnum-char-list {
  margin-top: 10px;
  overflow: hidden;
  list-style: none !important;

  li {
    background-color: $light-bg;
    border-radius: 1px;
    line-height: 20px;
    margin: 0 5px 5px 0;
    padding: 0 5px;
    float: left;
  }
}
.app-dialog-field-list {
  // match interview field style
  // TODO: Put this in another module so we don't duplicate this everyhwere.
  li {
    background: $main-bg;
    margin-bottom: 10px;
    overflow: hidden;
    padding: 10px;
  }
  label,
  input,
  select {
    display: block;
    float: left;
  }
  label {
    width: 35%;
  }
  // NOTE: FF seems to ignore width on file upload inputs.
  // At the current styling, the file upload field is 300px wide.
  input,
  select {
    width: 60%;
  }
  label {
    cursor: pointer;
    font-weight: bold;
    line-height: 27px;
  }
  // match interview field input style
  input[type="text"],
  input[type="password"],
  textarea {
    border: 1px solid $base-border-color;
    color: get-font(color);
    box-shadow: inset 0 0 3px #ddd;
    padding: 5px;
  }
}

// Custom styling for various additional field types,
// currently only used in the support (help) dialog
.app-dialog-field-item-textarea {
  label, textarea {
    float: none;
    width: 97%;
  }
}
.app-dialog-field-item-checkbox {
  input, label {
    display: inline;
    float: none;
    width: auto;
  }
  input {
    margin-right: 5px;
  }
  label {
    line-height: 20px;
  }
}
.app-dialog-field-item-inner {
  background: $light-bg;
  margin-top: 5px;
  padding: 10px;
}
.app-dialog-field-item-submit {
  overflow: hidden;
  button {
    float: right;
  }
  label {
    clear: both;
    float: none;
    margin-bottom: 5px;
  }
  input {
    display: inline;
    width: 30%;
  }
}

// Special field for taxman import options in case import dialog
#ui-case-import-taxman-options {
  display: none;
  label {
    color: get-brand(primary);
  }
}

// Help/Support overlay when user has not yet registered an account
// (see support.js)
.app-dialog-support-anonymous {
  margin-bottom: 0;

  .app-dialog-support-anonymous__help {
    padding: 30px;
  }
  p + p {
    margin-top: 10px;
  }
  h2 {
    font-size: 20px;
    font-weight: normal;
    line-height: 20px;
    margin-bottom: 10px;
  }
  .app-dialog-support-anonymus__body {
    padding: 30px 280px 30px 30px;
    border-radius: 3px;
    background-image: url(../img/support-andi.jpg);
    background-position: top right;
    background-repeat: no-repeat;
    background-size: cover;

    p {
      margin-top: 10px;
    }
    small {
      display: block;
    }
    strong {
      font-size: 24px;
    }
    button {
      margin-top: 12px;
    }
    ul.app-dialog-support-anonymous__checklist {
      margin-left: 20px;
      margin-top: 12px;

      li {
        margin-top: 8px;

        &:first-child {
          margin-top: 0;
        }
      }
    }
  }
}

.app-dialog-support-anonymous__help {
  font-size: 16px;
}


.app-dialog-lexoffice-import {
  h1 {
    font-size: 20px;
    line-height: 20px;
    margin-bottom: 10px;
  }
  p {
    margin-bottom: 10px;
  }
  strong {
    font-weight: bold;
  }
}

.app-dialog-lexoffice-logo {
  position: relative;
  left: 500px;
}

.app-dialog-waiting {
  background: url($action_indicator) center center no-repeat;
  color: get-font(lightColor);
  font-size: 20px;
  font-weight: bold;
  line-height: 30px;
  min-height: 200px;
  padding-top: 30px;
  text-align: center;
  width: 100%;
}


.yui3-overlay-content {
  box-shadow: 0 0 30px rgba($black, .4);

  h3 {
    font-size:16px;
    font-weight:bold;
    padding:8px 0px;
  }

  iframe {
    width: 100%;
  }
  table td {
    padding-left: 20px;
    vertical-align: top;


    &:first-child {
      padding-left: 0;
    }
  }

  .scrollable {
    max-height:470px;
    overflow-y:scroll;
    overflow-x:hidden;
    padding-right:10px;
  }

  ul {
    list-style:disc;
    padding-left:20px;
  }
}