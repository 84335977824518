#case-editor-analysis {
  margin-bottom: 20px;

  & > h3 {
    margin-top: 20px;
    color: #000;
    font-size: 24px;
    font-weight: 400;
    margin-bottom: 10px;
  }
  & > p {
    margin-bottom: 10px;
    font-size: 14px;

    em {
      font-weight: bold;
    }
  }
}

.case-editor-analysis-report-preview-header {
  h2 {
    color: $black;
    font-size: 22px;
    font-weight: 400;
  }
  .btn {
    font-size: 14px;
  }
}

#case-editor-analysis-report-selector {
  margin: 20px 0;

  // The top part containing the tabs:
  ul.yui3-tabview-list {
    margin-bottom: -1px;
    overflow: hidden;
    font-size: 14px;
  }
  li {
    &.yui3-categorytab,
    &.yui3-appendixtab {
      float: left;

      a {
        background: get-brand(secondary);
        border: 1px solid transparent;
        border-radius: $base-border-radius $base-border-radius 0 0;
        color: get-font(ghost);
        // For some unknown reason, there was a default pointer on the tabs
        cursor: pointer;
        display: block;
        font-weight: bold;
        line-height: 1;
        margin-bottom: -1px;
        margin-right: 5px;
        padding: 9px 14px;

        &:hover {
          background: get-font(ghost);
          border: 1px solid $light-border-color;
          color: get-brand(primary);
        }
      }
    }
    &.yui3-categorytab-selected,
    &.yui3-appendixtab-selected {
      a, a:hover {
        background: get-font(ghost);
        border: 1px solid $light-border-color;
        color: get-brand(primary);
        cursor: default;
      }
    }
    // Currently, we don't use focus styles.
    // We are not keyboard-navigatable yet anyway, we don't support screen readers,
    // and there is not much point in making focus on these tabs visible.
    &.yui3-categorytab-focused,
    &.yui3-appendixtab-focused,
    &.yui3-categorytab a:focus,
    &.yui3-appendixtab a:focus {
      outline: none;
    }
  }

  // The bottom part containing the tab content:
  .yui3-tab-panel {
    // Faux columns background -- because the two columns are practically
    // never the same height, so background colors in the uls themselves
    // will rarely reach to the same point vertically, which looks wrong
    // This is 1200px wide, so should be enough also on large monitors
    background: url($analysis_faux_columns_background) top center repeat-y;
    background-size: 1200px 10px;
    overflow: hidden;
    display: none;
  }

  .yui3-tab-panel-selected {
    display: block;
  }


  // Left: categories chooser, right: report chooser:
  ul.case-editor-analysis-report-selector-categories-list,
  ul.case-editor-analysis-report-selector-reports-list,
  ul.case-editor-analysis-report-selector-appendix-list {
    li {
      color: get-font(color);
      cursor: pointer;
      font-size: 15px;
      font-weight: 400;

      &:hover {
        color: get-brand(primary);
      }
      &.active, &.active:hover {
        color: get-brand(primary);
        cursor: default;
      }
      // NOTE: don't use :last-child -- IE8 does not support it.
      &:last-child {
        border-bottom: none;
      }
    }
    a {
      color: gray;
    }
    a.active {
      color: get-brand(primary);
    }
  }
  ul.case-editor-analysis-report-selector-categories-list,
  ul.case-editor-analysis-report-selector-appendix-list {
    border-top: 3px solid $white;

    li {
      &:first-child {
        border-top: none;
      }

      a, span {
        display: block;
        padding: 15px;
        background: $main-bg;
      }
      &.active a,
      &.active span,
      &.active:hover a,
      &.active:hover span {
        background: get-brand(primary);
        color: get-font(ghost);
        font-weight: bold;
      }
    }
  }
  ul.case-editor-analysis-report-selector-categories-list,
  ul.case-editor-analysis-report-selector-reports-list {
    float: left;
    width: 50%;
  }
  ul.case-editor-analysis-report-selector-categories-list li {
    &.active, &.active:hover {
      background: $light-bg;
    }
  }
  ul.case-editor-analysis-report-selector-reports-list {
    padding-left: 20px;

    li {
      margin-top: 5px;

      &:first-child {
        margin-top: 0;
      }

      span {
        display: inline-block;
        padding: 5px 9px;
        border-radius: $base-border-radius;
      }
      a {
        color: get-brand(primary);
      }
      &.active span {
        background: get-brand(primary);
        color: get-font(ghost);
      }

      // Mandatory reports (these have to be printed out)
      &.case-editor-analysis-mandatory {
        span:after {
          color: $info-color;
          content: ' *';
        }
      }
      // When a category contains no reports:
      &.case-editor-analysis-report-selector-reports-list-empty-note {
        cursor: default;
        font-weight: normal;
        &:hover {
          color: get-font(color);
        }
        a {
          color: get-brand(primary);
        }
      }
    }
  }
  ul.case-editor-analysis-report-selector-appendix-list {
    // In appendix lists, there is just one column (no categories),
    // So we have to overwrite the faux-colums background.
    li {
      // In the appendix lists, the structure is li > a
      // and not li > span as in the other two list types above,
      // so we have to fix the link color:
      a {
        color: get-font(color);

        &:hover {
          color: get-brand(primary);
        }
      }
    }
  }
  #case-editor-analysis-report-selector-appendix-list-empty-note {
    background: $light-bg;
    color: get-font(color);
    padding: 10px;
  }

  // This is currently only used with backoffice access for the tab "Intern".
  // This tab has no categories, just one column; like the appendix-list
  // (Not ideal to have a separate mechanism/identifier for this, but that's how it is for now.)
  .case-editor-analysis-report-selector-no-categories {
    background: $light-bg;
    width: 100% !important;
  }
}

.case-editor-analysis-report-preview-body {
  overflow: visible;

  td {
    font-size: 14px;
  }
  h1 {
    margin-bottom: 15px;
    font-size: 21px;
    font-weight: 400;
  }
  h2 {
    margin-top: 15px;
    font-size: 19px;
    font-weight: 400;
  }
  .yui3-spreadsheetFooter-result {
    font-size: 24px;
  }
}
.case-editor-analysis-report-preview-body,
.case-editor-inline-report {
  tr.has-sum td {
    background: $main-bg;
  }

  list-style: none;
}

.case-editor-report-table-container {
  table {
    width: 100%;
  }
}

.v-application .case-analysis-tabs {
  width: calc(100% + #{2 * $case-editor-padding-y});
  margin-left:-$case-editor-padding-y;
  margin-top:-$case-editor-padding-top;
}
