.case-editor-assessment-details-result,
.case-editor-assessment-details-table {
  border: 1px solid $base-border-color;
 border-radius: 2px;
  padding: 20px;
}
.case-editor-assessment-details-result {
  font-size: 16px;
  line-height: 22px;
  margin: 20px 0;

  em {
    font-weight: bold;
  }
}
.case-editor-assessment-details-table {
  margin-bottom: 30px;

  h2 {
    font-size: 16px;
    margin: 20px 0 10px 0;
    em {
      font-weight: bold;
    }
  }
  tr {
    vertical-align: top;
  }
  th, td {
    border-bottom: 1px solid $base-border-color;
    padding: 5px 0;
  }
  th {
    font-weight: bold;
  }
  thead th, td {
    text-align: right;
  }
  // Identifiers defined in EvaluationController.java
  .result_gv, .result_gv50,
  .result_sc, .result_sc50,
  .result_diff, .result_diff50 {
    color: get-brand(primary);
    font-weight: bold;
  }
}
.case-editor-assessment-details-table-number-column {
  width: 12.5%;
  text-align: right;
}

.case-editor-assessment-details-currency:after {
  content: " €";
}

.case-editor-assessment-details-negative {
  color: $failed-color;
}
.case-editor-assessment-details-positive {
  color: $success-color;
}

#case-editor-assessment-form {
  margin-bottom: 20px;
}
.case-editor-assessment-switch-container,
.case-editor-assessment-settings {
  h2 {
    color: get-brand(primary);
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 10px;
  }
}
.case-editor-assessment-settings {
  padding: 0 20px;
  border-radius: $base-border-radius;
  border: 1px solid $light-border-color;
}

.case-editor-assessment-switch-container {
  ul {
    display: table;
    margin-bottom: 20px;
    overflow: hidden;
    width: 100%;
    border-radius: $base-border-radius;
  }
  li {
    display: table-cell;
    vertical-align: middle;
    border-radius: $base-border-radius;
    margin: 0 10px 10px 0;

    &:first-child label {
      border-left: none;
    }

    input {
      position: absolute;
      visibility: hidden;
    }
    label {
      cursor: pointer;
      font-weight: bold;
      text-align: center;
      display: table-cell;
      height: 70px;
      padding: 0 30px;
      vertical-align: middle;
      border-left: 2px solid $white;
      border-radius: $base-border-radius;
      background: $switch-container-bg;
      @include transition(all .3s);
      color: get-font(ghost);

      &:hover {
        background: get-brand(primary);
        color: get-font(ghost);
      }
    }
    &.case-editor-assessment-choice-selected {
      label {
        background: get-brand(primary);
        border-left: 2px solid $white;
        border-color: get-brand(primary);
        color: get-font(ghost);
      }
      &:first-child label {
        border-left: none;
      }
    }
  }
}
.case-editor-assessment-settings-list {
  li {
    background: $light-bg;
    border-radius: 3px;
    margin-bottom: 10px;
    padding: 10px;
  }
}
.case-editor-assessment-settings-item-allocation {
  overflow: hidden;

  label {
    float: left;
    font-weight: bold;
  }
  div {
    float: right;
    margin-right: 100px;
    margin-top: 5px;
  }
  input {
    border: 0;
    font-weight: bold;
    padding: 0;
  }
}

.case-editor-assessment-settings-item-allocation-slider {
  padding-left:15px;
  padding-right:15px;
}

.ui-case-editor-assessment-settings-item-allocation-label {
  color: get-brand(primary);
  font-weight: bold;
}
.case-editor-assessment-settings-item-kbk-half {
  margin-bottom: 5px;
}
.case-editor-assessment-form-action {
  margin-top: 20px;
  text-align: center;

  button {
    display: inline-block;
  }
}

.case-editor-tax-status__prefix {
  display: none;
}

.case-editor-tax-assessment-tooltip {
  display: none;
}


.case-editor-tax-assessment {
  .btn {
    background: $button-bg;
  }
  table {
    margin-bottom: 0;
    margin-top: 40px;
    width: 100%;
    border-radius: $base-border-radius;
    border: 1px solid $base-border-color;

    tbody th,
    tbody td {
      background: $main-bg;
    }
  }
  .hasDiff {
    min-width: 120px;
  }
  .hasDiff.is-positive {
    color: $green;
  }
  .case-editor-tax-assessment-table-headline {
    th,
    td {
      background: $light-bg;
    }
  }
  .case-editor-tax-assessment-table-result-line {
    th,
    td {
      border-bottom-width: 2px;
      background-color: $success-bg;
      color: $success-color;
    }

    &.is-negative {
      td,
      th {
        background: $alert-bg;
        color: $alert-color;
      }
    }
  }
  > div:first-child {
    margin-top: -2px;
    padding: 20px;
    background: get-brand(primary);
    border-radius: 0 0 $base-border-radius $base-border-radius;
    border: 1px solid darken(get-brand(primary), 17%);
  }
  td,
  th,
  thead th {
    padding: 10px 20px;
    border-color: $base-border-color;
  }
  h2 {
    margin-bottom: 5px;
  }
  h2 + p {
    font-size: 15px;
  }

  // table
  div:first-child + div {}

  #ui-print-decree {
    @extend .btn-orange;
  }
}

.case-editor-tax-assessment-div {
  margin-bottom: 8px;
}

.case-editor-tax-assessment-result-type {
  i.fa-check-circle {
    color: get-brand(primary);

    &:before {
      content: "\f05d";
    }
  }
  span {
    display: none;
  }
}
$case-editor-tax-status-negative-bg: $gray-lighter;
$case-editor-tax-status-negative-border-color: $base-border-color;

.case-editor-tax-status {
  padding: 45px 40px;
  padding-left: 160px;
  position: relative;
  border: 1px solid $success-border-color;
  border-radius: $base-border-radius;
  background-color: $success-bg;
  color: $success-color;

  &.is-negative {
    border-color: $case-editor-tax-status-negative-border-color;
    background-color: $case-editor-tax-status-negative-bg;
    color: get-font(color);
  }
}
.case-editor-tax-status__prefix {
  display: block;
  left: 40px;
  position: absolute;
  top: 50%;
  @include translateY(-50%);
  font-size: 82px;
}

.case-editor-tax-assessment-temporary-notes {
  .case-editor-tax-assessment-temporary-notes__item {
    padding: 15px;
    border-top: 5px solid $white;
    background-color: $main-bg;

    &:first-child {
      border-top: none;
    }

    .btn {
      left: 0px;
      padding: 6px;
      position: absolute;
      top: 50%;
      @include translateY(-50%);
    }
  }
}

.case-editor-tax-basic-calculation {
  font-weight: normal;
  text-align: right;

  em {
    font-weight: normal;
  }

  &.case-editor-tax-basic-calculation-pressure {
    margin: 60px 0 0;
    padding: 20px;
    background: #FFEAEA;
    border-radius: 3px;
    border: 1px solid #dd0000;
    color: #dd0000;
    text-align: left;
  }
}

.case-editor-tax-assessment-quote {
  margin: 20px 0 0;
  padding: 20px 40px 0;
  background: transparent;
  border: none;
  border-top: 1px solid $white;
  font-size: 15px;
  line-height: 1.35;
}
.case-editor-tax-assessment-temporary-notes__item__header {
  padding-left: 40px;
  position: relative;

  h3 {
    font-size: 15px;
  }
}


// Tooltip
.case-editor-tax-assessment-tooltip {
  display: inline-block;
  position: relative;

  &:hover {
    .case-editor-tax-assessment-tooltip__content {
      display: block;
    }
  }
  .case-editor-tax-assessment-tooltip__trigger {
    cursor: help;
    color: get-brand(primary);
    font-size: 19px;
  }
}
.case-editor-tax-assessment-tooltip__content {
  bottom: 100%;
  display: none;
  padding: 10px;
  margin-bottom: 8px;
  margin-right: -8px;
  position: absolute;
  right: 0;
  width: 245px;
  border-radius: $base-border-radius;
  background: $light-bg;
  box-shadow: 0 0 20px rgba($black, 0.3);
  font-size: 13px;
  font-weight: bold;
  text-align: left;

  &:before {
    content: '';
    position: absolute;
    right: 10px;
    top: 100%;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 6px solid $white,
  }
}
.case-editor-tax-assessment-tooltip__content__list {}
.case-editor-tax-assessment-tooltip__content__item {
  margin-top: 8px;
  padding-left: 15px;
  position: relative;
  color: $success-color;
  font-weight: normal;
  line-height: 1.2;

  &:before {
    content: '';
    height: 7px;
    left: 0px;
    position: absolute;
    top: 50%;
    width: 7px;
    border-radius: $rounded-border-radius;
    background: $success-bg;
    @include translateY(-50%);
  }

  &:first-child {
    margin-top: 0;
  }

  &.is-negative {
    color: $alert-color;

    &:before {
      background-color: $alert-bg;
    }
  }
}

.case-editor-tax-assessment__table-offset {
  min-width: 120px;
  padding-right: 30px;
  position: relative;
  font-size: 14px;
  font-style: italic;
  text-align: left;

  .case-editor-tax-assessment-tooltip {
    margin-left: 5px;
    position: absolute;
    top: 2px;
  }
}

.case-editor-tax-assessment-under-table-offset {
  margin: 5px 0 0;
  text-align: right;
}
