////
/// @group lists
/// @author Tim Hartmann
////

// 1.Config
$list-positive-maincolor: $green !default;

// 2.Base
/// Default list
/// @name m-list
.m-list {
	@include list-reset();
	margin-top: 20px;

	&:first-child {
		margin-top: 0;
	}

	li {
		margin-top: 8px;

		&:first-child {
			margin-top: 0;
		}
	}
}

/// @name m-list--positive
.m-list--positive {
	margin-left: 34px;
	color: $list-positive-maincolor;

	li {
		margin-top: 18px;
		position: relative;

		&:first-child {
			margin-top: 0;
		}

		&:before {
			content: "\f058";
			margin-right: 8px;
			position: absolute;
			right: 100%;
			@include translateY(-50%);
			top: 50%;
			-webkit-font-smoothing: antialiased;
			color: $list-positive-maincolor;
			font-size: 25px;
			line-height: 0.5;
			font-family: FontAwesome;
		  font-style: normal;
		  font-weight: normal;
		  line-height: 1;
		  -webkit-font-smoothing: antialiased;
		  -moz-osx-font-smoothing: grayscale;
		}
	}
}

.m-list--horizontal {
	margin-left: -50px;

	li {
		display: inline-block;
		margin: 0 0 0 50px;
		vertical-align: middle;
	}
}