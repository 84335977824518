.app-alert {
  margin: 20px 0 0;
  padding: 15px;
  border: 1px solid $light-border-color;
  border-radius: 3px;
  font-size: 13px;
  text-align: left;
  overflow: hidden;

  & > i.fa {
    display: inline-block;
    font-size: 70px;
    line-height: 1;
    padding-left: 1px;
    vertical-align: middle;
  }
  div {
    display: inline-block;
    padding-left: 30px;
    vertical-align: middle;
    width: 85%;

    .btn {
      margin-top: 10px;
    }
  }
  h1, h2, p {
    margin-bottom: 10px;
  }
  h1, h2, em {
    font-weight: bold;
  }
  h1 {
    font-size: 16px;
    line-height: 1.2;
  }
  table td {
    padding-left: 15px;
    vertical-align: top;

    &:first-child {
      padding-left: 0;
    }
  }
}
.app-alert-problem,
.app-status-msg .app-alert-problem {
  border-color: $alert-border-color;
  background: $alert-bg;
  color: $alert-color;

  > i.fa {
    color: $alert-border-color;
  }
}

.app-dialog-field-list {
  label {
    margin-top: 12px;
    font-size: 14px;
    line-height: 1;
  }
  li {
    margin-bottom: 0;
    border: 1px solid $light-border-color;
    background: transparent;
    font-size: 14px;
  }
  input[type="text"],
  input[type="password"],
  textarea {
    padding: 10px 35px 10px 15px;
    border-color: $base-border-color;
    border-radius: 3px;
    font-family: get-font(baseFamily);
    font-size: 13px;

    &:active,
    &:focus {
      border-radius: 3px;
      border: 1px solid $base-border-color;
    }
  }
}

.app-dialog-field-item-textarea {
  textarea {
    margin-top: 8px;
  }
}

.app-dialog-lock-case-password-allowed-non-alnum-char-list li {
  padding: 5px;
  border-radius: 3px;
  background: rgba($orange, .2);
  line-height: 1;
}

.app-alert-info {
  border-color: $info-border-color;
  background: $info-bg;
  color: get-font(color);
  font-size: 14px;
  line-height: 1.3;

  h3 {
    margin: 30px 0 0;
    font-size: 18px;
    font-weight: bold;

    &:first-child {
    margin-top: 0;
    }
  }

  > i.fa {
    color: $info-color;
  }
}

.app-alert-success {
  border-color: $green-light;
  background: $green-lighter;
  color: get-font(color);
  font-size: 14px;
  line-height: 1.3;

  h3 {
    margin: 30px 0 0;
    font-size: 18px;
    font-weight: bold;

    &:first-child {
      margin-top: 0;
    }
  }

  > i.fa {
    color: $green-medium;
  }
}

.app-alert-hint {
  border-radius: $base-border-radius;
  border: 1px solid $hint-border-color;
  background: $hint-bg;
  color: get-font(color);
  font-size: 13px;
  line-height: 1.5;

  > i.fa {
    color: $hint-color;
  }
}

.app-dialog-lexoffice-import {
  .app-dialog-lexoffice-logo {
    left: 460px;
  }
}


.app-alert-problem > i.fa {color: $alert-color}
.app-alert-warning > i.fa {color: $info-color}
.app-alert-hint > i.fa    {color: $hint-color}
.app-alert-info > i.fa    {color: $info-color}

.app-attention {color: $alert-color}

.inline-border {
  border-color: $base-border-color;
}
