.l-backoffice {
  #app-header {
    overflow: visible;

    .app-status-msg {
      text-align: left;
    }
  }
  #case-filing .case-editor-filing-module {
    padding-top: 40px;
  }

  #case-editor-main-header {
    &.has-header-image {
      #case-editor-section-help {
        padding-top: 0;
      }
    }
  }
}


// This file contains styling for modules that are only shown for users with backoffice access.


// These are the controls for backoffice user functionality,
// placed below the standard app header.
#app-backoffice-controls {
  float: none;
  margin: 0;
  max-width: $max-wrapper-width;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
  min-width: 1024px;
  z-index: 2;
  border-radius: $base-border-radius;

  .btn {
    border: none;
    background: rgba($white, .2);
    color: $white;
    font-size: 12px;

    &:hover {
      background: rgba($white, .3);
    }
  }
}
#app-backoffice-controls-actions {
  &:after {
    content: '';
    clear: both;
    display: table;
  }

  div {
    float: left;
    margin-left: 10px;

    &:first-child {
      margin-left: 0;
    }
  }
}

// This is an iframe that contains debug reports.
#app-backoffice-report-container {
  border: 1px solid $gray;  // NOTE: non-standard color
  display: none;
  height: 800px;
  margin: 10px 10px 30px 10px;
  min-width: 600px;
  width: 95%;
}

// This module is visible at the bottom of the filing page.
.case-editor-filing-elster-debug {
  background: $main-bg;
  border: 1px solid $base-border-color;
  border-radius: $base-border-radius;
  padding: 10px;

  h2 {
    color: get-brand(primary);
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  ul {
    overflow: hidden;
  }
  li {
    float: left;
    margin-right: 20px;
  }
}

// Dynamic marker that can be added to a button label for backoffice functions
.btn-bo-marker {
  background: $alert-color;
  border-radius: $base-border-radius;
  color: $white;
  margin-right: 5px;
  padding: 0 5px;
}
