// 1.Config

// 2.Base
// Reset default button style (not handled by YUI CSS Reset)
button {
  cursor: pointer;
  background: $button-bg;
  border: none;
  font: 13px/20px 'FacitWeb', sans-serif;
  text-shadow: none;
  margin: 0;
  padding: 0;
}

.btn {
  border-radius: 3px;
  background: $button-bg;
  color: $button-color;
  cursor: pointer;
  display: inline-block;    // Make links styled as buttons accept height/padding/margin
  font-size: 16px;
  font-weight: bold;
  line-height: 1;
  padding: 12px 25px;
  -webkit-font-smoothing: antialiased;
  white-space: nowrap;    // Button labels should never wrap. See ONSE-7745.
  text-shadow: none;
  @include transition(background .3s ease-in);

  &:hover {
    opacity: .8;
  }
  i.fa {
    vertical-align: middle;
    font-size: 100%;
  }
}

// Enlarge icons versus the label text in buttons and menu items
.btn, .menu-item {
  i.fa {
    font-size: 120%;
  }
}

// A question mark button that launches/toggles help content
.btn-help {
  background: transparent;
  border: 1px solid get-brand(primary);
  border-radius: 3px;
  color: get-brand(primary);
  cursor: pointer;
  cursor: help;
  display: inline-block;
  font-size: 14px;
  font-weight: bold;
  line-height: 25px;
  padding: 0 10px;
  text-align: center;

  &:hover {
    background: lighten(get-brand(primary), 8%);
  }
  &.btn-help-circle {
    border-radius: 50%;
    height: 20px;
    line-height: 20px;
    padding: 0;
    width: 20px;
  }
}


// A <button> made to look like a text link
button.text {
  background: none;
  color: get-brand(primary);
  cursor: pointer;
  line-height: 18px;

  &:hover {
    border-bottom: 1px solid get-brand(primary);
  }
}


// ----------------------------------------
// Colors
// ----------------------------------------

.btn-dark {
  background: darken(get-brand(primary), 10%);

  &:hover {
    opacity: .8;
    background: darken(get-brand(primary), 10%);
  }
}

.btn-light {
  background: $btn-light-bg;
  color: $btn-light-color;

  &:hover {
    opacity: .8;
    background: $btn-light-bg;
    color: $btn-light-color;
  }
}

// Default (highlighted) button
.btn-hilited {
  background: $btn-hilited-bg;
  color: $btn-hilited-color;

  &:hover,
  &:focus {
    opacity: .8;
    background: $btn-hilited-bg;
  }
}

// Extreme highlighting, only to use very rarely.
.btn-orange {
  background: $btn-orange-bg;

  &:hover {
    background: darken($btn-orange-bg, 10%);
  }
}

.btn-orange-border {
  background: $white;
  color: $btn-orange-bg;
  border:2px solid $btn-orange-bg;

  &:hover {
    background: $btn-orange-bg;
    color: $white;
  }
}

.btn-white {
  background: $white;
  color: $blue;

  &:hover {
    background: $blue-light;
  }
}

// Ghost Button.
.btn-ghost-blue {
  background: $white;
  border:2px solid $blue;
  color: $blue;

  &:hover {
    background: $blue;
    color: $white;
  }
}

.btn-ghost-blue-border {
  background: $white;
  color: $blue;
  border:2px solid $blue;

  &:hover {
    background: $blue;
    color: $white;
  }
}


// This class should be added to all buttons that have a destructive effect,
// such as deleting an item.
.btn-destructive {
  background: $alert-color;
  color: $white;

  &:hover,
  &:focus {
    background: darken($alert-color, 10%);
  }
  .fa {
    vertical-align: 1px;
  }
}


// ----------------------------------------
// States
// ----------------------------------------

// Disabled button
.btn-disabled,
.btn-disabled:hover,
.btn-disabled:active {
  opacity: 0.5;
  background: $btn-disabled-bg;
  box-shadow: none;
  color: $btn-disabled-color;
  cursor: default;
  cursor: not-allowed;
  font-weight: normal;

  &:hover,
  &:active {
    opacity: 0.5;
    background: $btn-disabled-bg;
    color: $btn-disabled-color;
  }
  &.btn-hilited {
    background: get-brand(primary);

    &:hover {
      opacity: .5;
    }
  }
}

// Simulate the depressed state of a button to indicate that the
// UI state that this button would activate is presently active
// Currently used for disabled buttons only
.btn-active,
.btn-active:hover {
  box-shadow: inset 0 0 5px $gray; // NOTE: non-standard color
}


// ----------------------------------------
// Sizes
// ----------------------------------------

.btn-s {
  font-size: 12px;
  padding: 6px 12px;

  i.fa {
    font-size: 12px;
  }
}
.btn-l {
  font-size: 18px;
}


// ----------------------------------------
// Grouping
// ----------------------------------------

.btn-group,
.btn-group-fused {
  overflow: hidden;
  .btn {
    display: block;
    float: left;
  }
}

// Buttons in-line but separate
.btn-group {
  .btn {
    margin-left: 10px;
    // NOTE: don't use :last-child -- IE8 does not support it.
    &:first-child {
      margin-left: 0;
    }
  }
}

// Buttons in-line, borders fused together
.btn-group-fused {
  .btn {
    padding: 7px 10px;
    border-left: 0;
    border-radius: 0;

    &:first-child {
      border-left: 1px solid $gray;
      border-radius-left: 3px;
    }
    // This will not work in IE8 because it doesn't support :last-child,
    // but IE8 doesn't do border-radius either, so we can ignore that.
    &:last-child {
      border-radius-right: 3px;
    }
    &.btn-hilited:first-child {border-left-color: get-brand(primary)}
    &.btn-destructive:first-child {border-left-color: $alert-color}
    &.btn-disabled:first-child {border-left-color: $light-border-color}
  }
}
// Buttons stacked on top of one another. Need to set fixed width on buttons so they align.
.btn-group-stacked {
  .btn {
    border-radius: 3px;
    border-top: 0;

    &:first-child {
      border-top: 1px solid $gray;
      border-radius: 3px 3px 0 0;
    }
    // This will not work in IE8 because it doesn't support :last-child,
    // but IE8 doesn't do border-radius either, so we can ignore that.
    &:last-child {
      border-radius: 0 0 3px 3px ;
    }
    &.btn-hilited:first-child {border-top-color: get-brand(primary)}
    &.btn-destructive:first-child {border-top-color: $alert-color}
    &.btn-disabled:first-child {border-top-color: $light-border-color}
  }
}



// ----------------------------------------
// Alignment containers
// ----------------------------------------

.btn-center {
  text-align: center;

  .btn {
    display: inline-block;
  }
}

.btn--connect {
  padding: 6px 15px;
  border: none;
  background: $xing;
  color: $white;
  font-size: 17px;

  &:hover {
    background: darken($xing, 5%);
  }
  .fa {
    margin-right: 7px;
    vertical-align: -2px;
    font-size: 23px;
  }
}

.btn-pulse-focus {
  animation: pulse-focus 4s infinite 3s;
}

.btn-pulse-hilited {
  animation: pulse-hilited 4s infinite 3s;
}

// Currently Orange
@keyframes pulse-focus {
  0% {
    box-shadow: 0 0 0 0 transparent;
    transform: scale(1);
  }
  10% {
    transform: scale(1.1);
    box-shadow: 0 0 0 5px rgba($orange-dark, .4);
  }
  20% {
    transform: scale(1);
    box-shadow: 0 0 0 10px transparent;
  }
}

// Currently Blue
@keyframes pulse-hilited {
  0% {
    box-shadow: 0 0 0 0 transparent;
    transform: scale(1);
  }
  10% {
    transform: scale(1.1);
    box-shadow: 0 0 0 5px rgba($orange-dark, .4);
  }
  20% {
    transform: scale(1);
    box-shadow: 0 0 0 10px transparent;
  }
}
