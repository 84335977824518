////
/// @author Tim Hartmann
/// @group transforms
////

/// @name rotate
/// @param {string} $degrees
@mixin rotate($degrees) {
	-webkit-transform: rotate($degrees);
			-ms-transform: rotate($degrees);
					transform: rotate($degrees);
}

// @name transform
// @param {string} $value
@mixin transform($value) {
	-webkit-transform: $value;
			-ms-transform: $value;
    			transform: $value;
}

/// @name translate
/// @param {string} $xvalue
/// @param {string} $yvalue
@mixin translate($xvalue, $yvalue) {
	-webkit-transform: translate($xvalue, $yvalue);
			-ms-transform: translate($xvalue, $yvalue);
					transform: translate($xvalue, $yvalue);
}

/// @name translateX
/// @param {string} $value
@mixin translateX($value) {
	-webkit-transform: translateX($value);
			-ms-transform: translateX($value);
					transform: translateX($value);
}

/// @name translateY
/// @param {string} $value
@mixin translateY($value) {
	-webkit-transform: translateY($value);
			-ms-transform: translateY($value);
					transform: translateY($value);
}

/// @name scale
/// @param {number} $value
@mixin scale($value) {
	-webkit-transform: scale($value);
			-ms-transform: scale($value);
					transform: scale($value);
}