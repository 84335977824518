// http://www.fontspring.com/blog/the-new-bulletproof-font-face-syntax
// http://www.fontspring.com/support/installing_webfonts/how-do-i-use-the-webfonts

@font-face {
  font-family: 'FacitWeb';
  src: url('../font/FacitWeb.eot');
  src: url('../font/FacitWeb.eot?#iefix') format('embedded-opentype'),
       url('../font/FacitWeb.woff') format('woff'),
       url('../font/FacitWeb.ttf') format('truetype');
       // url('../font/FacitWeb.svg#FacitWeb') format('svg')
  font-weight: normal;
}
@font-face {
  font-family: 'FacitWeb';
  src: url('../font/FacitWeb-Bold.eot');
  src: url('../font/FacitWeb-Bold.eot?#iefix') format('embedded-opentype'),
       url('../font/FacitWeb-Bold.woff') format('woff'),
       url('../font/FacitWeb-Bold.ttf') format('truetype');
       // url('/portal/fonts/FacitWeb-Bold.svg#webfont') format('svg')
  font-weight: bold;
}

// FontAwesome
@font-face {
  font-family: 'FontAwesome';
  src: url('../font/fontawesome-webfont.eot?v=4.1.0');
  src: url('../font/fontawesome-webfont.eot?#iefix&v=4.1.0') format('embedded-opentype'),
       url('../font/fontawesome-webfont.woff?v=4.1.0') format('woff'),
       url('../font/fontawesome-webfont.ttf?v=4.1.0') format('truetype'),
       url('../font/fontawesome-webfont.svg?v=4.1.0#fontawesomeregular') format('svg');
  font-weight: normal;
  font-style: normal;
}