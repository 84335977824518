.case-editor-registration-reminder {
	padding: 0;
	border: none;

	a {
		cursor: pointer;
		display: block;

		&:hover {
			opacity: 0.8;
		}
	}

	img {
		max-width: 100%;
		width: auto;
	}
}