.case-editor-analysis-report-preview-header {
  overflow: hidden;
  margin-bottom: 10px;

  h2 {
    color: get-brand(primary);
    float: left;
    font-size: 16px;
    line-height: 30px;
    font-weight: bold;
  }
  a {
    &.yui3-analysispreview-hidden {
      display: none;
    }
  }
  div.btn-group-fused {
    float: right;
  }
}

.case-editor-analysis-report-preview-body {
  overflow: auto;
}
.case-editor-analysis-report-preview-body-placeholder {
  color: get-font(lightColor);
  padding: 20px;
  text-align: center;
}
