.app-controls-system-state {
  margin-top: 8px;
  text-align: right;

  a {
    color: $light-link-color;
    cursor: default;
    font-size: 12px;
  }
  i {
    margin-right: 5px;
  }
}

.app-status-msg {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 102;

  & > div {
    background: $light-bg;
    box-shadow: $base-box-shadow;
    margin-bottom: 0;
    width: 547px; // magic number to make chrome not shrink the div when scrolled.
  }
}