// 1.Config

// 2.Base
.yui3-app-transitioning .yui3-app-views,
.yui3-app-views.yui3-app-transitioning {
    overflow-x: hidden;
    position: relative;
    white-space: nowrap;
    letter-spacing: -0.31em; /* webkit: collapse white-space between units */
    text-rendering: optimizespeed; /* Webkit: fixes text-rendering: optimizeLegibility */
}
/* Opera as of 12 on Windows needs word-spacing.
   The ".opera-only" selector is used to prevent actual prefocus styling
   and is not required in markup.
*/
.opera-only :-o-prefocus,
.yui3-app-transitioning .yui3-app-views,
.yui3-app-views.yui3-app-transitioning {
    word-spacing: -0.43em;
}
.yui3-app-transitioning .yui3-app-views > *,
.yui3-app-views.yui3-app-transitioning > * {
    display: inline-block;
    letter-spacing: normal;
    word-spacing: normal;
    vertical-align: top;
    text-rendering: auto;
    width: 100%;
    white-space: normal;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

/* YUI CSS Detection Stamp */
#yui3-css-stamp.app-transitions-css { display: none; }