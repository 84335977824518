// Styling for all help content that is created in the backoffice using TinyMCE.
// (ONSE-8118)
// This is currently used in the following places:
// - Areas ("Bereichshilfe")
// - Fields ("Feldhilfe")
// - Extended field help overlays ("Erweiterte Feldhilfe")
// - Spreadsheet overlays ("Tabellenhilfe")
// In the future, it will probably also be used in the "Erweiterte Lebenswelten" concept.


// See ONSE-8036 for the styles currently supported/configured in TinyMCE.
// Explicit styling is currently available for all those styles, plus sub/sup.


// To apply help styling, just add this class to the innermost container:
.case-editor-interview-help {
  background-color: $info-color;
  font-size: 12px;
  line-height: 18px;
  padding: 10px;

  &.is-hidden {
    display: none;
  }

  p {
    margin-top: 5px;

    &:first-child {
      margin-top: 0;
    }
  }
  em, strong, b {
    font-weight: bold;
  }
  sup {
    vertical-align: top;
  }
  sub {
    vertical-align: bottom;
  }
  a {
    border-bottom: 1px dotted get-brand(primary);
    color: get-brand(primary);

    &:hover {
      border-bottom: none;
    }

    // Automatically inserted links to the Tax Lexicon in www.smartsteuer.de
    &.case-editor-interview-help-lexicon-link {
      color: $base-link-color;
      cursor: help;
      font-weight: normal;

      &:after {
        font-family: FontAwesome;
        font-size: 120%;
        content: "\f059";
        margin-left: 1px;
      }
      &:hover {
        border-color: get-font(color);
      }
    }
  }
  .btn {
    border-bottom-color: $gray;
    background: $main-bg;
    color: get-font(ghost);

    &:hover {
      background: darken($gray_lighter, 10%);
      color: get-font(ghost);
    }
  }
  .btn-hilited {
    padding: 0;
    border-bottom: 1px dotted;
    background: transparent;
    color: get-brand(primary);
    font-size: 13px;

    &:hover {
      background: transparent;
      border-bottom: none;
      color: get-brand(primary);
    }
  }
  h1, h2, h3, h4 {
    font-weight: bold;
    margin-bottom: 5px;

    &:first-child {
      margin-top: 0;
    }
  }
  h1 {
    font-size: 20px;
    line-height: 20px;
    margin-top: 30px;
  }
  h2 {
    font-size: 16px;
    line-height: 16px;
    margin-top: 20px;
  }
  h3 {
    font-size: 14px;
    line-height: 14px;
    margin-top: 15px;
  }
  h4 {
    margin-top: 10px;
  }
  blockquote {
    border-left: 1px solid get-brand(primary);
    color: get-font(color);
    margin: 20px 0 20px 10px;
    padding-left: 10px;
  }
  ol, ul {
    margin: 5px 0 5px 20px;
  }
  ol {
    list-style: decimal;
  }
  ul {
    list-style: square;
  }

  .case-editor-interview-help__form-list {
    list-style: none;
    margin: 15px 0 0;
    padding: 0;

    > li {
      margin-top: 8px;

      &:first-child {
        margin-top: 0;
      }
    }
  }



  hr {
    border: 0;
    border-top: 1px solid get-brand(primary);
    height: 0;
    margin: 10px 0;
  }
  // ONSE-8638 : added tables (specifically for the examples in the extended help)
  table {
    border-collapse: collapse;
    margin-bottom: 15px;
  }
    tr {
      vertical-align: top;
    }
      // For now, we assume that tables will always consist of a first column with some text content,
      // followed by any number of columns of numbers, which are always right aligned.
      // Since TinyMCE's table editor offers no way to insert <th>s (as a first column),
      // We'll just simulate them using :first-child.
      th, td {
        background: $white;
        border: 1px solid get-brand(primary);
        padding: 5px 10px;
        text-align: right;
        // Avoid line-wrap in second and further columns (usually containing only numbers)
        white-space: nowrap;
        &:first-child {
          background: $info-color;
          // The 1st column by default contains titles/names and thus should be styled like normal text.
          white-space: normal;
          text-align: left;
        }
      }
  // ONSE-8965: Alternative style for tables with text content rather than numbers.
  // Override all cells to allow line-wrap and align text left.
  table.smst-stbl {
    th, td {
      white-space: normal !important;
      text-align: left !important;
    }
  }

  input,
  textarea {
    @include box-sizing(border-box);
    padding: 8px;
    width: 100%;
    border: 1px solid darken(get-brand(primary), 8%);
    background: $white;
    color: $gray;

    @include placeholder() {
      color: $gray;
      font-style: italic;
    }

    &:focus {
      outline: none;
      border: 1px solid darken(get-brand(primary), 16%);
    }
  }
  textarea {
    min-height: 120px;
    resize: none;
  }
}

.case-editor-interview-field-help-more-container {
  margin-top: 10px;
}

// Help containers in Inliners need a bottom margin
.case-editor-interview-help-inliner {
  margin-bottom: 5px;
  position: relative;
  border-color: darken($info-color, 22%);
  border-width: 2px;
}


  // "Do you want to know more?" in field help
  .case-editor-interview-field-help-more-container {
    margin-top: 20px;

    &:after {
      content: '';
      clear: both;
      display: table;
    }

    .btn {
      display: inline-block;
    }
  }
  .case-editor-interview-field-help-more-container-right {
    float: right;
  }

// Extended field help overlay
.case-editor-interview-help-extended-navigation {
  overflow: hidden;
  margin-bottom: -1px;
  position: relative;
  z-index: 10;

  h3 {
    color: get-font(color);
    float: left;
    line-height: 30px;
    margin: 0 10px 0 20px;
  }

  & > button {
    border-radius: $base-border-radius $base-border-radius 0 0;
    background: $main-bg;
    color: get-font(color);
    cursor: pointer;
    display: block;
    float: left;
    font-weight: bold;
    line-height: 20px;
    margin-right: 5px;
    padding: 5px 10px;
    text-align: left;
    white-space: nowrap;
    &:active,
    &:hover,
    &.active {
      background-color: $info-color;
      color: get-brand(primary);
    }
    &.active {
      cursor: default;
    }
  }
}

.case-editor-interview-help-extended-navigation,
.case-editor-interview-help-extended-navigation-lexicon {
  button,
  > button.active {
    margin-bottom: -1px;
    padding: 9px 14px;
    border-color: transparent;
    border-radius: $base-border-radius $base-border-radius 0 0;
    background: darken($main-bg, 10%);
    color: get-font(ghost);
    line-height: 1
  }
  > button.active {
    border: 1px solid darken($info-color, 17%);
    border-bottom: none;
    background: $info-color;
    color: $orange;
  }
}
.case-editor-interview-help-extended-navigation-lexicon {
  background: $info-color;
  border: 1px solid darken($info-color, 17%);
  clear: both;
  overflow: hidden;
  padding: 10px;

  li {
    float: left;
    margin-right: 10px;
  }
  button {
    background: transparent;
    color: get-brand(primary);
    cursor: pointer;
    display: block;
    padding: 5px 10px;

    &.active {
      cursor: default;
      font-weight: bold;
    }
  }
}

.case-editor-interview-help-extended {
  height: 636px;
  overflow: auto;
}
.case-editor-interview-help-extended-more {
  min-height: 300px;

  td,
  th {
    white-space: normal;
  }
}
.case-editor-interview-help-extended-examples {
  min-height: 300px;
}
.case-editor-interview-help-extended-lexicon {
  iframe {
    border: 1px solid $base-border-color;
  }
}
.case-editor-interview-help-extended-lexicon-pointer {
  background: url(../img/case-editor-interview-help-extended-lexicon-pointer.svg) 10px bottom no-repeat;
  background-size: 20px 8px;
  height: 10px;
  margin-bottom: -1px;
  position: relative;
  z-index: 1000;
}
.case-editor-interview-help-extended-lexicon-loading {
  background: rgba(255, 255, 255, .85) url(../img/app-action-indicator.gif) center 50% no-repeat;
  height: 580px;
  left: 11px;
  position: absolute;
  top: 122px;
  width: 678px;
}


// Debug information in field help
// (could be moved to backoffice.scss because it is really "backoffice" content)
.case-editor-interview-help-debug {
  background: rgba($info-color, .2);
  border-radius: 3px;
  margin-bottom: 10px;
  padding: 10px;

  strong {
    color: get-brand(primary);
  }
  em {
    font-weight: bold;
    font-style: normal;
  }
}


#case-interview-help,
.case-interview-help,
.case-editor-interview-help {
  border-radius: $base-border-radius;
  border: 1px solid $info-border-color;
  background: $info-bg;
  color: get-font(color);
  font-size: 13px;
  line-height: 1.3;

  .case-editor-interview-field-message-warning,
  .case-editor-interview-field-message-hint,
  .smst-warn,
  .smst-hint {
    padding: 0;
    border: none;
  }
  hr {
    border-color: darken($info-color, 17%);
  }
  .btn {
    margin-top: 10px;
    border: none;
  }
  input,
  textarea {
    border-color: darken($info-color, 17%);
  }
}

// Adaptations for display of help content in native iOS app custom help view,
// see ONSE-9602
html.native-app-help-view {
  background: $blue-light;

  #case-interview-help,
  .case-interview-help,
  .case-editor-interview-help {
    background: $blue-light;
    border: none;
    padding: 20px;
    a,
    a.case-editor-interview-help-lexicon-link {
      border: 0;
      color: get-font(color);
      cursor: default;
      &:after {
        display: none;
      }
    }
  }

  .case-editor-interview-help-footer a {
    color: $base-link-color;
  }

  .case-editor-interview-field-help-more-container {
    display: none;
  }
  #case-editor-main-header {
    padding: 0;
  }

}
