// NOTE! Common appearance for the overlay (panel) container is defined in dialogs.scss for ALL overlays and panels.

// TODO: We need another option to hide elements instead of this. This will make the rendering slow!
.yui3-routespanel-hidden {
  visibility: hidden;
  top: -10000px !important;
  left: -10000px !important;
}
.yui3-routespanel-focused {
  outline: none;
}

#case-editor-interview-tool-routes-inputs {
  display: table;
  table-layout: fixed;
  border-bottom: 1px solid $base-border-color;
  overflow: hidden;
  padding: 10px 20px;

  li {
    display: table-cell;
    padding-left: 40px;
    vertical-align: bottom;
    width: 300px;

    &:first-child {
      padding-left: 0;
    }
  }
  label {
    color: $gray;
    font-weight: normal;
    margin-right: 5px;
  }

  // Match style of interview fields
  // TODO: Put this in another module so we don't duplicate this everyhwere.
  input {
    border-radius: $base-border-radius;
    border: 1px solid $base-border-color;
    color: get-font(color);
    box-shadow: inset 0 0 3px #ddd;  // NOTE: non-standard color
    padding: 6px 12px;
    width: 100%;

    &:focus {
      border-radius: $base-border-radius;
    }
  }
  .btn {
    padding: 10px 15px;
    font-size: 15px;
  }
}
.case-editor-interview-tool-routes-update {
  text-align: right;
}
.case-editor-interview-tool-routes-update-button {
  float: right;
}

#case-editor-interview-tool-routes-result-short {
  padding: 15px;
  border: 1px solid #B5D8A9;
  border-radius: $base-border-radius;
  background: #EBFCE5;

  div h1 {
    color: $gray;
    font-size: 18px;
  }
}

#case-editor-interview-tool-routes-output {
  overflow: hidden;
  padding: 20px;
}
#case-editor-interview-tool-routes-map {
  float: left;
  height: 465px;
  width: 570px;
}
#case-editor-interview-tool-routes-map-placeholder {
  background: $main-bg;
  color: get-brand(primary);
  font-weight: bold;
  height: 550px;
  line-height: 550px;
  position: absolute;
  text-align: center;
  width: 598px;
}
#case-editor-interview-tool-routes-details {
  float: right;
  width: 327px;

  > p {
    font-size: 15px;
    font-weight: bold;
  }
}
#case-editor-interview-tool-routes-result {
  margin-bottom: 20px;

  div {
    font-size: 16px;
    line-height: 24px;
    margin: 8px 0 0;
    padding: 10px;

    h1 {
      margin-bottom: 10px;
      color: $gray;
      font-size: 16px;
      font-weight: 400;
      line-height: 1.2;
    }
    ul {
      margin-bottom: 10px;
      overflow: hidden;
    }
    li {
      float: left;
      padding-left: 15px;
      width: 50%;

      &:first-child {
        padding-left: 0;
      }
    }
    span {
      color: $success-color;
      font-size: 32px;
      font-weight: 400;
      line-height: 30px;
    }
  }
  #case-editor-interview-tool-routes-result-loading {
    padding: 15px;
    border: 1px solid darken($main-bg, 10%);
    border-radius: $base-border-radius;
    background: $main-bg;

    h1 {
      margin: 0;
    }
  }
  .btn {
    margin-top: 15px;
    padding: 9px 14px;
    font-size: 14px;
  }
}
#case-editor-interview-tool-routes-result-fast {
  padding: 15px;
  border: 1px solid darken($main-bg, 10%);
  border-radius: $base-border-radius;
  background: $main-bg;

  p {
    font-size: 12px;
    line-height: 16px;
  }
}
#case-editor-interview-tool-routes-help {
  h1 {
    font-size: 16px;
    line-height: 16px;
    margin-bottom: 8px;
  }
  p {
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 8px;
  }
}
