.case-interview-field {
  line-height: 20px;
  overflow: hidden;
  padding: 8px;
  background: $case-interview-field-bg;
  border: 1px solid $case-interview-field-border-color;

  @media screen and (min-width: 1200px) {
    padding: 10px;
  }

  &:hover {
    background-color: $case-interview-field-focused-bg;
  }
  + .case-interview-field:not(.case-interview-field-checkbox-only),
  + .case-editor-interview-inline {
    border-top: none;
  }

  &.case-interview-field-focused {
    background-color: get-brand(primary);

    .case-interview-field-label {
      color: get-font(ghost);
    }
  }
  .case-interview-field-unit {
    height: 33px;
    padding: 9px;
    width: auto;
    border-radius: 0 3px 3px 0;
    color: #5E6B73;
    font-size: 14px;
    line-height: 1;
    text-align: center;

    @include placeholder() {
      color: $gray-lighter;
    }
  }
  .case-interview-field-label {
    width: 48%;
    font-size: 13px;

    @media screen and (min-width: 1100px) {
      width: 57%;
    }
  }
  .case-interview-field-body {
    padding-left: 10px;
    width: 50%;

    @media screen and (min-width: 1100px) {
      width: 42%;
    }
  }
  input[type="text"] {
    height: 33px;
  }
  input[type="text"],
  textarea {
    padding: 6px 12px;
    width: 100%;
    border-radius: $base-border-radius;
    font-family: 'FacitWeb', sans-serif;
    font-size: 14px;

    &:focus,
    &:active {
      border-radius: $base-border-radius;
    }
  }

  a.case-editor-interview-help-lexicon-link {
    border-bottom: 1px dotted $gray;
    color: get-brand(primary);

    &:hover {
      border-bottom: none;
    }
    &:after {
      display: none;
    }
  }

  & + .case-interview-field-focused,
  & + .field-invalid {
    border-top: 1px solid;
  }

  &.case-editor-interview-field-hidden + .case-interview-field {
    border-top: 1px solid $light-border-color;
  }

  &.field-invalid + .field-invalid {
    border-top: none;
  }

  &.case-interview-field-focused {
    border-color: darken($case-interview-field-focused-bg, 8%);
    background-color: $case-interview-field-focused-bg;

    .case-interview-field-input-tool-container {
      .case-interview-field-input-tool-btns {
        background-color: get-font(ghost);

        &:before {
          border-top-color: get-font(ghost);
        }
      }
    }

    .case-interview-field-label {
      color: $black;
    }
    .case-interview-field-label-line-reference em {
      margin-left: -12px;
      margin-top: -42px;
      padding: 4px 8px;
      background: $info-bg;
      color: get-font(color);
      font-size: 12px;
      line-height: 1;

      &:after {
        content: '';
        height: 0;
        margin-top: 3px;
        width: 0;
        background: transparent;
        @include triangle(bottom, 7px, $info-bg);
      }
    }
  }
  &.field-invalid {
    border-color: $alert-border-color;
    background-color: $alert-bg;

    input,
    .case-interview-field-unit {
      border-color: $alert-border-color;
    }
  }
  .field-format-0 {
    width: 99px !important;
  }

  .field-format-P {
    width: 125px !important;
  }
}
.case-interview-field-label {
  color: get-font(color);
  cursor: pointer;
  display: block;
  float: left;
  width: 59%;

  // Field labels may contain some markup,
  // (only <strong> is in use currently)
  strong, em, b, i {
    font-weight: bold;
  }
  .case-interview-field-focused &,
  .case-interview-field-focused & em {
    // color: get-font(ghost);
  }
}

// Contains the asterisk '*' that marks mandatory fields (inside the label)
// ONSE-8497: this was previously just a span,
// but for fields with line-ref, the label text used 2 spans
// to separate between line-ref and actual label text,
// so we were coloring those orange and made them large type
.case-interview-field-mandatory-marker {
  color: $info-color;
  font-size: 17px;
}

.case-interview-field-input-tool-container {
  .case-interview-field-input-tool-btns {
    display: block;
    position: relative;

    &:before {
      content: '';
      left: 15px;
      position: absolute;
      top: 100%;
      @include triangle(bottom, 5px, $gray-lighter);
    }
    &:after {
      display: none;
    }
  }
  .case-interview-field-input-tool-btns-attached {
    display: inline-block;
    margin-bottom: 11px;

    .btn-s {
      padding: 5px 15px;

      i {
        font-size: 12px;
      }
    }
  }
  .case-interview-field-input-tool-btns-pointing {
    display: inline-block;
    margin-bottom: 13px;
  }
  ~ .case-interview-field-unit {
    display: inline-block;
    padding: 9px ;
    width: auto;
    font-size: 14px;
    text-align: center;
  }
}

// We don't use tool button hint text for now...
// It's too irritating on pages with many spreadsheet fields,
// and we assume that the spreadsheet tool UI is self-explanatory
// .case-interview-field-input-tool-btns-hint {
//  color: get-font(color);
//  font-size: 12px;
//  // Line height is ignored since we are inline inside the label
// }
.case-interview-field-label-with-line-reference {
  overflow: hidden;
}
.case-interview-field-label-line-reference,
.case-interview-field-label-text {
  display: block;
  float: left;
  overflow: hidden;
}
.case-interview-field-label-line-reference {
  width: 15%;

  em {
    display: none;

    // Showing this tooltip on hover causes problems on touch devices (tablets), making the interview form unusable
    .case-interview-field-focused & {
      background-color: get-brand(primary);
      border-radius: 3px;
      box-shadow: 3px 0 10px rgba($black, .5);
      color: get-brand(primary);
      display: block;
      margin-left: -17px;
      margin-top: -45px;
      position: absolute;
      padding: 5px 10px;
      z-index: 10;
      font-size: 12px;
      line-height: 1;

      &:after {
        background: url($help_arrow_down) 0 0 no-repeat;
        background-size: 20px 5px;
        content: " ";
        display: block;
        height: 10px;
        margin-top: 5px;
        position: absolute;
        width: 30px;
      }
    }
    .case-interview-field-focused & {
      // background: get-brand(primary) !important;
      // color: $white !important;
      font-weight: bold;
    }
  }
}
.case-interview-field-label-text {
  width: 85%;
}

.case-interview-field-body {
  float: right;
  overflow: hidden;
  width: 40.5%;
}
.case-interview-field-input {
  float: left;
  // Limit, otherwise overlong selects will get clipped
  // NOTE: In IE8+, this may cause selects to get limited to this width
  // even when the drop-down is shown, so that long entries cannot be read to the end.
  width: 100%;
  input[type="text"],
  textarea {
    border: 1px solid $base-border-color;
    color: get-font(color);
    box-shadow: inset 0 0 3px #ddd;
    padding: 5px 8px;

    &.case-editor-interview-field-autofilled {
      border-style: dashed;
    }
  }
  input[type="text"] {
    vertical-align: middle;
  }
  select {
    // Limit, otherwise overlong selects will get clipped
    // (100% will still clip if the window is at minimum width)
    // NOTE: In IE8+, this may cause selects to get limited to this width
    // even when the drop-down is shown, so that long entries cannot be read to the end.
    width: 180px;
  }
}

.case-interview-field-has-unit {
  input[type="text"] {
    border-radius: 3px 0 0 3px;
  }
}

// ONSE-8544 : if there is a unit, the select must be shortened quite a bit
// so the unit container does not wrap below the select
.case-interview-field-has-unit .case-interview-field-input select {
  width: 110px;
}

// ONSE-8544
// Wrapper around <select>s styled to create a container that will
// correctly "fuse" on to a unit container, as for normal inputs
.case-interview-field-input-select-container {
  display: inline-block;

  &.case-editor-interview-field-autofilled {
    border-style: dashed;
  }
  // Actually, the unit container should be inserted after
  // .case-interview-field-input-select-container, but it is inserted
  // *inside* .case-interview-field-input-select-container after the <select>.
  // So we go for a different solution and remove the unit's border and
  // background when it appears after a <select>.
  // This still keeps <select> and unit next to each other in one line,
  // and we keep the styling for this enclosing container.
  .case-interview-field-unit {
    background: none;
    border: none;
    padding-left: 10px;
  }
  select {
    width: 100%;
    font-size: 14px;
  }
}

.case-editor-sidebar-navigator-container {
  padding: 0;
}

// ONSE-8666: add a styled container around checkboxes/radios
// to make the input more visible to users and make sure
// they click the actual input instead of just focusing the field
// (In particular on IE, checkboxes/radios are tiny and may be
// overlooked, especially when there is a field-exclusive-marker.)
.case-interview-field-input-checkbox-radio-container {
  padding: 5px;
  background: $main-bg;
  border-radius: $base-border-radius;
  display: inline-block;
  line-height: 1;

  &.case-editor-interview-field-autofilled {
    border-style: dashed;
  }
}
// We need to adjust field widths to accomodate the lock icon,
// so we use a variable for this that we just re-use.
$read_only_lock_icon_padding : 20px;
.case-interview-field-readonly {

  &.case-interview-field-checkbox {
    .case-interview-field-input-checkbox-radio-container {
      width: 38px;
    }
  }
  &.case-interview-field .field-format-D {
    width: 125px !important;
  }
  input, textarea {
    cursor: not-allowed;
  }
  input[type="text"],
  textarea {
    background-color: $main-bg;
    background-image: url($readonly_marker);
    background-repeat: no-repeat;
    border-color: $base-border-color;
    box-shadow: none;
  }
  input[type="text"],
  textarea,
  .case-interview-field-unit {
    color: #5E6B73;
  }
  input[type="text"] {
    background-position: 5px center;
    text-indent: $read_only_lock_icon_padding;
  }
  // Text-indent does not work in a text-area, see ONSE-9379
  textarea {
    background-position: 5px 5px;
    padding-left: 25px;
  }
  // We cannot style a checkbox, so we have to use the surrounding container
  &.case-interview-field-checkbox .case-interview-field-input-checkbox-radio-container {
    background: transparent url($readonly_marker) 25px center no-repeat;
    width: 35px;
    background-color: $main-bg;
  }
  .field-format-V,
  .field-format-OD,
  .field-format-U,
  .field-format-M,
  .field-format-G,
  .field-format-F,
  .field-format-P,
  .field-format-POS,
  .field-format-R,
  .field-format-S,
  .field-format-N,
  .field-format-A,
  .field-format-I {
    width: 120px !important;
  }
}

// A unit for the field value (such as €, km)
// This is attached to the right of the input field.
// Currently, I've only seen it used with fields that are not very long,
// (common for number values), so there is enough space on the right of the input.
// For longer inputs, this will wrap into the next line and would need a fix.
.case-interview-field-unit {
  background: $light-bg;
  border: 1px solid $base-border-color;
  border-left-width: 0;
  display: inline-block;
  font-size: 12px;
  height: 24px;
  line-height: 24px;
  padding: 0 5px;
  vertical-align: middle;
  .case-interview-field-readonly & {
    background: $main-bg;
  }
}
// This is set by JS when no unit is given, to hide the unit container completely.
// We could solve this in CSS alone by using the :empty selector,
// but IE8 doesn't support it.
.case-interview-field-unit-empty {
  display: none;
}

// ONSE-8509
// Wrapper around the tool button container
// (which has to be inline-block, see below)
// We need this to cause the tool button to always wrap,
// otherwise we sometimes get inputs to appear next to tool buttons

// A spreadsheet without data is only represented by a single button. This button has to be rendered
// inline.
.case-interview-field-input-tool-container-inline {
  display: inline-block;
  vertical-align: top;
}

// Container for tool buttons inside interview fields.
// Theoretically, we could have more than one button in a field,
// but in practice, this doesn't happen yet.
// This is the default tool button container. It sits in its own padded box.
// The classes below may be added to add variations.
.case-interview-field-input-tool-btns {
  display: inline-block;
  padding: 4.5px 10px;
  border-radius: 3px;
  background: $case-interview-field-item-bg;
  border: 1px solid $base-border-color;

  &:before {
    display: none;
  }

  // An optional button label, in line
  span {
    color: get-font(color);
    font-size: 13px;
    margin: 0 0 0 3px;
  }

  // 1 or more buttons, in line. TODO: replace with .btn-group instead
  button,
  .btn {
    display: inline-block;
    margin-left: 5px;
    padding: 5px;
    font-size: 12px;

    &:first-child {
      margin-left: 0;
    }
  }
}
// When this is class is added, the tool button container
// sits directly on top of the input element, without a gap.
// This is currently used for the spreadsheet tool only,
// when the spreadsheet contains data, and the tool button container
// has edit and delete buttons to edit the spreadsheet.
.case-interview-field-input-tool-btns-attached {
  button,
  .btn {
    padding: 7px 10px;
  }
  .btn-s {
    padding: 7px;

    i.fa {
      font-size: 15px;
    }
  }

  i.fa {
    font-size: 15px;
    line-height: 0.9;
  }

  + input {
    margin-top: 8px;
  }
  + .field-format-P {
    display: block;
    width: 130px !important;
  }
}
// This class must be added to add an arrow at the bottom
// pointing down onto the input element (like a speech bubble).
// This is currently used for the full-year and route tools.
.case-interview-field-input-tool-btns-pointing {
  margin-bottom: 8px;

  &:after {
    background: url($tool_button_error) 0 0 no-repeat;
    background-size: 20px 8px;
    content: " ";
    display: block;
    height: 8px;
    margin-left: 5px;
    margin-top: 5px;
    margin-bottom: 8px;
    position: absolute;
    width: 20px;
  }
}

// Error message for an invalid field
.case-editor-interview-field-problem-details {
  clear: both;

  span {
    background: $alert-color;
    border-radius: 3px;
    color: get-font(ghost);
    display: inline-block;
    font-size: 14px;
    font-weight: normal;
    line-height: 1;
    margin: 10px 0 0;
    padding: 6px 10px;
  }
}


// Layout optimisation for different field types:

// Optimised sizes for various field types
// (copied from old core css)
.field-format-V,  // (-)0,0000
.field-format-OD, // "0,0"
.field-format-U,  // "0"
.field-format-M,  // "0"
.field-format-G,  // "0"
.field-format-F,  // 0,0000
.field-format-P,  // ? (see ONSE-7425: types P, POS and OD should be same width)
.field-format-POS,  // 00,00
.field-format-R,  // 00,00
.field-format-S,  // (-)0,00
.field-format-N,  // "1"
.field-format-A,  // XXXX0XXXXXXXX
.field-format-I,  // XXXXXXXXXXX
.field-format-ZEIT
{
  // Max possible width to avoid wrap-around with long text
  // for unit (eg. "€ pro Jahr", see ONSE-8544) (was: 110px)
  width: 110px !important;
}

.field-format-K,  // MM.JJJJ
.field-format-O,  // PLZ
.field-format-Z,  // TT.MM.JJJJ
.field-format-W,  // JJJJ/JJJJ
.field-format-E   // TT.MM.JJ
{
  width: 90px !important;
}

.field-format-ZEIT {
  text-align: center;
}

.field-format-D { // TT.MM.JJJJ
  // ONSE-8469 : added 5px to avoid clipping in Webkit
  // ONSE-8696 : removed those 5px again, otherwise these fields will wrap-around
  // when there is a from-to range (probably FF only)
  width: 92px !important;
}

.field-format-B,  // MM.JJ
.field-format-T,  // TT.MM.
.field-format-L   // MM.JJ
{
  width: 66px !important;
}

.field-format-JAHR {
  width: 4em !important;
}

// Adjusted field widths to accomodate read-only lock symbol
// Fixes ONSE-8136, ONSE-8315, ONSE-8469, ONSE-8510
// $read_only_lock_icon_padding is defined right before .case-interview-field-readonly
.case-interview-field-readonly {
  .field-format-V,  // (-)0,0000
  .field-format-OD, // "0,0"
  .field-format-U,  // "0"
  .field-format-M,  // "0"
  .field-format-G,  // "0"
  .field-format-F,  // 0,0000
  .field-format-P,  // ? (see ONSE-7425: types P, POS and OD should be same width)
  .field-format-POS,  // 00,00
  .field-format-R,  // 00,00
  .field-format-S,  // (-)0,00
  .field-format-N,  // "1"
  .field-format-A  // XXXX0XXXXXXXX
  {
    // Max possible width to avoid wrap-around with long text
    // for unit (eg. "€ pro Jahr", see ONSE-8544) (was: 110px)
    width: 110px !important;
  }

  .field-format-I  // XXXXXXXXXXX
  {
    width: calc(110px + $read_only_lock_icon_padding) !important;
  }

  .field-format-K,  // MM.JJJJ
  .field-format-O,  // PLZ
  .field-format-Z,  // TT.MM.JJJJ
  .field-format-W,  // JJJJ/JJJJ
  .field-format-E   // TT.MM.JJ
  {
    width: calc(90px + $read_only_lock_icon_padding) !important;
  }

  .field-format-D { // TT.MM.JJJJ
    width: calc(92px + $read_only_lock_icon_padding) !important;
  }

  .field-format-B,  // MM.JJ
  .field-format-T,  // TT.MM.
  .field-format-L   // MM.JJ
  {
    width: calc(66px + $read_only_lock_icon_padding) !important;
  }

  .field-format-JAHR {
    width: calc(4em + $read_only_lock_icon_padding) !important;
  }
}

// ONSE-8722
// Autocomplete inputs are clipped in Webkit due to the right padding,
// for the autocomplete icon, so we limit the width
// (format H had no defined width)
.field-format-H.yui3-aclist-input {
  width: 100%;
}

// Checkbox field: long labels, minimal width for field body
.case-interview-field-checkbox {
  .case-interview-field-label {
    width: 56%;
  }
  .case-interview-field-body {
    width: 42%;
    text-align: right;
  }
  &.case-interview-field-readonly {
    .case-interview-field-input-checkbox-radio-container {
      padding-right: 40px;
    }
  }
}

.case-interview-field-help {
  display: none;
}

.case-interview-field-input-checkbox-icon > i {
  display: none;
  font-size: 28px;
  color: get-brand(primary);
  position: absolute;
}

.case-interview-field-group-checkbox-only {
  display: flex;
  flex-wrap: wrap;

  .case-editor-interview-inline-area:first-child {
    width: 100%;
  }
}

.case-interview-field-checkbox-only {
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-end;
  width: calc(33.333% - 16px);
  margin-bottom: 16px;
  margin-right: 16px;
  padding: 12px;
  padding-bottom: 20px;
  border: 2px solid #EBEEF1;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.2s;

  &:hover {
    background-color: rgba(0,0,0,0.02);
  }

  &.field-focused {
    border-color: darken(#EBEEF1, 12);
  }

  input[type="checkbox"] {
    position: absolute;
    opacity: 0;
  }

  .case-interview-field-input-checkbox-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    margin-bottom: 12px;
    background-color: white;
    border: 2px solid #A1A5A7;
    border-radius: 5px;
  }

  .case-interview-field-input-checkbox-radio-container {
    background: none;
    padding: 0;
  }

  .case-interview-field-body {
    padding: 0;
    width: 100%;
    text-align: left;
    display: flex;
  }

  .case-interview-field-label {
    font-size: 15px !important;
    color: #454D52;
    font-weight: bold;
    margin-left: 2px;
  }

  @media screen and (max-width:1415px) {
    width: calc(50% - 16px);
  }
}

.case-interview-field-selected {
  border-color: get-brand(primary);
  background-color: #EFF8FF;

  &:hover {
    background-color: darken(#EFF8FF, 2);
    transition: 0.2s;
  }

  &.field-focused {
    border-color: get-brand(primary);
    box-shadow: 0 0 0 3px rgba(0, 128, 204, 0.18);
    transition: 0.2s;
  }

  .case-interview-field-label {
    color: get-brand(primary);
  }

  .case-interview-field-input-checkbox-icon {
    border: 2px solid get-brand(primary);

    & > i {
      display: block;
    }
  }
}

// Divider dash between from-to range fields (such as dates)
.case-editor-interview-field-range-divider {

  .case-interview-field-focused & {
    // color: get-font(ghost);
  }
}

.case-editor-interview-field-empty {
  color: get-font(lightColor) !important;
}

.case-editor-interview-field-group-caption {
    margin-bottom: 15px;
    margin-top: 25px;
    font-size: 19px;
    font-weight: 400;
    line-height: 1.45;
}

.inline-border {
  border: 1px solid get-brand(primary);
}

// Hidden fields will get removed from the view but will stay in scope for script and dom operations.
.case-editor-interview-field-hidden {
  visibility: hidden;
  top: -1000em;
  position: fixed;
}

// "Connection" symbols for mutually exclusive fields ("radio buttons")
.case-editor-interview-field-exclusive-marker-1st,
.case-editor-interview-field-exclusive-marker,
.case-editor-interview-field-exclusive-marker-last {
  background-repeat: no-repeat;
  background-position: right top;

  &.case-interview-field-checkbox {
    background-position: 100% top;
    background-repeat: no-repeat;

    .case-interview-field-body {
      float: right;
    }
  }
  .case-interview-field-body {
    padding-right: 30px;
  }
}
.case-editor-interview-field-exclusive-marker-1st {
  background-image: url($exclusive_marker_first);
  background-size: 200px 1255px;
  border-radius-bottom: 0;
  margin-bottom: 1px;
}
.case-editor-interview-field-exclusive-marker {
  background-image: url($exclusive_marker);
  background-size: 200px 100px;
  border-radius: 0;
  margin-bottom: 1px;
}
.case-editor-interview-field-exclusive-marker-last {
  background-image: url($exclusive_marker_last);
  background-size: 200px 100px;
  border-radius-top: 0;
}
// Checkboxes or radios are right-aligned, so the horizontal line must be shorter,
// otherwise it would overlap to the left beyond the edge of the checkbox/radio
.case-editor-interview-field-exclusive-marker-1st.case-interview-field-checkbox {
  background-image: url($exclusive_marker_first_short);
  background-size: 54px 1254px;
}
.case-editor-interview-field-exclusive-marker.case-interview-field-checkbox {
  background-image: url($exclusive_marker_short);
  background-size: 54px 100px;
}
.case-editor-interview-field-exclusive-marker-last.case-interview-field-checkbox {
  background-image: url($exclusive_marker_last_short);
  background-size: 54px 100px;
}
// Apply this class to all inline fields that are logically connected to a parent field
// which is a mutually exclusive field (this creates a continuous connection line):
.case-editor-interview-field-exclusive-marker-connector {
  background-image: url($exclusive_marker_connector);
  background-size: 20px 10px;
  background-position: right top;
  background-repeat: repeat-y;
  margin-top: 0;
  margin-bottom: 1px;

  .case-interview-field-label {
    width: 54%
  }
  .case-interview-field-body {
    width: 45%;
  }
}

// ONSE-8889: styles for messages in interview field list
// .smst-warn and .smst-hint are short names so our BI team does not have to remember long names.
// Removed "warning stripe" styles, we got rid of them with the new smartCheck design.
// TODO: this should instead use the generic app-alert styles,
// but for this BI would have to edit each occurrence by hand.
// I'm leaving this in for now.
.case-editor-interview-field-message-warning,
.case-editor-interview-field-message-hint,
.smst-warn,
.smst-hint {
  border: 1px solid $base-border-color;
  padding: 20px;

  a {
    color: get-brand(primary);

    &:hover {
      border-bottom: 1px solid get-brand(primary);
    }
  }
  b, i, em, strong {
    font-weight: bold;
  }
}

.case-interview-field.smartcheck-field-attention-status {
  border-left: 4px solid #ff7f01;
}