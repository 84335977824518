
.o-modal {
  strong {
    font-weight: bold;
  }
}
.o-modal--community {
	margin: 0 -10px;

	.o-modal__keyvisual {
		bottom: 0;
		display: block;
		height: 100%;
		position: absolute;
		right: 0;
		width: 100%;
		background: url(../img/modal-community-keyvisual.jpg) no-repeat top right / 100%;
	}

	.app-help-lexiconLinks-iframe {
		height: 400px;
		margin-top: -20px;
		width: 100%;
	}

	.app-dialog-waiting-wrapper {
		height: 400px;
		display: none;
		position: absolute;
		width: 100%;
		background: #fff;

		&.is-loading {
			display: block;
		}
	}

	a:not(.btn) {
		color: get-brand(primary);
	}
	.btn-hilited {
		color: #fff;
	}

	.m-tabs__matter__column-full {
	  width: 100%;
	  flex: 0 0 100%;
	  border-top: 1px solid #ccc;

	  &:first-child {
	  	border-top: 0;
	  }

	  ul,
	  ol {
	  	margin-left: 20px;
	  }
	}
	.m-tabs__matter__columns {
	  display: flex;
	  flex-direction: row;
	  flex-wrap: wrap;

	  &--plain {
	    .m-tabs__matter__column-half {
	      border-left: none;
	      border-right: none;
	    }
	    .m-tabs__matter__column-full {
	      padding-bottom: 0;
	      border-bottom: none;
	    }
	  }
	  &--grid {
	    .m-tabs__matter__column-half {
	    	padding-left: 0;
	    	position: relative;
	    	border-left: none;

			.m-tabs__matter__column-spacer {
				padding-top: 40px;
			}

	      + .m-tabs__matter__column-half .m-tabs__matter__column-spacer {
	      	padding-left: 40px;
	      	padding-right: 40px;
	      }
	    }
	  }
	}
	[class*="m-tabs__matter__column"] .m-tabs__matter__column-spacer {
	  padding: 40px;
	}

	.m-tabs__matter__column-half,
	.m-tabs__matter__column-one-third,
	.m-tabs__matter__column-two-third {
	  width: 50%;
	  flex: 0 0 50%;
	  position: relative;


	  .m-tabs__matter__column-spacer {
	  	border-right: 1px solid #ccc;
	  }

	  &:first-child {
	  	padding-left: 0;
	  }

	  + .m-tabs__matter__column-half,
		+ .m-tabs__matter__column-one-third,
		+ .m-tabs__matter__column-two-third {
	    .m-tabs__matter__column-spacer {
	    	margin-left: -1px;
	    	border-left: 1px solid #ccc;
	    	padding-right: 0;
	    	border-right: none;
	    }
	  }
	}

	.m-tabs__matter__column-one-third {
		width: 33.333%;
		flex: 0 0 30%;
	}
	.m-tabs__matter__column-two-third {
		width: 66.666%;
		flex: 0 0 63%;
	}

	.l-center {
	  text-align: center;
	}

	.o-referal {
	  display: inline-block;
	  margin-top: 10px;
	  font-weight: bold;
	}


	.o-modal__form {
	  padding: 30px;

	  .o-modal__form__list__item.is-error {
  		input,
  		textarea {
  			border-color: red;
  		}
  		.o-modal__form__error {
  			display: block;
  			padding-left: 283px;
	    	padding-top: 10px;
			  color: red;
			}
  	}

	  .o-modal__form__list {
	    list-style: none;
	    margin: 50px 0 0;
	    padding: 0;

			&__item {
				padding-top: 40px;

				&:first-child {
					padding-top: 0;
				}
				.o-modal__form__error {
	    		display: none;
	    	}
			}
	    &__item__wrap {
	    	display: flex;
	      flex-direction: row;
	      flex-wrap: nowrap;
	    }
	    &__item--centered {
	      label {
	        align-self: center;
	      }
	    }
	  }

	  label {
	    flex-basis: 43%;
	    padding-right: 50px;
	    font-size: 19px;

	    p {
	    	margin-top: 10px;
	      font-size: 13px;
	    }
	  }

	  input,
	  textarea {
	    display: block;
	    padding: 20px;
	    width: 100%;
	    border: 1px solid #b3b8bc;
	    border-radius: 3px;
	    resize: none;
	    font-size: 18px;
	  }

	  &__header {
	    text-align: center;

	    h3 + h2 {
	    	margin-top: 10px;
	    }
	  }

	  &__footer {
	    align-items: center;
	    display: flex;
	    justify-content: space-between;
	    margin-top: 40px;
	    text-align: center;

	    &__offset {
	      align-items: flex-end;
	      display: flex;
	      flex-basis: 60%;
	      flex-direction: row;
	      flex-wrap: nowrap;
	      text-align: right;

	      p {
	        padding-right: 30px;
	        font-size: 14px;
	      }
	    }
	  }
	}

	.m-tabs__lexicon-links {
		margin-left: 0 !important;

		li a {
			&.is-active {
				font-weight: bold;
			}
		}
		li:first-child {
			margin-top: 0 !important;
		}
	}


	.l-thanks {
	  padding: 80px;
	  text-align: center;

	  .fa {
	  	padding: 17px;
	  	border-radius: 50%;
	  	background: rgba(#50b078, .2);
	  	color: #50b078;
	  	font-size: 100px;
	  }

	  .l-thanks__title {
	    margin: 40px 0 30px;
	    color: #50b078;
	    font-size: 26px;

	    strong {
	      font-weight: bold;
	    }
	  }
	}
	.l-error {
		padding: 50px;
		text-align: center;

		.btn {
			margin-top: 15px;
		}
	}

	.l-completion {
	  margin-top: 25px;
	  text-align: center;
	}

  h2 {
    margin-bottom: 20px;
    color: #666;
    font-size: 22px;
  }
  h3 {
    margin: 30px 0 0;
    font-size: 18px;
    font-weight: bold;

    &:first-child {
      margin-top: 0;
    }
    + p {
      margin-top: 3px;
    }
  }
  h3 strong {
  	font-weight: bold;
  }
  blockquote {
    font-style: italic;
  }
  p {
    margin: 18px 0 0;

    &:first-child {
      margin-top: 0;
    }
  }
  table {
    margin-top: 15px;
    width: 100%;
    border: 1px solid;
  }
  td {
    padding: 5px;
    border-top: 1px solid;
  }
  td + td {
    border-left: 1px solid;
    text-align: right;
  }
  tr:first-child {
    td {
      border-top: none;
    }
  }

	.o-list {
	  margin: 20px 0 0;
	  padding: 0;
	  list-style: none;

	  &:first-child {
	    margin-top: 0;
	  }

	  li {
	    margin-top: 18px;
	  }

	  &--numbered {
	    counter-reset: numberedlist;

	    li {
	      counter-increment: numberedlist;
	      overflow: hidden;
	      padding-left: 35px;
	      padding-top: 2px;
	      position: relative;
	      text-overflow: ellipsis;
	    }
	    li:before {
	      content: counter(numberedlist);
	      left: 0;
	      padding: 4px 6px;
	      position: absolute;
	      top: 0;
	      border-radius: 150px;
	      background: lighten(get-brand(primary), 40%);
	      color: get-brand(primary);
	      font-weight: bold;
	      line-height: .9;
	      text-align: center;
	    }
	  }
	  &--horizontal {
	    display: flex;
	    flex-direction: row;
	    flex-wrap: wrap;
	    justify-content: space-between;

	    li {
	    	margin-top: 0;
	      flex-basis: 45%;

	      p {
	      	margin-top: 25px;
	      }
	    }
	    li:before {
	    	top: 25px;
	    }
	  }
	}
	.m-tabs__matter {
	  display: none;

	  &.is-active {
	    display: block;
	  }

	  ol,
	  ul {
	  	margin-left: 0px;
	  	margin-top: 5px;
	  }

	  ul {
	  	list-style-type: circle;
	  }
	  ol {
	  	list-style-type: decimal;
	  }
	}
	.o-tabs__matter__footer {
	  margin-top: 25px;
	  text-align: left;

	  &--centered {
	    margin-top: 65px;
	    text-align: center;
	  }
	  &--justified {
	  	&:after {
	  		content: '';
	  		clear: both;
	  		display: table;
	  	}
	  	> * {
				float: right;

				&:first-child {
					float: left;
				}
	  	}
	  }
	}
	.o-tabs__list {
	  list-style: none;
	  margin: -70px 0 0;
	  display: flex;
	  flex-wrap: wrap;
	  overflow: hidden;
	  padding: 0;
	  position: relative;
	  background: #eef1f4;
	  white-space: nowrap;

	  > li {
	    display: inline-block;
	    flex: 1;
	    vertical-align: middle;
	    border-left: 1px solid #ccc;
	    font-size: 17px;
	    text-align: center;
	    transition: all .3s ease-in;

	    &:first-child {
	      border-left: 0;
	    }
	    a {
	      display: block;
	      padding: 25px;
	      color: get-brand(primary);
	      font-weight: normal;
	    }
	    .is-active,
	    &:hover {
	      position: relative;
	      z-index: 2;
	      background: white;
	      box-shadow: 0 0 40px rgba(0,0,0,.3);
	      color: #50b078;
	    }
	  }
	}
}

.o-modal .m-tabs__matter {
  textarea {
  	box-sizing: border-box;
    padding: 6px 12px;
    width: 100%;
    border-radius: 3px;
    border: 1px solid #95a7b2;
    font-size: 14px;
  }
  input {
  	box-sizing: border-box;
    height: 33px;
    padding: 6px 12px;
    border: 1px solid #95a7b2;
    border-radius: 3px;
    box-shadow: none;
    font-size: 14px;

    &[type="radio"],
    &[type="checkbox"] {
      height: auto;
    }
  }
}
.o-modal .m-tabs__matter--support {
  #support-form {
    padding: 25px;

    ul {
      list-style: none;
      margin: 0;
      padding: 0;
    }
    textarea {
      overflow: hidden;
    }
  }
  label {
    display: block;
    color: #000;
    font-size: 13px;
    font-weight: bold;
    line-height: 1;

    + *,
    + p {
      margin-top: 8px;
    }
  }
  label,
  input,
  textarea {
    float: none;
    margin-top: 8px;
    width: 100%;

    &:first-child {
      margin-top: 0;
    }
  }
  .support-form__row.is-error {
    padding: 15px;
    background-color: $alert-bg;

    .support-form__invalid-message {
      display: block;
    }
  }
  label.is-error {
    color: $alert-color;
  }
  textarea {
    height: 140px;
  }
  input[type="checkbox"],
  input[type="radio"] {
    width: auto;
  }
  label {
    font-size: 14px;
  }
  input,
  textarea {
    border-color: $base-border-color;

    &.is-error {
      border-color: $alert-border-color;
    }
  }
  textarea {
    margin-top: 8px;
  }
  .support-form__filelist {
    margin-left: -1%;

    &:after {
      content: '';
      clear: both;
      display: table;
    }
    input[type="file"] {
      height: auto;
      float: left;
      margin-left: 1%;
      margin-top: 15px;
      width: 32%;

      &.is-error ~ input[type="file"] {
        border-color: $alert-border-color;
      }
    }
  }
  .app-dialog-field-list {
    padding: 10px 30px 30px;

    li {
      padding-left: 0;
      padding-right: 0;
      list-style: none;
      border: none;
    }
  }
  .app-dialog-support-anonymous {
    .app-dialog-support-anonymous__help {
      margin-bottom: 0;
      font-size: 16px;
    }
  }
  .app-small-text {
    display: block;
    line-height: 1.3;
  }
  .m-tabs__matter--support__invalid-message {
    display: none;
    margin-top: 10px;
    padding: 6px 10px;
    border-radius: 3px;
    background: $alert-color;
    color: $white;
    font-size: 13px;
    line-height: 1;
  }
  .m-tabs__matter--support__subtitle {
    margin-top: 5px;
    font-weight: bold;
  }
  .app-dialog-field-item-submit {

    label {
      display: block;
    }
    input[type="file"] {
      height: auto;
      margin-left: 2%;
      margin-top: 15px;
      padding: 15px;
      width: 31.6%;
      border-style: dotted;
      line-height: 1;

      &:first-child,
      &:nth-child(4) {
        margin-left: 0;
      }

      &:hover {
        cursor: pointer;
        background-color: #f2f2f2;
      }
    }
  }
  .app-dialog-field-item-row {
    padding: 15px;

    &:after {
      content: '';
      clear: both;
      display: table;
    }
  }
  .app-dialog-field-item-mask {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    background: rgba(255,255,255,.5);
  }
  .app-dialog-field-item-textarea:after {
    content: '';
    clear: both;
    display: table;
  }
  .m-tabs__matter--support__thanks {
    padding: 30px;

    h2 {
      margin: 40px 0 30px;
      color: #50b078;
      font-size: 26px;
    }
  }
}

.o-modal .m-tabs__matter--support__header {
  min-height: 160px;
  position: relative;
}

.o-modal .m-tabs__matter--support__header__keyvisual {
  display: block;
  height: 100%;
  opacity: 0.4;
  position: absolute;
  width: 100%;
  background-repeat: no-repeat;
  background-position: top right;
  background-size: cover;
}
.o-modal .m-tabs__matter--support__header__description {
  padding: 25px;
  position: relative;
  width: 70%;
  z-index: 1;

  h1 {
    font-size: 24px;
    font-weight: bold;
    line-height: 1.3;
  }

  p {
    margin-top: 5px;
  }
}

.o-modal__trigger {
  right: 0;
  padding: 8px;
  position: absolute;
  top: 0;
  z-index: 1;
  border-radius: 50px;
  background: #5f6370;
  color: #fff;
  font-weight: bold;
  line-height: .8;
  text-align: center;
  transform: translate(50%, -50%);
}

.modal--alternative {
	.yui3-widget-hd {
		overflow: visible;
		padding: 0;
		position: absolute;
		right: 3px;
		top: -70px;
		z-index: 200;
		background: transparent;
	}
	.yui3-overlay-xbutton {
		cursor: pointer;
		padding: 8px 7px;
		border-radius: 250px;
		color: #1B212B;
		font-size: 26px;
	}
	.yui3-widget-bd {
		padding-bottom: 0;
		padding-top: 0;
	}
}

