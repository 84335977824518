.case-editor-smartcheck-banner {
  background-color: $info-bg;
  margin-bottom: 50px;
  margin-top: 15px;
  padding: 40px 50px 30px 50px;
  border: 1px solid $info-border-color;

  &:after {
    content: '';
    clear: both;
    display: table;
  }

  .fa {
    color: $info-color;
  }

  &.case-editor-smartcheck-passed {
    border: 1px solid $success-border-color;
    background: $success-bg;
    color: $success-color;

    .fa {
      color: $success-color;
    }
  }
  h1 {
    float: left;
    width: 25%;
    font-size: 150px;
    line-height: 150px;
  }
  h3 {
    font-size: 19px;
    font-weight: 400;
    line-height: 1.2;
  }
  div {
    float: left;
    width: 75%;

    h2, h3 {
      font-weight: bold;
    }
    h2 {
      font-size: 30px;
      line-height: 30px;
      margin-bottom: 30px;
    }
    h3, p, ul {
      margin-bottom: 10px;
    }
    ul {
      list-style: square;
      margin-left: 15px;
    }
      li {
        margin-bottom: 5px;
      }
    .case-editor-smartcheck-passed p {
      font-weight: bold;
    }
  }
}


.case-editor-smartcheck-issue-list {
  margin: 30px 0 0;
}

.case-editor-smartcheck-issue-category-hint,
.case-editor-smartcheck-issue-category-warning,
.case-editor-smartcheck-issue-category-problem {
  .case-editor-smartcheck-issue-list-body {
    > li {
      margin: 0 0 0 18px;
      padding: 20px;
      overflow: hidden;
      border: 1px solid $light-border-color;
      box-shadow: -3px 0 0 $light-border-color;

      &:first-child {
        border-radius: 3px 3px 0 0;
      }
      &:last-child {
        border-radius: 0 0 3px 3px;
      }
    }
  }
}

.case-editor-smartcheck-issue-category-hint {
  .case-editor-smartcheck-issue-list-body {
    > li {
      border-color: $success-border-color;
      border-top: none;
      background: $success-bg;
      box-shadow: -3px 0 0 $success-border-color;

      &:first-child {
        border-top: 1px solid $success-border-color;
      }
    }
  }
}

.case-editor-smartcheck-issue-category-warning {
  .case-editor-smartcheck-issue-list-body {
    > li {
      border-radius: $base-border-radius;
      border: 1px solid $info-border-color;
      background: $info-bg;
      box-shadow: -3px 0 0 $info-border-color;
      font-size: 13px;
      line-height: 1.3;
    }
  }
}

.case-editor-smartcheck-issue-category-problem {
  .case-editor-smartcheck-issue-list-body {
    > li {
      border-color: $alert-border-color;
      border-top: none;
      background: $alert-bg;
      box-shadow: -3px 0 0 $alert-border-color;

      &:first-child {
        border-top: 1px solid $alert-border-color;
      }
    }
  }
}

.case-editor-smartcheck-issue-list-header {
  margin-bottom: 15px;
  padding-top: 25px;
  overflow: hidden;
  border-top: 1px solid $light-border-color;

  i {
    float: left;
    font-size: 25px;
    line-height: 25px;
    padding-left: 1px;
    width: 39px;

    .case-editor-smartcheck-issue-category-problem &  {
      color: $alert-color;
    }
    .case-editor-smartcheck-issue-category-warning &  {
      color: $info-color;
    }
    .case-editor-smartcheck-issue-category-hint &   {
      color: $success-color;
    }
    .case-editor-smartcheck-issue-category-unedited & {
      color: get-font(color);
    }
  }
  h1 {
    float: left;
    font-size: 22px;
    line-height: 1;
    font-weight: 400;

    span {
      font-size: 13px;
      font-weight: normal;
    }
  }
  button {
    float: left;
    margin-left: 10px;
    margin-top: 3px;
  }
}
.case-editor-smartcheck-issue-category-unedited {
  .case-editor-smartcheck-issue-list-header {
    padding-top: 0;
    border-top: none;
  }
  .case-editor-smartcheck-issue-list-body {
    list-style: circle;
    margin: 0 0 0 15px;
    padding: 10px 30px 15px 35px;
    border: 1px solid $light-border-color;
    box-shadow: -3px 0 0 $gray-lighter;
    max-height: 100px;
    overflow: auto;
  }
}

.case-editor-smartcheck-issue-list-help.case-editor-interview-help {
  display: none;
  margin: 15px 0 15px 40px;
}
.case-editor-smartcheck-issue-list-body {
  & > li {
    padding: 15px 0;

    a {
      color: $base-link-color;
      font-size: 14px;

      &:hover {
        text-decoration: underline;
      }
    }
    > div {
      display: inline-block;
      margin-right: -4px;
      vertical-align: middle;
    }
    .case-editor-smartcheck-issue-category-unedited & {
      padding: 5px 0;
      font-size: 12px;
      line-height: 18px;

      a {
        &:hover {
          color: get-brand(primary);
        }
      }
    }
  }
}
.case-editor-smartcheck-issue-details {
  width: 80%;

  & > div {
    margin-top: 5px;

    &:first-child {
      margin-top: 0;
    }
  }
  .case-editor-smartcheck-issue-muted & {
    opacity: .33;
  }
  .case-editor-interview-help {
    padding: 0;
    border: none;
    background: transparent;
    color: get-font(color);
  }
  .case-editor-smartcheck-issue-path {
    font-size: 16px;
  }
}
.case-editor-smartcheck-issue-path {
  font-weight: bold;
  margin-bottom: 5px;

  .case-editor-smartcheck-issue-path-unassigned {
    color: get-font(color);
  }
}
.case-editor-smartcheck-issue-note {
  color: get-font(color);
  font-size: 12px;
  line-height: 18px;
  margin-top: 10px;

  strong {
    font-weight: bold;
  }
}
.case-editor-smartcheck-issue-multiple-targets {
  font-size: 12px;
  line-height: 18px;
  p {
    color: get-font(color);
    margin-bottom: 10px;
  }
  li {
    margin-bottom: 10px;
    overflow: hidden;
  }
  ul li .btn-hilited {
    color: $white;
  }
}
.case-editor-smartcheck-issue-multiple-targets-details {
  float: left;
  width: 80%;
}
.case-editor-smartcheck-issue-multiple-targets-path {
  font-weight: bold;

  .case-editor-smartcheck-issue-path-unassigned {
    color: get-font(color);
  }
}
.case-editor-smartcheck-issue-multiple-targets-action {
  float: left;
  width: 20%;

  .btn {
    float: right;
  }
}
.case-editor-smartcheck-issue-actions {
  width: 20%;

  .btn-group-stacked {
    float: right;

    .btn {
      float: right;
      color: $white;
      font-size: 13px;
      text-align: center;
      width: 120px;
      border-top: none;
    }
  }
}
