.m-steps {
  display: table;
  margin: 0 auto 50px;
  position: relative;
  width: 280px;
  @include transition(all .5s);

  &:before {
    content: '';
    display: block;
    height: 3px;
    left: 0;
    position: absolute;
    top: 50%;
    width: 100%;
    border-bottom: 3px dotted $base-border-color;
    transform: translateY(-50%);
    @include transition(border 2s);
  }
}
.m-steps__step:last-child {
  text-align: right;
}
.m-steps__step {
  display: table-cell;
  position: relative;
  z-index: 1;
  vertical-align: middle;
  font-size: 18px;

  &.is-active {
    .m-steps__step__inner {
      background: $success-bg;
      box-shadow: 0 0 0 4px #5cb85c,
                  0 0 0 10px #fff;
      transform: scale(1.1);
    }
  }
}
.m-steps__step__inner {
  display: inline-block;
  padding: 8px 5px;
  vertical-align: middle;
  width: 40px;
  border: 3px solid #fff;
  border-radius: 50%;
  background: darken($main-bg, 15%);
  box-shadow: 0 0 0 4px #ccc,
              0 0 0 10px #fff;
  color: #fff;
  font-size: 19px;
  font-weight: bold;
  line-height: 1;
  text-align: center;
  @include transition((background 3s, box-shadow 3s));

  &:after {
    content: "\f00c";
    display: none;
    color: get-font(color);
    font: normal normal normal 18px/1 FontAwesome;
  }
}