// 1.Config
// 2.Base
// Name: Default Layer
.m-layer {
	@include box-sizing();
	padding: 20px;
	position: relative;
	z-index: 1;
	border-bottom: 1px solid #CACFD5;
	background: $layer-contrastcolor;

	.l-constrained {
		padding-left: 0;
		padding-right: 0;
	}

	.m-layer__asset {
		opacity: 0.7;
	}

	.m-form {
		padding: 20px;
		position: relative;
		background: $main-bg;

		&:before {
			content: '';
			@include triangle(left, 10px, $layer-contrastcolor);
			position: absolute;
			right: 100%;
			top: 25px;
		}
		input,
		textarea,
		.m-form__trigger__pseudo-input {
			border: 1px solid darken($layer-contrastcolor, 15%);
		}
		.btn {
			padding: 10px 25px;
			background: get-brand(primary);
			color: get-font(ghost);
			font-size: 16px;
			line-height: 1;

			&:hover {
				opacity: .8;
				background: get-brand(primary);
			}
		}
		input,
		textarea {
			&:active, &:focus {
				outline: none;
				border-color: get-brand(primary);
			}
		}
	}
	.m-form__error-message {
		font-size: 14px;
	}

	h3 {
		font-size: 20px;
		font-weight: bold;
		line-height: 1.4;
	}

	img {
		height: auto;
		max-width: 100%;
	}

	p {
		margin-top: 5px;

		&:first-child {
			margin-top: 0;
		}
		+ p {
			margin-top: 15px;
		}
	}

	strong {
		font-weight: bold;
		-webkit-font-smoothing: antialiased;
	}

	.m-list--positive {
		margin-top: 30px;
		font-size: 15px;
		font-weight: bold;
		-webkit-font-smoothing: antialiased;

		&:before {
			font-weight: normal;
		}
	}
}

// Name: Description
.m-layer__description {
	padding-top: 10px;
}

// Name: Layer-Trigger
.m-layer__trigger {
	opacity: .8;
	position: absolute;
	right: 24px;
	top: 17px;
	font-size: 25px;
	@include transition(all .3s ease-out);

	&:hover {
		opacity: .6;
		@include transform((scale(1.1) rotate(90deg)));
	}
}

a.m-layer__trigger {
	color: #1B212B;
}


// Name: Register-Layer
.m-layer--register {
	.m-layer__asset {
		display: none;

		@include respond(xxlarge) {
			display: inline-block;
			margin-top: 50px;
			text-align: center;
		}
	}
}

.m-layer--register--variation {
	.l-units {
		padding-left: 10px;
		padding-right: 10px;
	}
	.m-form .btn.btn-orange {
		background: $info-color;

		&:hover {
			 background: darken($info-color, 10%);
		}
	}

	.m-form {
		padding-left: 100px;
		padding-right: 100px;

		@include respond(xxlarge) {
			padding-left: 170px;
			padding-right: 170px;
		}

		h3 {
			margin-bottom: 25px;
			text-align: center;
		}
	}

	.m-form__footer {
		text-align: center;

		.m-form__info {
			margin-bottom: 25px;
		}
	}
}

.yui3-aclist-content {
	text-align: left;
}