// Adapted from CSS provided with YUI3 widget.
// Simplified ans SCSS-ified code;
// threw out everything that we don't use or that was outdated.

.yui3-aclist-input {
  //  ONSE-4974: autocompletes need a marker to make it obvious that they are "special"
  background: $white url($icon_autocomplete) right center no-repeat;
  padding-right: 25px !important;
}

// In FF at least, there is a horizontal scrollbar on the aclist,
// even though no width is set explicitly, anywhere.
// Enforcing width: auto on any node within yui3-aclist did not help.
// (the widths are already auto, but FF seems to be calculating cell width incorrectly)
// I am now just forcing the horizontal scrollbar to hidden, so it's less ugly.
.yui3-aclist {
  position: absolute !important; // needed for all the ies
  z-index: 1;
  // width: auto !important;  // doesn't do anything
  width: 350px; // trying this now. Bad hack because hard-coding the width.
          // If we have aclists with longer lines, they WILL be clipped.
          // Let's hope we don't.
  font-size: 14px;
}
.yui3-aclist-hidden {
  display: none;
}
.yui3-aclist-content {
  background: $light-bg;
  border: 1px solid $base-border-color;
  box-shadow: 0 3px 10px $gray;  // NOTE: non-standard color
  // width: auto !important;  // doesn't do anything
  // This is the "autocomplete help" ("Vorschläge:")
  h3 {
    border-bottom: 1px solid $base-border-color;
    color: get-font(lightColor);
    font-weight: bold;
    line-height: 25px;
    padding: 0 5px;
  }
}
.yui3-aclist-list {
  max-height: 220px;
  overflow: auto;   // Auto scrollbars for browsers that don't know overflow-x|y
  overflow-x: hidden; // Force hide horizontal scrollbars
  overflow-y: auto; // Vertical scrollbars must be shown on overflow, otherwise we can't scroll long aclists

  // width: auto !important;  // doesn't do anything
  ul, li {
    // width: auto !important;  // doesn't do anything
  }
}
.yui3-aclist-item {
  cursor: pointer;
  padding: 2px 5px;
}
.yui3-aclist-item-hover {
  background-color: get-brand(primary);
  color: get-font(ghost);
}
.yui3-aclist-item-active {
  background-color: get-brand(primary);
  color: get-brand(primary);
}
.yui3-aclist-aria {
  // Hide from sighted users, show to screen readers.
  left: -9999px;
  position: absolute;
}
