// This is just a marker so we can see if we are looking at an MF or MFA
// Should be commented out from markup when dev work is done
.case-editor-interview-multif-dev-info {
  background: red;
  border-radius: 5px;
  color: get-font(ghost);
  display: inline-block;
  font-weight: bold;
  margin-bottom: 10px;
  padding: 10px;
}

// ----------------------------------------------------------------------

// Adding MF/MFA item(s)
.case-editor-interview-multif-add-initial {
  border-radius: $base-border-radius;
  border: 1px solid $light-border-color;
  margin: 0 0 20px 0;
  overflow: hidden;
  position: relative;

  .case-editor-interview-multif-add-initial-note {
    padding: 0;
    border: none;
    background: transparent;
    font-size: 14px;
  }

  & > div {
    float: left;
    width: 83%;

    &:first-child {
      left: 0;
      height: 100%;
      position: absolute;
      top: 0;
      width: 100px;

      label {
        display: block;
        height: 100%;
        padding: 8px;
        border-radius: $base-border-radius 0 0 $base-border-radius;
        background: get-brand(primary);
        color: $white;

        &:hover {
          background-color: darken(get-brand(primary), 10%);
        }

        .fa {
          left: 50%;
          position: absolute;
          top: 50%;
          -webkit-transform: translate(-50%, -50%);
             -moz-transform: translate(-50%, -50%);
              -ms-transform: translate(-50%, -50%);
                  transform: translate(-50%, -50%);
        }
      }

      + div {
        padding: 15px 15px 15px 125px;
      }
    }
  }
}
.case-editor-interview-multif-add-initial-label {
  label {
    color: get-font(lightColor);
    cursor: pointer;
    display: block;
    font-size: 60px;
    line-height: 90px;
    text-align: center;

    &:hover {
      color: get-font(color);
    }
    span {
      display: none;
    }
  }
}
.case-editor-interview-multif-add-initial-action {
  margin-bottom: 10px;
  overflow: hidden;

  h1 {
    float: left;
    font-size: 17px;
    font-weight: 400;
    line-height: 45px;
    margin-right: 20px;
    // ONSE-8640: limit length to avoid wraparound
    max-width: 86%;

    // ONSE-8640:
    // Remove vertical centering for long titles
    &.case-editor-interview-multif-add-long-label {
      line-height: 20px;
    }
  }
}
.case-editor-interview-multif-add-initial-select {
  background-color: $main-bg;
  float: left;
  padding: 10px;

  select {
    width: 70px;
  }
}
.case-editor-interview-multif-add-initial-note {
  background: get-brand(primary);
  border-radius: 3px;
  font-size: 12px;
  line-height: 18px;
  margin-bottom: 0;
  padding: 10px;
}

// ----------------------------------------------------------------------

// This module inserted at the bottom of interview pages belonging to MF/MFA items,
// so users can directly add another item and continue editing:

.case-editor-interview-multif-edit-goto-container {
  border-radius: 3px;
  font-size: 15px;
  text-align: center;
  line-height: 18px;
  margin: 20px 0;
  padding: 15px;
  background: $main-bg;

  p {
    margin-bottom: 20px;
  }
  em {
    color: get-brand(primary);
    font-weight: bold;
  }
  div {
    text-align: center;

    button {
      display: inline-block;
    }
  }
}

// ----------------------------------------------------------------------

// Editing existing MF/MFA items
ul.case-editor-interview-multif-selector {
  overflow: hidden;

  li {
    float: left;
    margin: 0 20px 20px 0;
    padding: 15px;
    width: 300px;
    border-radius: 3px;
    background: $main-bg;

    h1 {
      border: 0;
      padding: 10px;

      span {
        display: block;
        height: 42px;
        line-height: 20px;
        margin-bottom: 15px;
        overflow: hidden;
      }
    }
    h1,
    a.case-editor-interview-multif-item-label {
      display: block;
      padding: 0 0 10px;
      background: transparent;
      color: $cameleon-link-color;
      font-size: 16px;
      font-weight: 400;
      word-wrap: break-word;
    }

    .case-editor-interview-multif-item-controls {
      padding: 0;
      text-align: center;

      a.btn,
      a.btn-hilited,
      button.btn-hilited {
        padding: 8px;
        vertical-align: 0;
        width: auto;
        font-size: 14px;
        text-align: center;

        i.fa {
          font-size: 100%;
        }
      }
      .case-editor-interview-multi-item-rename > .fa-pencil {
        vertical-align: 0;
      }
      .case-editor-interview-multi-item-rename > .fa-pencil:before {
        content: "\f013";
      }
    }

    // This is a special list item for adding new items;
    // it's only present when there is at least 1 MF/MFA item
    &.case-editor-interview-multif-add {
      border: 2px dotted $light-border-color;
      background: transparent;

      h1 {
        background: $light-bg;
        font-size: 60px;
        line-height: 92px;
        text-align: center;

        a {
          color: get-brand(primary);
          cursor: pointer;
          display: block;

          &:hover {
            color: get-font(color);
          }
        }
        span {
          display: none;
        }
      }
      button.btn-hilited {
        display: block;
        text-align: center;
        width: 100%;
      }
    }
    .case-editor-interview-multif-item-controls {
      .btn {
        padding: 8px;
        width: auto;
        font-size: 14px;
        text-align: center;
      }
      .case-editor-interview-multi-item-add {
        width: 100%;
      }
    }
  }
}
a.case-editor-interview-multif-item-label {
  color: get-brand(primary);
  font-size: 16px;
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
}
.case-editor-interview-multif-item-controls {
  padding: 10px;

  a.btn {
    display: block;
    margin-bottom: 10px;
    width: 198px;
  }
  div {
    overflow: hidden;

    button {
      float: right;

      &:first-child {
        float: left;
      }
    }
  }
}

// I don't think we are still using this one:
.case-editor-interview-multif-selector-empty {
  background: $main-bg;
  border-radius: 3px;
  padding: 10px;
  text-align: center;

  h3 {
    font-weight: bold;
    line-height: 18px;
    margin: 0 0 5px 0;
  }
  p {
    margin: 0;
  }
}
