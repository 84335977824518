#case-editor-header {
  background: $white;
  border-bottom: 1px solid $light-border-color;
  overflow: hidden;
  padding: 15px 20px;
}

#case-editor-title-container {
  float: left;
  width: 26%;
}
#case-title-year {
  background: $white;
  border-radius: 2px;
  border-top: 1px solid $gray;  // NOTE: non-standard color
  color: get-font(color);
  display: block;
  float: left;
  font-weight: bold;
  line-height: 13px;
  margin-right: 10px;
  margin-top: 13px;
  padding: 4px 0 3px 0;
  text-align: center;
  width: 15%;
}
#case-title-year-label {
  display: none;
}
#case-title-name {
  display: block;
  float: left;
  font-size: 23px;
  font-weight: bold;
  line-height: 35px;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 80%;
}
#case-title-name-label {
  display: none;
}

#case-editor-status-container {
  float: left;
  width: 26%;
}
#case-status-display {
  margin: 0 auto;
  width: 410px;
  background: transparent;
}

#case-editor-controls-container {
  float: right;
}
#case-editor-controls {
  float: right;
  margin-top: 2px;
  white-space: nowrap;

  .btn,
  button {
    padding: 12px 14px;
    font-size: 16px;
  }
}
#case-search {
  float: left;
  margin-right: 10px;
  overflow: hidden;
  position: relative;

  input {
    background: $light-bg;
    border: 1px solid $base-border-color;
    border-right-width: 0;
    border-radius: $base-border-radius 0 0 $base-border-radius;
    font-family: get-font(baseFamily);
    color: get-font(color);
    display: block;
    float: left;
    font-size: 16px;
    line-height: 1.5;
    margin: 0;
    padding: 8px 35px 9px 15px;
    width: 200px;

    @media screen and (min-width: 1200px) {
      width: 290px;
    }

    @include placeholder() {
      font-weight: normal;
    }

    &:focus {
      border-radius: $base-border-radius;
    }

    &:focus,
    &:hover {
      background: $white;
      color: get-font(color);
      outline: none;
    }

    .btn {
      padding: 0;
      position: absolute;
      right: 14px;
      top: 12px;
      background: transparent;
      color: get-font(lightColor);
      font-size: 15px;
      @include transition(all .3s);

      &:hover {
        background: transparent;
        @include scale(1.1);
      }
    }
  }
  button {
    border-left-width: 1px;
    border-radius: 0 3px 0 0;
  }
}

#case-editor-waiting {
  background: url($action_indicator) center center no-repeat;
  color: get-font(lightColor);
  font-size: 20px;
  font-weight: bold;
  line-height: 30px;
  min-height: 200px;
  padding-top: 130px;
  text-align: center;
  width: 100%;
}

.case-editor-registration-reminder {
  margin-top: 20px;

  .btn {
    box-sizing: border-box;
    width: 100%;
  }
}

#case-editor-continue-container {
  margin: 20px 0 0;

  &:after {
    content: '';
    clear: both;
    display: table;
  }

  button, a.btn {
    float: right;
    padding: 10px 14px;

    @media screen and (min-width: 1300px) {
      padding: 12px 25px;
    }

    &:first-child {
      float: left;
    }
  }
  // But if we have a button group (for next page and next error buttons),
  // we float that group right, and un-float the buttons inside it.
  .btn-group {
    float: right;

    .btn {
      float: left;
    }
  }
}

#case-filing-documents {
  #ui-case-editor-receipts-print-content {
    ul {
      margin-left: 0;
      list-style: none;
      border: 1px solid #eef1f4;
    }
    li {
      padding: 15px 15px 15px 55px;
      position: relative;
      border-top: 1px solid #eef1f4;

      &:first-child {
        border-top: none;
      }

      &:before {
        content: "\f061";
        display: inline-block;
        left: 20px;
        position: absolute;
        top: 50%;
        -webkit-transform: translateY(-50%);
           -moz-transform: translateY(-50%);
                transform: translateY(-50%);
        font: normal normal normal 14px/1 FontAwesome;
        font-size: inherit;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
      }
    }
  }
  .case-editor-filing-module {
    padding: 20px;
    position: relative;
    border-radius: $base-border-radius;
    border: 1px solid $light-border-color;

    &:after {
      content: '';
      clear: both;
      display: table;
    }

    > div {
      padding: 0;
      border: none;
    }
    + .case-editor-filing-module {
      margin-top: 35px;
    }
  }
  .case-editor-filing-module__aside {
    float: left;
    padding-right: 30px;
    width: 25%;
    color: get-font(lightColor);
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;

    .fa {
      color: get-font(lightColor);
      font-size: 120px;
      line-height: 1;
    }
    .icon-circle {
      left: 15px;
      position: absolute;
      top: 15px;

      .fa {
        color: #fff;
        font-size: 17px;
      }
    }
    .btn {
      margin-top: 12px;

      &:first-child {
        margin-top: 0;
      }
    }
  }
  .case-editor-filing-module__main {
    float: left;
    padding-left: 50px;
    width: 75%;

    .btn {
      margin: 30px 0 0;
    }
  }
  .case-editor-filing-module-highlight {
    .case-editor-filing-module-state-control {
      margin-bottom: 0;
    }
  }
}

.case-interview-field-input-chooser {
  display: inline-block;
  overflow: hidden;
  position: relative;
  width: 145px;
  border: 1px solid $base-border-color;
  border-radius: 3px;
  font-size: 13px;
  text-transform: uppercase;
  text-align: center;

  input {
    position: absolute;
    right: 0;
    top: 0;
    visibility: hidden;
  }

  &__positive,
  &__negative {
    cursor: pointer;
    display: inline-block;
    margin-right: -4px;
    padding: 5px;
    position: relative;
    vertical-align: middle;
    width: 50%;
    line-height: 1;

    &:first-child {
      margin-right: 0;
    }

    &:before {
      content: '';
      display: inline-block;
      height: 8px;
      margin-right: 5px;
      vertical-align: middle;
      width: 8px;
      border: 1px solid $base-border-color;
      border-radius: 50%;
    }
    &.is-active {
      background: rgba(get-brand(primary), .1);
      color: get-brand(primary);
      pointer-events: none;

      &:before {
        background: get-brand(primary);
        border-color: white;
        box-shadow: 0 0 0 1px get-brand(primary);
      }
    }
  }

  &__positive {}
  &__negative {}
}

.case-editor-cta-bar {
  margin: 20px 0 0;
  line-height: 0;
  text-align: justify;

  &:first-child {
	margin-top: 0;
  }

  &:after {
	content: '';
	display: inline-block;
	width: 100%;
  }
  > * {
	display: inline-block;
	margin-top: 0;
	vertical-align: middle;
	line-height: 1;
  }
  > .btn {
	margin-top: 0 !important;
  }

  #case-editor-main-body & {
	margin: 20px 0 0;
  }
}
.case-editor-cta-bar-addition {
  margin-top: 5px;
  font-size: 12px;
}
.case-editor-cta-bar-col-left {
  float:left;
  width:35%;
}
.case-editor-cta-bar-col-right {
  float:right;
  width:65%;
}
.case-editor-cta-bar-btn-webid {
  background-color:#05b1fb;
}
.case-editor-cta-bar-btn-yes {
  word-spacing: 16px;
}

@media only screen and (max-width: 1400px) {
  .case-editor-cta-bar-col-right{width: 100%; padding: 10px 0px}
}

#case-editor-filing-product .case-editor-filing-module {
  position: relative;

  &.is-loading {
    cursor: default;
    pointer-events: none;
    opacity: .7;
  }
}

#case-editor-filing-product {
  #case-editor-waiting {
    left: 50%;
    position: absolute;
    top: 50%;
    width: auto;
    z-index: 1000;
    -webkit-transform: translate(-50%, -50%);
       -moz-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
  }
  #case-editor-waiting + #case-editor-filing-product:before {
    content: '';
    left: 0;
    height: 100%;
    opacity: .7;
    position: absolute;
    top: 0;
    width: 100%;
    background: white;
  }
}

#case-editor-decree-preview {
  padding: 20px;
  border: 1px solid $info-border-color;
  background: $info-bg;
}