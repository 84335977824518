#cases-manager-header {
  overflow: hidden;
  padding: 15px 20px 0;

  & > h1 {
    margin-top: 13px;
    float: left;
    font-size: 24px;
    font-weight: bold;
    line-height: 1;
    text-transform: uppercase;
  }
}
#cases-manager-controls {
  float: right;
}
#cases-manager-add-case-panel {
  border-radius: 3px;
  display: inline-block;
  overflow: hidden;

  h1,
  .btn-group {
    float: left;
  }
  h1 {
    line-height: 30px;
    margin-right: 25px;
    margin-top: 8px;
    font-size: 17px;
    font-weight: 400;
  }
}

#cases-manager-body {
  min-height: 660px;
  padding: 20px;

  .case-manager-empty-list-message {
    margin: 0 auto;
    text-align: center;
    margin-bottom: 20px;

    div {
      margin-top: 75px;
      text-align: center;
      font-size: 25px;
      line-height: 50px;
      color: get-font(color);

      em {
        color: get-brand(primary);
      }
    }

    h3 {
      font-size: 20px;
      color: $gray-dark;
    }
  }
}
#cases-manager-caselist,
#cases-manager-case-legacy-list {
  margin-left: 220px;
  // cannot use overflow: hidden to clear floats
  // because .case-functions-menu would be clipped inside this element
  // so we have to use the old clearfix hack :/
  &:after {
    content: ".";
    display: block;
    height: 0;
    clear: both;
    visibility: hidden;
  }
}

#cases-manager-caselist {
  display: flex;
  flex-wrap: wrap;

  &:after {
    content: '';
    clear: both;
    display: table;
  }
}

#cases-manager-sidebar {
  margin: 20px 0 20px 20px;
  overflow: hidden;
  position: absolute;
  text-indent: -999px;
  width: 220px;
}

.cases-manager-sidebar-box {
  text-align: center;
  padding: 16px;
  text-indent: 0;

  & .btn {
    width: 100%;
  }
}

.cases-manager-sidebar-title {
  font-size: 19px;
  font-weight: bold;
  margin-bottom: 12px;
}

.cta-box {
  background-color: $blue;
  color: $white;
  margin-bottom: 12px;

  .cta-box-header {
    overflow: auto;
    position: relative;
    padding: 18px 0 10px;

    & img {
      position: relative;
      left: 4px;
    }

    & a {
      position: absolute;
      right: 0;
      top: -6px;
      color: #ffffff;
      font-size: 30px;
      cursor:pointer;

      &:hover {
        opacity:0.85;
      }
    }
  }
}

.cta-box-text {
  padding: 16px 0 20px;
  font-size: 14px;
  line-height: 150%;

  & strong {
    font-weight: bold;
  }
}

.import-list {

  list-style: none !important;

  li {
    margin-top: 20px;
  }

  label {
    font-weight: bold;
    margin-bottom: 20px;
  }

  .import-type {
    width: 47%;
    float: left;
  }

  .import-help {
    width: 50%;
    float: right;
  }

  .clear {
    clear: both;
  }

  .li-full {
    label {
      width: 100%;
    }

    border: none;
    padding: 0px;
  }

}