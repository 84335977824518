$footer-bg: $white;

.l-contentinfo {
  margin-top: 60px;
  padding-bottom: 50px;
  padding-top: 50px;
  background: $footer-bg;
  border-top: 1px solid $base-border-color;
  font-size: .9rem;

  a {
    color: $base-link-color;
  }
  .btn {
    margin-top: 4px;
  }
}
.l-contentinfo__constrained {
  display: flex;
  justify-content: space-between;
  margin-left: auto;
  margin-right: auto;
  max-width: $max-wrapper-width;
  padding-left: $prevent-spacing;
  padding-right: $prevent-spacing;
}

.l-contentinfo__offset {
  display: flex;
  justify-content: flex-end;
  width: 30%;

  .l-contentinfo__navigation {
    padding-right: 40px;
    text-align: right;
  }
  .is-mobile & {
    justify-content: flex-start;
    margin-top: 30px;
    width: auto;
  }
}

.l-contentinfo__tuev {
  height: 65px;
  width: 130px;
  background: url($app_logos) -500px -200px no-repeat;
}

.l-contentinfo__contact {
  display: flex;
  margin-top: 25px;

  &__item {
    padding-right: 20px;
    padding-left: 20px;
    border-left: 1px solid $base-border-color;

    &:first-child {
      padding-left: 0;
      border-left: 0;
    }
    &:last-child {
      padding-right: 0;
    }
  }
}

.footer-links {
  text-align: center;

  a {
    margin-right: 30px;
  }
}