#case-editor-sidebar {
  float: left;
  padding-right: 12px;
  width: 25%;
  @include transition(width .1s ease-in);

  #body.is-mobile & {
    height: 100%;
    width: 25%;
  }
  #body.is-inactive-sidebar & {
    width: 0%;
  }
}

.case-editor-sidebar-navigator-container {
  padding: 10px;

  #body.is-mobile & {
    height: 100%;
    left: 0;
    /*margin-top: 65px;*/
    -webkit-overflow-scrolling: touch;
    overflow: auto;
    padding: 0;
    position: absolute;
    top: 0;
    width: 24%;
    @include transition(width .1s ease-in);
    z-index: 97;

    .case-editor-sidebar-navigator-tree-container {
      margin-bottom: 65px;
    }
  }
  #body.is-inactive-sidebar & {
    transform: translateX(-100%);
  }
}
.case-editor-sidebar-navigator-tree-container {
  padding: 10px;
}

.lexoffice-backlink {
  margin-bottom: 8px;

  img {
    height: 25px;
    vertical-align: middle;
  }

  #body.is-mobile & + .case-editor-sidebar-navigator-container {
    margin-top: 85px;

    .case-editor-sidebar-navigator-tree-container {
      margin-bottom: 85px;
    }
  }
}

.case-editor-sidebar-trigger {
  display: none;
  padding: 12px;
  border-radius: 3px;
  background: $light-bg;
  color: get-brand(primary);
  font-size: 28px;
  line-height: 0.8;
  text-align: center;
  text-transform: uppercase;
  -webkit-font-smoothing: antialiased;

  &:hover {
    opacity: .7;
  }

  #body.is-mobile & {
    display: inline-block;
  }

  &.is-active:before {
    content: "\f18e";
  }
}