#case-editor-interview-section-overview {
  margin-bottom: 30px;
  padding: 20px;
  border-radius: $base-border-radius;
  border: 1px solid $info-border-color;
  background: $info-bg;
  color: get-font(color);

  h2 {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  p {
    margin-bottom: 20px;
  }
  em {
    font-weight: bold;
  }
  ul {
    list-style: square;
    margin-left: 20px;
  }
  li {
    margin-bottom: 10px;
  }

  &.case-editor-interview-section-overview-empty {
    display: none;
  }
}
