#app-header {
  overflow: hidden;
  position: relative;
  max-height: 800px;
  background: $app-header-bg;
  @include transition(max-height .8s);

  .btn {
    padding: 8px 12px;
    font-size: .85rem;
    line-height: 1;

    i:first-child {
      margin-right: 5px;
    }
  }
  .m-layer {
    margin: -15px;
  }
}

.l-banner__offset__hint {
  margin-top: 8px;
  font-size: .75rem;
  font-weight: bold;
  text-align: right;

  &, a {
    color: #fff;
  }
}

.l-banner__wrap {
  align-items: center;
  display: flex;

  .l-banner__extension {
    padding-left: 30px;
  }
}

.l-banner__extension {
  color: #fff;
  font-size: 22px;
  font-weight: normal;

  @if ($banner-extension-visibility != true) {
    display: none;
  }

  strong {
    font-weight: bold;
  }
}

.l-banner__constrained {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: 1380px;
  margin-left: auto;
  margin-right: auto;
}

.app-header-logo {
  height: $app-header-logo-height;
  overflow: hidden;
  width: $app-header-logo-width;
  background: url($app-header-logo);
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: 100%;
  text-indent: -999px;
}

.app-header-dropdown-year {
  color:$blue;
  width: 80px;
}

.app-header-select {
  select {
    height:40px;
    background-color: $blue;
  }

  .selectdiv {
    position: relative;
    /*Don't really need this just for demo styling*/
    float: right;
    min-width: 80px;
    margin-left:10px
  }

  .selectdiv:after {
    content: '\f078';
    font: normal normal normal 17px/1 FontAwesome;
    color: $blue;
    right: 12px;
    top: -2px;
    height: 29px;
    padding: 10px 0px 0px 8px;
    position: absolute;
    pointer-events: none;
  }

  .selectdiv select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    display: block;
    width: 100%;
    max-width: 90px;
    height: 35px;
    float: right;
    padding: 0px 10px;
    font-size: 16px;
    color: $blue;
    background-color: $white;
    background-image: none;
    border: 0;
    -ms-word-break: normal;
    word-break: normal;
    border-radius: 3px;
  }
}

.c-navigation {
  &__list {
    display: flex;
    justify-content: flex-end;
    list-style: none;
    margin: 0 0 0 -10px;
    padding: 0;

    > li {
      margin-left: 10px;
    }
  }
  &--vertical {
    .c-navigation__list {
      display: block;

      li {
        margin-top: 10px;
      }
      a {
        text-decoration: none;
      }
    }
  }
}

// In the case editor, we only show a compacted logo with no type, to save vertical space
.app-header-logo-compact {
  background-position: -500px 0;
  height: 80px;
  width: 80px;
}


.app-header-inline-reg-form-waiting {
  position: relative;

  &:before {
    content: '';
    display: block;
    height: 100%;
    opacity: 0.8;
    position: absolute;
    width: 100%;
    z-index: 90;
    background: $white;
  }
  &:after {
    content: '';
    display: block;
    height: 25px;
    left: 50%;
    position: absolute;
    top: 50%;
    width: 25px;
    z-index: 100;
    background: url(../img/preloader.gif) no-repeat;
    transform: translate(-50%, -50%);
    background-size: 100%;
  }
}

.app-header-inline-reg-form-thanks {
  padding: 50px 20px;
  background: $white;
  text-align: center;

  h1 {
    margin-bottom: 10px;
    font-size: 22px;
    font-weight: bold;
  }

  button {
    margin-top: 10px;
    border-bottom: 1px dotted;
    background: transparent;
    color: $base-link-color;
    font-size: 14px;
  }
  button:hover {
    border-bottom: none;
  }
}