.case-editor-tax-assessment {
  & > div {
    margin-top: 40px;
  }
  h2, p {
    margin-bottom: 10px;
  }
  h2 {
    font-size: 16px;
    font-weight: bold;
  }
    em {
      font-weight: bold;
    }
  table {
    border-collapse: collapse;
    margin: 20px 0 30px 0;
  }
  tr {
    vertical-align: top;
  }
  th, td {
    border: 1px solid $light-border-color;
    border-width: 1px 0;
    padding: 10px 0;
  }
  thead {
    th {
      border-color: $base-border-color;
      font-weight: bold;
      text-align: right;
      &:first-child {
        text-align: left;
      }
    }
  }
  tbody {
    th {
      position: relative;
    }
    td {
      font-weight: bold;
      padding-left: 20px;
      text-align: right;
    }
  }
  var {
    background-color: get-brand(primary);
    color: $white;
    display: none;
    margin-left: 5px;
    margin-top: -5px;
    padding: 5px 10px;
    position: absolute;
    white-space: nowrap;
  }
  th:hover var {
    display: inline;
  }
  .level2 {
    display: none;
    th {
      padding-left: 40px;
    }
  }
  .hasDiff {
    color: $alert-color;
  }
  .noDiff {
    color: get-brand(primary);
  }
}
.case-editor-tax-assessment-result-type {
  white-space: nowrap;

  i.fa-check-circle {
    color: get-brand(primary)
  }
  i.fa-thumbs-o-up {
    color: $success-color;
  }
  i.fa-thumbs-o-down {
    color: $alert-color
  }
}
.case-editor-tax-assessment-quote {
  border: 1px solid $base-border-color;
  color: get-font(color);
  margin: 10px 0 20px 0;
  padding: 20px;
}
