//  From: http://yuilibrary.com/yui/docs/cssreset/

//  YUI 3.5.1 (build 22)
//  Copyright 2012 Yahoo! Inc. All rights reserved.
//  Licensed under the BSD License.
//  http://yuilibrary.com/license/

//  Unminified, so you can see more easily what's going on here.

html {
  color: #000;
  background: #FFF
}
body,
div,
dl, dt, dd,
ul, ol, li,
h1, h2, h3, h4, h5, h6,
pre, code,
form, fieldset, legend, input, textarea,
p, blockquote,
table, th, td // added table
// TODO: Should we add new HTML5 elements here? As soon as browsers implement default styles for them, we have to...
{
  margin: 0;
  padding: 0;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
fieldset, img {
  border: 0;
}
address, caption, cite, code, dfn, em, strong, th, var {
  font-style: normal;
  font-weight: normal;
}
ol, ul {
  list-style: none;
}
caption, th {
  text-align: left;
}
h1, h2, h3, h4, h5, h6 {
  font-size: 100%;
  font-weight: normal;
}
q:before, q:after {
  content: '';
}
abbr, acronym {
  border: 0;
  font-variant: normal;
}
sup {
  vertical-align: text-top;
}
sub {
  vertical-align: text-bottom;
}
// Webkit seems to ignore this and does not inherit the font settings from body,
// so we have to explicitly set them (elsewhere).
input, textarea, select, optgroup, option {
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
}
input, textarea, select, optgroup, option {
  *font-size: 100%;
}
legend {
  color: #000;
}
