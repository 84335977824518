#body.is-inactive-sidebar {
  overflow: auto;
}

#case-editor {
  #body.is-mobile & {
    overflow: hidden;
  }
}

#case-editor-body,
#case-editor-body-with-sidebar {
  overflow: visible;
  background: $light-bg;

  &:after {
    content: '';
    clear: both;
    display: table;
  }

  .is-mobile & {
    position: relative;
  }
}
#case-editor-main {
  //background-color: $light-bg;
  //box-shadow: 0 3px 3px rgba($black, .1);
  //border-radius: $base-border-radius;

  #case-editor-body-with-sidebar & {
    float: right;
    width: 74%;
  }
}

#case-editor-header {
  padding: 15px 0 20px;
  border: none;
  background: transparent;
}

#case-editor-body-with-sidebar {
  background: transparent;
}

#case-editor-body-with-sidebar #case-editor-main {
  @include transition(width .1s ease-in);

  #body.is-mobile & {
    width: 74%;
  }

  #body.is-inactive-sidebar & {
    width: 100%;
  }
}

#case-editor-main-header {
  min-height: 180px;
  overflow: visible;
  padding: 0;
  position: relative;
  background: $light-bg;
  line-height: 1.45;

  ul,
  ol {
    list-style: circle;
    padding-left: 20px;
  }
  ol {
    list-style: decimal;
  }
  p, ul, ol, li {
    margin-top: 8px;
  }
  p:first-child,
  ul:first-child,
  ol:first-child,
  li:first-child {
    margin-top: 0;
  }

  .case-editor-section-header__image {
    display: block;
    height: 100%;
    position: absolute;
    width: 100%;
    background-image: url($header-default-image);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .case-editor-section-header__image--default {
    opacity: 0.4;
    background-position: top right;
  }

  .case-editor-section-header-description__wrapper {
    padding-bottom: 25px;
    text-shadow:
      0 0 8px $white,
      0 0 8px $white,
      0 0 8px $white,
      0 0 8px $white,
      0 0 8px $white;
  }
  .case-editor-section-header-description__wrapper,
  #case-editor-section-title {
    padding-left: 25px;
    position: relative;
    z-index: 10;
  }

  .case-editor-section-help {
    font-size: 16px;
  }
  .case-editor-section-help a {
    border-bottom: 1px dotted;
    color: get-brand(primary);
    font-weight: bold;

    &:hover {
      border-bottom: none;
    }
  }

  #case-editor-main-controls {
    float: none;
    position: absolute;
    right: 20px;
    top: -45px;
    width: auto;
    z-index: 20;

    .btn,
    button {
      border-left: none;
      background: $light-bg;
      color: darken(#8f9caa, 20%);

      &:hover {
        opacity: .7;
      }
    }
  }
}
#case-editor-section-title {
  padding-right: 180px;
  font-weight: bold;
  float: none;
  margin-bottom: 8px;
  padding-top: 25px;
  position: relative;
  color: get-font(color);
  font-size: 24px;
  line-height: 1.3;
}

.case-editor-section-header-description__wrapper {
  padding-right: 180px;
  position: relative;
}

#case-editor-main-controls {
  float: right;
  position: absolute;
  right: 0;
  width: 120px; // Width limit, see ONSE-6674
}
#case-editor-page-skipper {
  // ONSE-6674
  // Align right, but we can only do this if we don't use the sidebar opener (below) again
  // If float: left, because of percentage width of #case-editor-main-controls,
  // page skipper will not be flush right.
  float: right;
}

#case-editor-main-body {
  padding: calc(($case-editor-padding-top / 2)) $case-editor-padding-y;
  position: relative;
  z-index: 11;


  @media screen and (min-width: 1200px) {
    padding: $case-editor-padding-top $case-editor-padding-y;
  }
}

.case-editor-body-filing {
  background-color: transparent;

  #case-editor-main-body {
    background-color: unset;
    background-color: transparent;
  }
}

.case-editor-body {
  margin-top: -$case-editor-padding-top;
  padding: calc(($case-editor-padding-top / 2)) $case-editor-padding-y;
  position: relative;
  z-index: 11;
  background-color: transparent;

  #case-editor-main {
    background-color: unset;
    background-color: transparent;
  }
}

.case-editor-main-body {
  background-color: $light-bg;
}