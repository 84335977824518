#case-interview-header {
  margin-bottom: 20px;

  .case-editor-section-help,
  #case-interview-help {
    margin-bottom: 20px;
    padding: 20px;
    border: 1px solid $light-border-color;
  }
  .case-editor-interview-help {
    margin-bottom: 30px;
    margin-top: 10px;
    padding: 0;
    border: none;
    background: transparent;
    color: get-font(color);
    font-size: 14px;

    ul,
    ol {
      margin-top: 12px;
    }
  }
  ul,
  ol {
    margin-top: 12px;
  }
}
.case-editor-section-help {
  margin-bottom: 20px;
}
#case-editor-section-help strong {
  font-weight: bold;
}

#case-interview-path {
  // We are not currently displaying the complete path at all, so diabling this for now.
  // If we keep this, we get about 30px of unnecessary gap at the top of the interview.
  display: none;
}
#case-interview-help {
  margin-bottom: 30px;
}

#case-interview-body {
  // ONSE-7727: Due to overflow: hidden, the line-reference tooltip
  // on the field label was clipped when the first field had a line reference.
  // So instead we have to use the old clearfix hack here, too.
  &:after {
    content: ".";
    display: block;
    height: 0;
    clear: both;
    visibility: hidden;
  }
  .app-alert {
    margin-bottom: 20px;
  }
}

.case-interview-body-with-field-help #case-interview-form {
  float: left;
  width: 68%;

  @media screen and (min-width: 1200px) {
    width: 64%;
  }
}
#case-interview-field-help {
  float: right;
  padding-left: 10px;
  position: relative;
  width: 31%;

  @media screen and (min-width: 1200px) {
    width: 34%;
  }

  &:not(.no-triangle) {
    &:before {
      content: '';
      left: 1px;
      position: absolute;
      top: 11px;
      z-index: 2;
      border-bottom: 10px solid transparent;
      border-right: 10px solid $info-bg;
      border-top: 10px solid transparent;
    }
    &:after {
      content: '';
      left: -1px;
      position: absolute;
      top: 10px;
      z-index: 1;
      @include triangle(left, 11px, darken($info-bg, 17%));
    }
  }

  // Name: Error
  &.is-error {
  }

  // Name: Success
  &.is-success {
    &:before {
      border-right-color: $success-border-color;
    }
    .case-editor-interview-help {
      background: $success-bg;
    }
  }

  .case-editor-interview-help {
    padding: 15px;
  }
}
#case-interview-field-help-inner {
  background: $info-bg;
  padding-top: 10px;
}
