.case-controls {
  // cannot use overflow: hidden to clear floats
  // because .case-functions-menu would be clipped at bounds of .case-operations-container
  // so we have to use the old clearfix hack :/
  &:after {
    content: ".";
    display: block;
    height: 0;
    clear: both;
    visibility: hidden;
  }

  .btn {
    padding: 7px 12px;
    font-size: 14px;
  }

  // *.btn are now the same height, so we don't need to hard-code the height anymore.
  // Also, we can now have both the normal inline controls as well as the stacked one-or-more-buttons
  // in #cases-manager-caselist, so hard-coding the controls height is no longer possible.
  // I'm leaving this in, though, in case this causes problems again.

  // ONSE-7042 : Make case boxes have the same height to avoid boxes not wrapping properly.
  // The basic issue is that we get inproper float wrapping when two boxes of differing height meet.
  // Height differences are caused by 2 issues:
  // 1. Previous-year (migratable) cases have two stacked buttons but older cases have only one.
  //    Both are in the same <ul>, so they can meet.
  // 2. In non-Webkit Browsers, a.btn and button.btn are not exactly the same height,
  //    so we would get different total heights in cases with both or just one type of .btn.
  // #cases-manager-caselist & {
  //  height: 35px;
  // }
  // #cases-manager-case-legacy-list & {
  //  height: 75px;
  // }
}
// Wrappers for when we have two controls; left and right aligned
.case-control-left {
  float: left;
}
.case-control-right {
  float: right;
}
// Stacked full-width button controls
// ONSE-8769: We currently have left/right controls for the current year cases always,
// and stacked controls for all other years. Since *all* other-year cases are in the same <ul>
// (ul.cases-manager-case-legacy-list), they are all rendered inline, but the number of buttons
// is different depending on the year. The previous-year cases are migratable and thus have
// a migrate and an edit button. The older cases have only an edit button. To avoid layout
// glitches because of the different <li> heights, this container gets a fixed height.
// See ONSE-7042 above which is an earlier incarnation of this same problem.
.case-control-stacked {
  height: 75px; // 31+31+10 plus a few px of buffer for browser quirks
  .btn {
    margin-top: 10px;
    text-align: center;
    width: 100%;

    &:first-child {
      margin-top: 0;
    }
  }
}
.case-operations-container {
  position: relative;
}
.case-operations-menu {
  background: $light-bg;
  border-radius: 3px;
  box-shadow: 0 2px 15px rgba($black, .3);
  padding: 12px;
  position: absolute;
  top: 40px;
  // ONSE-6737: Must be >1, because the waiting mask for case items has z-index 1,
  // and if a "waiting" case item is the right neighbour, that mask would render
  // over the context menu of the left neighbour, which looks wrong.
  z-index: 2;

  &:before {
    background: url($operation_menu_pointer) 0 0 no-repeat;
    background-size: 20px 10px;
    content: " ";
    bottom: 100%;
    display: block;
    height: 10px;
    position: absolute;
    width: 20px;
  }

  > li {
    margin-top: 10px;

    &:first-child {
      margin-top: 0;
    }
  }
}
.menu-item {
  background: none;
  color: get-font(color);
  cursor: pointer;
  display: block;
  text-align: left;
  white-space: nowrap;
  font-size: 15px;
  font-weight: normal;
  line-height: 1;

  &:hover {
    color: get-brand(primary);
  }
}
