////
/// @group Banner
/// @author Tim Hartmann
////

// 1.Config
$banner: (
	bgColor: $orange-light,
	borderColor: $orange-medium
) !default;

// 2.Base
/// @name Default Banner
.m-banner {
  margin-top: 30px;
	padding: 30px;
	position: relative;
	border: 1px solid map-get($banner, borderColor);
	background: map-get($banner, bgColor);

	&:after {
		content: '';
		clear: both;
		display: table;
	}

  p {
	margin-top: 10px;
  }
  .btn {
		margin-top: 15px;
  }
  .fa {
  	display: none;
  }
	ul {
		margin: 25px 0 0;
		font-weight: bold;
	}

	li {
		margin: 10px 0 0;

		&:before {
			content: '✔';
			padding-right: 5px;
		}
	}

  #case-editor-summary-view & {
    margin-left: 40px;
  }
}

.m-banner__list {
	list-style: none;
	margin: 0;
	padding: 0;
}

.m-banner--slim {
  &.m-banner {
    padding: 20px;
  }
  .m-banner__matter {
  	width: auto
  }
}

.m-banner--full {
	padding: 40px 30px 30px !important;
	position: relative;
	border-radius: $base-border-radius;
	border: none;
	background: $light-bg;
	box-shadow: 0 3px 3px rgba(0, 0, 0, 0.1);
	font-size: 18px;

	&:before {
		content: '';
		height: 40px;
		display: block;
		left: 0;
		position: absolute;
		top: -30px;
		width: 100%;
		box-shadow: inset 0 -7px 5px -5px rgba(0,0,0,0.1), inset 0 7px 5px -5px rgba(0,0,0,0.1), 30px 0 0 0 #eef1f4, -30px 0 0 0 #eef1f4;
		background: #eef1f4;
	}

	&:after {
		content: '';
		clear: both;
		display: table;
	}

	.m-banner__matter {
		padding-left: 40px;
		float: left;
		width: 60%;
		color: get-font(color);

		@media screen and (min-width: 1350px) {
			width: 70%;
		}
	}

	.m-banner__title {
		font-size: 28px;
		font-weight: bold;
	}

	p {
		margin-top: 10px;
		line-height: 1.4;
	}

	.fa {
		display: block;
		float: left;
		color: $gray-lighter;
		font-size: 230px;
	}
	.btn-l {
		margin-top: 35px;
		font-size: 18px;
	}
}

.m-banner__matter {
	float: left;
	width: 60%;
	color: #404040;
	font-size: 15px;

	@media screen and (min-width: 1350px) {
		width: 70%;
	}
}

.m-banner__keyvisual {
	@include box-sizing();
	bottom: 0;
	padding-left: 40px;
	position: absolute;
	right: 0;
	width: 48%;
	text-align: right;

	@media screen and (min-width: 1350px) {
		width: 38%;
	}

	img {
		vertical-align: bottom;
		height: auto;
		max-width: 100%;
		width: auto;
	}
}
.m-banner__title {
	color: get-font(color)_dark;
	font-size: 21px;
	line-height: 1.3;
}


// Name: Xing-Banner
.m-banner-xing {
  margin-top: 20px;
	padding: 60px 50px 50px;
	border: 1px solid $light-border-color;
	border-radius: $base-border-radius;
	background: url(../img/xing-banner-background.jpg) no-repeat center center;
	background-size: cover;
	text-align: center;

	.btn--connect {
		margin-right: 15px;
	}
	.app-alert {
		margin-top: 30px;
		border-radius: $base-border-radius;
		background: #fff;
	}
}
.m-banner-xing__title {
	font-size: 23px;
	text-shadow: 0 0 10px #fff;

	strong {
		font-weight: bold;
		text-decoration: underline;
	}
}
.m-banner-xing__subtitle {
	margin-top: 8px;
	color: $gray-lighter;
	font-size: 16px;
	text-shadow: 0 0 10px #fff;
}

.m-banner-xing__info {
	margin-bottom: 50px;
}

.m-banner-xing__advantages {
	margin-bottom: 12px;
	margin-left: -30px;
	margin-top: 40px;
	color: $xing;
	font-size: 15px;
	text-shadow: 0 0 10px #fff;

	> li {
		display: inline-block;
		margin-left: 30px;
		vertical-align: middle;
		font-weight: bold;
	}
	.fa {
		margin-right: 5px;
		color: $xing;
		font-size: 18px;
	}
}

.m-banner-xing__data {
	margin-bottom: 30px;
	margin-top: 30px;
	font-size: 16px;
	text-align: left;
}
.m-banner-xing__data__label,
.m-banner-xing__data__value {
	padding-top: 10px;
	vertical-align: top;
}

.m-banner-xing__footer {
	margin-top: 35px;
}

.m-banner-xing__data__label {
	font-weight: bold;
}
.m-banner-xing__data__value {
	padding-left: 20px;
}


.m-banner-xing--final {
	background-image: url(../img/xing-banner-final-background.jpg);
}





// BANNER FOR USABILITY-TEST
.m-usability-banner {
  padding: 22px;
  position: fixed;
  right: 0;
	top: 50%;
	backface-visibility: hidden;
	-webkit-transform: translate(100%, -50%);
			-ms-transform: translate(100%, -50%);
					transform: translate(100%, -50%);
  z-index: 2000;
  transition: all .3s linear;
  border: 3px solid #5cb85c;
  border-right: none;
  background: #ebf4d9;
  box-shadow: 0 0 20px rgba(0,0,0,.3);
  font-size: 17px;
  font-weight: bold;

	&.is-open {
		-webkit-transform: translateY(-50%);
				-ms-transform: translateY(-50%);
						transform: translateY(-50%);

		.m-usability-banner__trigger:before {
	    content: "\f054";
	  }
	}

  .btn {
    margin-top: 20px;
    text-transform: uppercase;
  }
  &:after {
    clear: both;
    display: table;
    content: '';
  }
}

.m-usability-banner__trigger {
  display: inline-block;
  padding: 18px 12px;
  position: absolute;
  right: 100%;
	top: 50%;
	-webkit-transform: translateY(-50%);
			-ms-transform: translateY(-50%);
					transform: translateY(-50%);
  border: 3px solid #5cb85c;
  border-right: none;
  background: #ebf4d9;
  color: #666;
  font-size: 24px;
  text-align: center;
  text-decoration: none;
}

.m-usability-banner__title {
  margin-bottom: 8px;
  font-size: 27px;
  font-weight: bold;
}
.m-usability-banner__subtitle {
  padding-right: 120px;
}

.m-usability-banner__asset {
  bottom: 0;
  height: 70px;
  max-width: 151px;
  width: 100%;
  position: absolute;
  right: 0;
  background: url(../img/smartsteuer-refresh-screenshot.png) no-repeat;
}

.m-usability-banner__hint {
  height: 36px;
	margin-left: 30px;
	margin-top: 12px;
  padding: 20px 0 0 25px;
  max-width: 230px;
  width: 100%;
  background: url(../img/usability-banner-hint.png) no-repeat;
}

.m-usability-banner--back {
  .m-usability-banner__subtitle {
    padding-right: 0;
  }
}


.m-banner-left-with-margin {
	float: left;
	margin-right: 30px;
}
.m-banner-left-with-margin-clear {
	clear: both;
}