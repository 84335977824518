.case-editor-interview-field-buttonpicker-select {
  display: none;
}

// Hacks to make buttonpicker fields work within the default field markup
.case-interview-field-image-list {
  padding-left: 0;

  &:hover {
    background: none;
  }

  label {
    font-size: 18px !important;
    font-weight: bold !important;
    margin-bottom: 25px !important;
    cursor: default;
    width: 100% !important;
  }
  .case-interview-field-label-line-reference {
    display: none;
  }

  .case-interview-field-body {
    padding-left: 0;
    width: 100%;
  }
  &.case-interview-field {
    border: none;

    &:hover {
      background: transparent;
    }
  }
}

.case-editor-interview-field-button-list {
  display:flex;
  flex-wrap: wrap;
  justify-content: center;

  & button {
    display:flex;
    align-items: center;
    max-width:250px;
    margin-bottom:20px;

    &:not(:last-child) {
      margin-right:25px;
    }

    & .case-editor-interview-field-button-label {
      white-space: normal;
      text-align: left;
      line-height: 1.4;
    }

    & i {
      margin-left: 20px;
    }
  }
}
