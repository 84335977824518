.m-tooltip {
  position: relative;

  &:hover {
    .m-tooltip__matter {
      display: block;
    }
  }
}
.m-tooltip__trigger {

}
.m-tooltip__matter {
  bottom: 100%;
  display: none;
  padding: 15px;
  position: absolute;
  right: -19px;
  width: 340px;
  background: $white;
  box-shadow: 0 0 20px rgba(0,0,0,.3);
  font-size: 14px;

  &:after {
    content: '';
    height: 10px;
    left: 0;
    position: absolute;
    top: 100%;
    width: 100%;
    background: transparent;
  }

  &:before {
    content: '';
    bottom: -7px;
    height: 0;
    position: absolute;
    right: 20px;
    width: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-top: 7px solid $white;
  }

  a {
    border-bottom: 1px dotted get-brand(primary);
    color: get-brand(primary);
  }
}
