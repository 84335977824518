// 1.Config

// 2.Base
/// Default form
/// @name form
/// @group forms
/// @author Tim Hartmann
.m-form {
	label {
		font-size: 14px;
		font-weight: bold;
	}
	input[type="text"],
	input[type="email"],
	input[type="password"],
	input[type="url"],
	input[type="tel"],
	textarea {
		@include box-sizing();
		display: block;
		padding: 10px;
		width: 100%;
		-webkit-appearance: none;
		border-radius: $base-border-radius;
		box-shadow: none;
	}

	label + input {
		margin-top: 5px;
	}

	input[type="checkbox"] {
		-webkit-appearance: checkbox;
	}
	input[type="radio"] {
		-webkit-appearance: radio;
	}

	.m-form__trigger {
		font-size: 14px;
		font-weight: normal;
		line-height: 1.35;
	}
}
.m-form__list {
	@include list-reset();
}
.m-form__row {
	margin-top: 15px;

	&:first-child {
		margin-top: 0;
	}
}

.m-form__footer {
	margin-top: 25px;
	text-align: right;

    a {
      border-bottom: 1px dotted $base-link-color;
      color: $base-link-color;

      &:hover {
        border-bottom: none;
      }
    }
}
.m-form__footer__small {
	margin-top: 5px;
	font-size: 13px;

	a {
		color: get-brand(primary);
	}
}


.m-form__half-columns {
	@include list-reset();

	@include respond(xlarge) {
		margin-left: -20px;
	}

	&:first-child {
		margin-top: -15px;

		@include respond(xlarge) {
			margin-top: 0;
		}
	}
}
.m-form__half-columns__unit {
	@include box-sizing();
	margin-top: 15px;

	@include respond(xlarge) {
		display: inline-block;
		margin-right: -4px;
		margin-top: 0;
		padding-left: 20px;
		vertical-align: top;
		width: 50%;
	}
}

// Name: Trigger
.m-form__trigger {
	cursor: pointer;
	display: block;
	margin-top: 10px;
  position: relative;

  &:first-child {
  	margin-top: 0;
  }

  a {
  	border-bottom: 1px dotted get-brand(primary);
  	color: get-brand(primary);

  	&:hover {
  		border-bottom: none;
  	}
  }
}
.m-form__trigger__input {
	left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  width: auto;

  &:checked + .m-form__trigger__pseudo-input {
    &:before {
    	content: "\f00c";
    	position: absolute;
    	top: 50%;
    	left: 50%;
    	@include translate(-50%, -50%);
		  font-family: FontAwesome;
		  font-size: 11px;
		  font-style: normal;
		  font-weight: normal;
		  -webkit-font-smoothing: antialiased;
		  -moz-osx-font-smoothing: grayscale;
    }
  }
}
.m-form__trigger__pseudo-input {
	display: block;
  height: 14px;
  left: 0;
  position: absolute;
  top: 3px;
  width: 14px;
  border-radius: $base-border-radius;
  border: 1px solid $base-border-color;
  background: $form-trigger-bgcolor;
}
.m-form__trigger__matter {
	cursor: pointer;
  display: block;
  padding-left: 20px;
}

// Name: Info
.m-form__info {
	color: $form-info-fontcolor;
	font-size: 13px;
	font-style: italic;
	line-height: 1.3;

	a {
		border-bottom: 1px dotted $base-link-color;
		color: $base-link-color;

		&:hover {
			border-bottom: none;
		}
	}
}

// Name: Message
.m-form__message {
	font-weight: normal;
}

// Name: Error-Message
.m-form__error-message {
	display: none;
	color: $form-error-color;
	font-size: 11px;
	font-style: italic;

    a {
      border-bottom: 1px dotted $base-link-color;
      color: $base-link-color;

      &:hover {
        border-bottom: none;
      }
    }
}

// Name: Password-Strength

.m-form__password-strength {
	margin-top: 5px;
	position: relative;

	&:first-child {
		margin-top: 0;
	}

	&.is-active {
		.m-form__password-strength__tooltip {
			opacity: 1;
			top: -24px;
		}
	}

	&.is-unsafe {
		.m-form__password-strength__visual {
			border-color: rgba($alert-color, .7);
			@include animation(passwordstrength-scaling 1s);
		}
	}
	&.is-good {
		.m-form__password-strength__visual {
			border-color: rgba(get-brand(primary), .7);
			@include animation(passwordstrength-scaling 1s);
		}
	}
	&.is-safe {
		.m-form__password-strength__visual {
			border-color: $success-color;
			@include animation(passwordstrength-scaling 1s);
		}
	}

	input[type="password"] {
		padding-right: 40px;
	}
}
.m-form__password-strength__visual {
	display: block;
	height: 15px;
  pointer-events: none;
	position: absolute;
	right: 11px;
	top: 50%;
	width: 15px;
	border: 3px solid #ccc;
	border-radius: $rounded-border-radius;
	@include translateY(-50%);
	@include transition(border .3s ease-in-out .5s);
}
.m-form__password-strength__tooltip {
	opacity: 0;
	padding: 7px;
	position: absolute;
	pointer-events: none;
	right: 7px;
	top: -15px;
	background: $form-password-tooltip-bgcolor;
	color: $form-password-tooltip-fontcolor;
	font-size: 12px;
	line-height: 1;
	text-align: center;
	@include transition((opacity .3s ease-in 1s, top .4s ease-out 1s));

	&:before {
		bottom: -5px;
		content: '';
		position: absolute;
		right: 10px;
		@include triangle(bottom, 5px, $form-password-tooltip-bgcolor);
	}
}


// Support Form
.support-form__row {
  padding-top: 25px;

  &:first-child {
    padding-top: 0;
  }
  &:after {
    content: '';
    clear: both;
    display: table;
  }
  &.is-error {
    padding: 15px;
    background-color: $alert-bg;

    .support-form__invalid-message {
      display: block;
    }
  }
  .clearfix:after {
    content: '';
    clear: both;
    display: table;
  }

  .support-form__checkbox {
    display: table;

    > * {
      display: table-cell;
      vertical-align: top;
      line-height: 1;
    }
  }
}
.support-form__row--large {
  padding-top: 50px;

  &.is-error {
    margin-top: 0;
  }
}
.support-form__row--action {
  &:after {
    content: '';
    clear: both;
    display: table;
  }
  > * {
    float: right;

    &:first-child {
      float: left;
    }
  }
}

.support-form__row__half {
  float: left;
  padding-right: 50px;
  width: 50%;

  &:last-child {
    padding-right: 0;
  }
}

.support-form__checkbox__matter {
  padding-left: 20px;
}

.support-form__invalid-message {
  display: none;
  margin-bottom: 25px;
  background: $alert-color;
  border-radius: 3px;
  color: get-font(ghost);
  font-size: 14px;
  font-weight: bold;
  line-height: 20px;
  padding: 5px 8px;
  text-align: center;
}