.case-editor-search-result-category-item {
  background: $light-bg;
  margin-top: 20px;
  padding: 10px 0;

  > ul {
    border-radius: $base-border-radius;
    border: 1px solid $light-border-color;
    box-shadow: -3px 0 0 $gray-lighter;
  }
}
.case-editor-search-result-category-result-count {
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 20px;

  em {
    color: $hint-color;
    font-weight: bold;
  }
  strong {
    background-color: $hint-bg;
    color: $hint-color;
    font-weight: bold;
    margin-left: 4px;
    display: inline-block;
    padding: 3px 4px;
    border: 1px solid $hint-border-color;
    border-radius: $base-border-radius;
  }
}
.case-editor-search-result-item {
  background: $light-bg;
  border-top: 1px solid $success-border-color;
  margin-bottom: 10px;
  padding: 20px;
  // NOTE: don't use :last-child -- IE8 does not support it.
  &:last-child {
    margin-bottom: 0;
  }
  &:first-child {
    border-top: none;
  }
}
.case-editor-search-result-item-title {
  font-size: 17px;
  font-weight: bold;
  margin-bottom: 5px;
}
.case-editor-search-result-item-text {
  // This text may contain structured markup.
  // So far, we have only seen <p> <br> <strong> <ul> <li>
  // as well as span.searchHighlight (see below)
  p {
    margin-bottom: 5px;
  }
  strong {
    font-weight: bold;
  }
  ul {
    list-style: square;
    padding-left: 15px;
  }
  li {
    margin-bottom: 5px;
  }
}
.case-editor-search-result-item-action {
  text-align: left;

  .btn {
    display: inline-block;
    margin-top: 10px;
    padding: 7px 10px;
    background: get-brand(primary);
    font-size: 14px;

    &:hover {
      background: darken(get-brand(primary), 10%);
    }
  }
}
// For search results in MF items, we can have more than one hit
// for each query. This needs a slightly different markup and styling:
.case-editor-search-result-item-action-mf {
  margin-top: 10px;

  h3 {
    font-weight: bold;
    margin-bottom: 5px;
    text-align: center;
  }
  ul {
    display: inline-block;
  }
  li {
    float: left;
    margin-left: 5px;

    &:first-child {
      margin-left: 0;
    }
  }
  .btn {
    display: block;
    margin-top: 0;
  }
}

// This class is inserted directly into the database search result;
// probably in some Java code. It is not found in any template or JS code.
.searchHighlight {
  background: $success-bg;
  color: $success-color;
  font-weight: bold;
  padding: 1px 4px;
  display: inline-block;
  border: 1px solid $success-border-color;
  border-radius: $base-border-radius;

}
