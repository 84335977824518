// #case-editor-sidebar-navigator-tree {
#ui-nav-tree {
  padding: 0;
  font-size: 13px;
  line-height: 18px;
  // Fix for ONSE-6767 (non-breaking long text renders beyond bounds of sidebar)
  overflow: auto;
  // Tried to make it so that the hidden text will show on hover,
  // but it will appear underneath interview main content (right column)
  // and I can't manage to fix this (the z-index stuff doesn't work)
  //  &:hover {
  //    overflow: visible;
  //    .ygtvcontent {
  //      background-color: $white;
  //      &:hover {
  //        position: absolute;
  //        z-index: 1000;
  //      }
  //    }
  //  }
}

.nav-unfilled,
.nav-semifilled,
.nav-filled,
.nav-summary-filled,
.nav-overview,
.nav-overview-semifilled,
.nav-overview-filled,
.nav-error {
  background-image: url($navigator_tree_sprite);
  background-size: 552px 851px;
  background-repeat: no-repeat;
  padding-left: 20px;
}

// *We used to have a different symbol for summary items, but now just re-use the standard "filled" symbol
.nav-unfilled {
  background-position: -500px   -1px
}  // blank

.nav-semifilled {
  background-position: -500px -101px
}  // partially-completed

.nav-filled, .nav-summary-filled {
  background-position: -500px -201px
}  // completed, summary completed*

.nav-overview {
  background-position: -500px -301px
}  // overview-blank

.nav-overview-semifilled {
  background-position: -500px -401px
}  // overview-partially-completed

.nav-overview-filled {
  background-position: -500px -501px
}  // overview-completed

// We re-introduce special symbols for error states.
// The error state class is set in addition to the fill state.
// We could combine the basic states (above) with error/warning/recommendation states,
// but that would make things quite complicated, even though only some of the combinations make sense
// (eg. it's not possible to have a blank (unfilled) node with an error, or is it?).

// Currently, we only mark the actual node that has the issue,
// not the parent (sub)tree that contains the affected node:
.nav-error {
  background-position: -500px -601px;
  color: $alert-color;
}  // completed, but with error
.nav-warning {
  background-position: -500px -701px
}  // completed, but with warning
.nav-recommendation {
  background-position: -500px -801px
}  // completed, but with recommendation

// We also set the text to the corresponding color.
// (I tried keeping the base color, but that looks weird.)
