
.is-mobile {
  /* ------------------- HILFE WINDOW ------------------- */
    .app-dialog-support-anonymus__body{
      ul.app-dialog-support-anonymous__checklist {
      margin-left: 0!important;
      padding-left: 10px!important;
    }
  }

  .app-dialog-support-anonymous{
    .app-dialog-support-anonymus__body{
      padding: 30px 260px 30px 30px
    }
  }

   /* ------------------- NAVBAR BUTTON NAV ------------------- */
    #mobile-toggle-navigator-icon {
        #ui-mobile-toggle-navigator {
            padding: 2px 5px 0px 5px !important;
        }
    }
    
  /* ------------------- CASE MANAGER ------------------- */
  #case-status-display {
    min-width: 268px;
  }

  .caselist-item {
      float: none;
      display: block;
      width: 300px;
  }

  #cases-manager {
    display: flex;
    flex-direction: column;
  }

  #cases-manager-controls, #cases-manager-header h1{
    width: 100%;
    text-align: center;
  }

  #cases-manager-add-case-panel h1, #cases-manager-header h1 {
    text-align: center;
  }

  #cases-manager-add-case-panel .btn {
    padding: 12px 21px;
  }

  #cases-manager-add-case-panel .btn-hilited {
    font-size: 0;
    &:before {
      content: "Anlegen";
      font-size: 16px;
      height: 16px;
      display: inline-block;
      vertical-align: middle;
    }
  }

  .cases-manager-import-case-btn {
    font-size: 0;
    &:before {
      content: "Importieren";
      font-size: 16px;
      height: 16px;
      display: inline-block;
      vertical-align: middle;
    }
  }

  #cases-manager-header {
    padding: 15px 20px 15px;
  }

  #cases-manager-add-case-panel {
    h1,
    .btn-group {
      float: none;
    }
    h1 {
      margin-right: 0px;
    }
  }

  #cases-manager-body {
    padding: 0px;
    order: 1
  }

  #cases-manager-caselist,
  #cases-manager-case-legacy-list {
    margin-left: 0px;
  }

  #cases-manager-caselist {
    margin-left: 0px;
  }

  #cases-manager-sidebar {
    position:inherit;
    width: auto;
    margin:0;
    order:3
  }

  #cases-manager-add-case-panel {
      .btn {
        display: grid;
      }
    }

  /* ------------------- HEADER AND FOOTER ------------------- */

  #app-header {
    padding: 7px;
    position: sticky;
    position: -webkit-sticky;
    top: 0px;
    z-index: 998; /* just below wait mask and pwa home screen button */
  }
  .app-header-logo {
    height: 39px;
    width: 97px;
    margin-right: 8px;
  }
  .system-status-label {
    display: none;
  }
  .smst-language-switcher {
    display: none;
  }
  .l-banner__wrap .l-banner__extension {
    padding-left: 0px;
  }
  .app-footer {
    font-size: 12px;
  }
  .l-backoffice {
    #app-header-content {
      padding-bottom: 10px;
    }
  }
  .l-banner__offset__hint {
    margin-top: 0px;
  }
  .c-navigation__list {
    margin: 0px;
  }
  .m-form__half-columns__unit {
    width: auto;
  }
  .mobile-hidden {
    display: none;
  }
  footer {
    padding-top: 10px;
  }

  /* ------------------- NAVIGATION ------------------- */

  #ui-nav-tree {
    font-size: 15px;
    line-height: 20px;
    overflow-x: hidden;

    a {
      word-break: break-all;
    }
  }
  .nav-close-button {
    float: right;
    font-size: 18px;
  }
  #ui-mobile-toggle-navigator {
    background: $white;
    color: get-brand(primary);
    padding: 8px;
    box-shadow: inset 0 0 0 2px darken(get-brand(primary), 10%);

    .fa, .fa:first-child {
      margin-right: 0;
    }
  }
  .is-inactive-sidebar {
    #ui-mobile-toggle-navigator {
      box-shadow: none;
      background: rgba($white, .6);
      color: get-brand(primary);
    }
    #app-header #ui-app-header-inline-reg-default #ui-mobile-toggle-navigator {
      opacity: 1;
      width: auto;
      background: darken(get-brand(primary), 10%);
      color: $white;
    }
  }

  /* ------------------- INTERVIEW ------------------- */

  #case-editor-main-header {
    min-height: 0px !important;
  }
  .case-editor-section-header__image {
    display: none !important;
  }
  #case-editor-section-title {
    padding-left: 10px !important;
    padding-right: 10px;
  }
  .case-editor-section-header-description__wrapper {
    padding-left: 10px !important;
  }
  #case-editor-main-body {
    padding: 10px;
  }
  .case-editor-section-help {
    font-size: 13px !important;
  }
  #case-editor-section-title {
    padding-left: 15px;
    padding-top: 10px;
    font-size: 18px;
  }
  .case-editor-section-header-description__wrapper {
    padding-left: 15px;
    padding-right: 10px;
  }
  .case-interview-field-unit {
    padding: 9px 3px !important;
    font-size: 8pt !important;
  }
  #case-editor-header {
    padding: 7px 0 5px;
  }
  #case-editor-main-body {
    min-height: 600px;
  }
  #case-editor-main-controls {
    display: none;
  }
  #case-editor-title-container {
    width: 47%;
  }
  #case-title-name {
    font-size: 16px;
    white-space: nowrap;
    overflow: hidden;
  }
  #case-result-value {
    font-size: 16px;
  }
  #case-editor-controls .btn {
    padding: 4px;
    font-size: 12px;
  }
  .mobile-help {
    clear: both;
    padding-top: 8px;
    font-size: 12px;
    margin-right: 8px;
  }
  .case-interview-field-checkbox-only {
    .mobile-help {
      display: none;
    }
    input {
      min-width: 10px !important;
    }
    min-width: 260px;
  }
  #case-editor-section-help {
    a {
      border: none;
      color: #404040;
      font-weight: normal;
    }
    .ui-help-link-youtube {
      color: $blue;
    }
    .ui-help-link-document {
      color: $blue;
    }
    .ui-help-link {
      color: $blue;
    }
  }
  .app-alert i.fa {
    font-size: 25px !important;
  }
  .case-editor-interview-multif-selector {
    li {
      width: auto;
    }
  }
  .case-editor-interview-help-debug {
    display: none;
  }
  .case-editor-interview-field-help-more-container {
    display: none; /* testing removing the more help container entirely */
    margin-top: 7px;

    button {
      padding: 6px 24px;
      background: $blue-lighter;
      color: $blue;
    }
  }
  .case-editor-interview-help-lexicon-link {
    border: none !important;
    color: #404040 !important;

    &:after {
      content: "" !important;
    }
  }
  .app-status-msg > div {
    width: auto;
  }
  .app-status-msg {
    position: relative;
  }
  .app-controls-system-state {
    margin-top: 0px;
    margin-left: 10px;
  }
  .ui-app-help-open {
    padding: 3px 8px;
    font-size: 10px;
  }
  .case-editor-interview-help-debug {
    width: 98%;
  }
  .case-interview-field-readonly input[type="text"],
  .case-interview-field-readonly textarea,
  .case-interview-field-readonly .case-interview-field-unit {
    color: #1d2123;
  }
  .modal--alternative {
    .yui3-overlay-xbutton {
      position: relative !important;
      top: 20px !important;
      left: -20px !important;
    }
  }
  .yui3-aclist-content {
    max-width: 250px;
    font-size: 18px;
  }

  input[type="color"],
  input[type="date"],
  input[type="datetime"],
  input[type="datetime-local"],
  input[type="email"],
  input[type="month"],
  input[type="number"],
  input[type="password"],
  input[type="autocomplete"],
  input[type="search"],
  input[type="tel"],
  input[type="text"],
  input[type="time"],
  input[type="url"],
  input[type="week"],
  textarea {
    font-size: 20px !important;
  }

  select,
  select:focus {
    font-size: 16px !important;
  }

  #case-editor-continue-container .btn {
    padding: 12px 12px;
  }
  .btn {
    padding: 12px 12px;
  }
  .btn-mobile-inv {
    padding: 3px;
    border: 1px solid;
    background: lightgrey;
  }
  .case-interview-field-body {
    input {
      min-width: 150px !important;
    }
  }
  .case-interview-field-label {
    word-break: break-all;
  }
  .case-editor-interview-field-exclusive-marker-1st label {
    margin-right: 24px;
  }
  .case-editor-interview-field-exclusive-marker label {
    margin-right: 24px;
  }
  .case-editor-interview-field-exclusive-marker-last label {
    margin-right: 24px;
  }
  .case-editor-interview-field-group-caption {
    font-size: 17px;
    word-break: break-all;
  }
  .m-banner__title {
    clear: both;
  }
  .case-editor-interview-field-exclusive-marker-1st {
    background-image: url($exclusive_marker_first_short);
    background-size: 54px 1254px;
  }
  .case-editor-interview-field-exclusive-marker {
    background-image: url($exclusive_marker_short);
    background-size: 54px 100px;
  }
  .case-editor-interview-field-exclusive-marker-last {
    background-image: url($exclusive_marker_last_short);
    background-size: 54px 100px;
  }

  /* ------------------- SMARTCHECK TO FILING ------------------- */
  #case-editor-summary {
    h1 {
      font-size: 18px;
    }
    .case-editor-smartcheck-issue-list-header {
      i {
        display: none;
      }
      .btn-help-circle {
        display: none;
      }
    }
    .case-editor-smartcheck-banner {
      i {
        display: none;
      }
      div {
        width: 100%;
      }

      padding: 10px;
    }
    .case-editor-smartcheck-issue-list {
      margin: 0px;
    }
    .case-editor-smartcheck-issue-list-body {
      margin: 0px;
      padding: 0px;
      list-style: none;

      li {
        margin: 0px;
        margin-left: 8px;
        padding: 5px;
      }
      .btn {
        border-radius: 3px 3px 3px 3px !important;
        margin-left: 4px;
      }
    }
    .case-editor-smartcheck-issue-actions {
      display: block;
      width: 100%;
      margin-top: 10px;
    }
    .case-editor-smartcheck-issue-details {
      width: 100%;
    }
    .m-banner {
      margin-left: 0px;
    }
    .m-banner__keyvisual {
      display: none;
    }
    .m-banner__matter {
      width: 100%;
    }
  }


  /* ------------------- FILING ------------------- */
  .ui-reduced-printouts-pdf-download {
    font-size: 14px;
  }

  /* ------------------- SPREADSHEETS ------------------- */
  .yui3-spreadsheet-content {
    th {
      display: inline-block;
    }
    th, td {
      display: inline-block;
      border: none;
      padding-left:8px;
      padding-top: 0px;
      background: none;

      input {
        min-width: 400px !important;
        min-height: 35px;
        font-size: 20px !important;
      }

      select {
        min-width: 300px !important;
      }
    }
    tr {
      border-bottom: 15px solid #fff;
    }
    col {
      display: none;
    }
    thead {
      tr {
        border-bottom: 0px;
      }
    }
    .yui3-aclist-content {
      max-width: 430px;
    }
    .case-interview-spreadsheet-actions {
      width: 100%;

      div {
        width: auto;
      }

      button {
        width: 238px;
        height: 40px;
      }
    }

    .btn-mobile-inv {
      padding: 8px;
      border: 1px solid;
      background: lightgrey;
      margin-left: 10px;
    }

    .btn-s i {
      font-size: 18px;
    }

  }

  .yui3-spreadsheetoverlay {

    overflow: auto;

    .yui3-widget-hd {
      padding: 10px;
    }

    .yui3-widget-bd {
      height: unset;
    }

    input {
      max-width: 450px;
      border: unset;
    }

    .yui3-form-field {
      max-width: 480px;
    }

    .yui3-spreadsheet-caption {
      display: none;
    }
  }

  .case-interview-spreadsheet-help-content {
    width: 100%;
    left: 0px !important;
  }

  // See ONSE-12123 - Table view on mobile
  .yui3-spreadsheetFooter-results {
    td {
      background-color: transparent !important;
    }
    tr {
      background-color: #eaf6ea !important;
    }
    .field-format-CUR{
      margin-left: -12px;
    }
  }

  .yui3-spreadsheet-data {
    tr {
      background: #fff0e0;
    }
    td {
      /* border-left: 1px solid #fff; */
      background: #fff0e0;
      padding: 5px;
    }
    .case-interview-spreadsheet-actions {
      text-align: right;
      padding-top: 0px;

      button {
        width: auto !important;
      }
    }
  }

  .case-interview-spreadsheet-spacer {
    border-bottom: 2px dotted #c0c0c0 !important;
    width: 100%;
  }
}




