.case-taxmeter-result {
  width: 100%;
}

.case-taxmeter-spacer {
  clear: both;
  width: 100%;
  height: 30px;
  border-top: 1px dotted #c0c0c0;
}

.case-taxmeter-info-box {
  clear: both;
  position: relative;
  top: 10px;
  margin-bottom: 30px !important;
}

.case-taxmeter-label {
  float: left;
  vertical-align: middle;
  color: get-font(color);
  font-size: 24px;
  font-weight: normal;
}

.case-taxmeter-value {
  float: right;
  padding: 3px 6px;
  vertical-align: middle;
  border-radius: $base-border-radius;
  background: $light-bg;
  color: $failed-color;
  font-size: 24px;
  font-weight: normal;
  line-height: 1;
  white-space: nowrap;

  .case-taxmeter-result-positive & {
    background: $success-color;
    color: get-font(ghost);
  }
}

