// Original YUI3 provided styles
// With 3 modifications (see notes below)
// Styles for vertical slider removed because we don't use them


// Base styling for horizontal and vertical sliders
.yui3-slider,
.yui3-slider-rail {
  display: inline-block;
  vertical-align: middle;
  zoom: 1;
}
.yui3-slider-content {
  display: block;
  position: relative;
}
.yui3-slider-rail {
  position: relative;
}
.yui3-slider-rail-cap-top,
.yui3-slider-rail-cap-left,
.yui3-slider-rail-cap-bottom,
.yui3-slider-rail-cap-right,
.yui3-slider-thumb,
.yui3-slider-thumb-image,
.yui3-slider-thumb-shadow {
  position: absolute;
}
.yui3-slider-thumb {
  overflow: hidden;
}

// Horizontal Slider
.yui3-slider-x {
  .yui3-slider-rail,
  .yui3-slider-rail-cap-left,
  .yui3-slider-rail-cap-right {
    background-image: url('data:image/gif;base64,R0lGODlhBQAzAJECALe3t////////wAAACH5BAEAAAIALAAAAAAFADMAAAIalI+pywj/gpTwtYuz3txUMAVC1ZXmyZHhJxQAOw=='); // case-editor-assessment-slider-rail; originally: ../img/slider/rail-x.png
    background-repeat: repeat-x;
  }
  .yui3-slider-rail {
    height: 26px;
  }
  .yui3-slider-thumb {
    height: 26px;
    width: 13px;  // originally: 15px
  }
  .yui3-slider-rail-cap-left {
    background-position: 0 -20px;
    height: 20px;
    left: -2px;
    width: 5px;
  }
  .yui3-slider-rail-cap-right {
    background-position: 0 -40px;
    height: 20px;
    right: -2px;
    width: 5px;
  }
  .yui3-slider-thumb-image {
    left: 0;
    top: -10px;
  }
  .yui3-slider-thumb-shadow {
    left: 0;
    opacity: .15;
    top: -50px;
  }
}

#yui3-css-stamp.skin-sam-slider-base {
  display: none;
}
