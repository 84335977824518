////
/// @author Tim Hartmann
/// @group animations
////

/// @name animation
/// @param {string} $params
@mixin animation($params) {
  -webkit-animation: #{$params};
          animation: #{$params};
}

/// @name animation-name
/// @param {string} $name
@mixin animation-name($name) {
  -webkit-animation-name: $name;
          animation-name: $name;
}

/// @name animation-delay
/// @param {number} $delay
@mixin animation-delay($delay) {
  -webkit-animation-delay: $delay;
          animation-delay: $delay;
}

/// @name animation-direction
/// @param {string} $direction
@mixin animation-direction($direction) {
  -webkit-animation-direction: $direction;
          animation-direction: $direction;
}

/// @name animation-duration
/// @param {number} $duration
@mixin animation-duration($duration) {
  -webkit-animation-duration: $duration;
          animation-duration: $duration;
}

/// @name animation-iteration-count
/// @param {number} $iteration-count
@mixin animation-iteration-count($iteration-count) {
  -webkit-animation-iteration-count: $iteration-count;
          animation-iteration-count: $iteration-count;
}

/// @name animation-play-state
/// @param {string} $play-state
@mixin animation-play-state($play-state) {
  -webkit-animation-play-state: $play-state;
          animation-play-state: $play-state;
}

/// @name animation-timing-function
/// @param {string} $type
@mixin animation-timing-function($type) {
  -webkit-animation-timing-function: $type;
          animation-timing-function: $type;
}

/// @name animation-fill-mode
/// @param {string} $type
@mixin animation-fill-mode($type) {
  -webkit-animation-fill-mode: $type;
          animation-fill-mode: $type;
}

/// @name keyframes
/// @param {string} $name
@mixin keyframes($name) {
  @-webkit-keyframes $name {
    @content;
  }
  @keyframes $name {
    @content;
  }
}