//center and stretch youtube videos

.video-container-wrapper {
  max-width: 100%;
  width: 500px;
  left: 0;
  right:0;
  margin:auto;
}

.video-container {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 0;
  height: 0;
  overflow: hidden;
}

.video-container iframe,
.video-container object,
.video-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
