////
/// @group animations
/// @author Tim Hartmann
////

/// @name spin
@include keyframes(spin) {
  0% {
    @include rotate(0deg);
  }
  100% {
  	@include rotate(359deg);
  }
}

@include keyframes(passwordstrength-scaling) {
	0% {
		@include transform(scale(1) translateY(-50%));
	}
	50% {
		@include transform(scale(1.2) translateY(-50%));
	}
}

@include keyframes(bounceIn) {
  from, 20%, 40%, 60%, 80%, to {
    animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
  }

  0% {
    opacity: 0;
    transform: scale3d(.3, .3, .3);
  }

  20% {
    transform: scale3d(1.1, 1.1, 1.1);
  }

  40% {
    transform: scale3d(.9, .9, .9);
  }

  60% {
    opacity: 1;
    transform: scale3d(1.03, 1.03, 1.03);
  }

  80% {
    transform: scale3d(.97, .97, .97);
  }

  to {
    opacity: 1;
    transform: scale3d(1, 1, 1);
  }
}