////
/// @group Links
/// @author Tim Hartmann
///

// 1.Config

// 2.Base
/// @name Link
.m-link {
	color: $base-link-color;
}