.feedback {
  position: relative;
  font-size: 1.3em;
  line-height: 1.35;
  text-align: center;

  &:before {
    content: '';
    display: block;
    left: 50%;
    opacity: .1;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    border-radius: 100%;
    background: #bfbfbf;
    width: 300px;
    height: 300px;
  }

  strong {
    font-weight: bold;
  }
}

.feedback-status {
  left: 20px;
  padding: 10px;
  position: absolute;
  top: 20px;
  border-radius: 150px;
  background-color: $red;
  color: $white;
  font-size: 45px;
  line-height: .85;
}
.feedback-status-number {
  font-size: 24px;
  line-height: .65;
}
.feedback-wrapper {
  position: relative;
  z-index: 10;
}
.feedback-icon {

}
.feedback-title {
  margin-top: 20px;
  font-size: 2rem;
  line-height: 1.45;
}

.feedback-success {
  &:before {
    background-color: $success-color;
  }

  .feedback-icon {
    color: $success-color;
  }
}

.feedback-card {
  padding: 40px;
  background: #fdf3f1;
  border: 1px solid $red;
  font-size: 1.2rem;

  &:before {
    display: none;
  }
  .feedback-icon {
    padding: 32px;
    border-radius: 150px;
    background: $red;
    color: $white;
    font-size: 50px;
    line-height: .9;
  }
  .feedback-title {
    font-size: 1.6rem;
  }
}

.feedback-card-success {
  background: transparent;
  border: 1px solid $gray-lighter;
  border-right: none;

  .feedback-status {
    padding: 0;
    background: transparent;
    color: $success-color;
  }

  .feedback-icon {
    background-color: $success-color;
  }
}

.feedback-follow {
  padding: 10px 13px;
  position: absolute;
  right: -25px;
  top: 50%;
  z-index: 11;
  border-radius: 150px;
  background: $white;
  box-shadow: 0 1px 3px rgba($black, .2);
  font-size: 23px;
  line-height: .8;
  text-align: center;
  transform: translateY(-50%);
}

.feedback-full {
  padding: 40px 0;
}