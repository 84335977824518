// Do not remove this. When spreadsheet overlay is dismissed, it becomes invisible,
// but is still there, blocking all interaction with UI elements below it.
.yui3-spreadsheetoverlay-hidden {
  visibility: hidden;
  top: -10000px !important;
  left: -10000px !important;
}
.yui3-spreadsheetoverlay {
  .yui3-widget-bd {
    height: 530px;
  }
}

// NOTE: This styling is used by both the spreadsheet overlay
// and the reports preview for tables (appendices) on the Analysis page.
// The print version for these tables is generated bei appendix.jsp,
// using a completely separate stylesheet.
// See also: ONSE-6993.
.yui3-spreadsheet-content {
  table {
    width: 100%;
  }
  .yui3-spreadsheet-caption {
    color: get-font(color);
    font-size: 20px;
    font-weight: bold;
    line-height: 28px;
    margin-bottom: 20px;
  }
  tr {
    vertical-align: middle;
  }
  th, td {
    overflow: hidden;
    vertical-align: top;
  }
  th {
    color: get-brand(primary);
    font-weight: bold;
    padding: 5px 10px;
    background-color: $light-bg;
  }
  td {
    background: $main-bg;
    border: 1px solid $white;
    padding: 5px 10px;
    padding-left: 3px !important;

    // these should be right-aligned
    // (but only the values, not the column labels)
    &.field-format-CUR, // currency
    &.field-format-N, // number
    &.field-format-D {  // date
      text-align: right;
    }
    // The N-columns get rendered too wide by default, we have to fix this here.
    &.field-format-N {
      width: auto !important;
    }
  }
  input {
    box-sizing: content-box;
    border: 1px solid $base-border-color;
    color: get-font(color);
    box-shadow: inset 0 0 3px #ddd;  // NOTE: non-standard color
    font-family: monospace;
    padding: 5px;

    &:hover,
    &:focus {
      background-color: $light-bg;
      border-color: #999;   // NOTE: non-standard color
      color: get-font(color);
    }
  }
  // FF needs a width limit for selects,
  // otherwise they will render as wide as the widest option/optgroup
  select {
    max-width: 200px;
  }
  // FF's default style for optgroups looks like shit,
  // so we style it explicitly
  optgroup {
    color: $gray;
    font-style: normal;
  }
  // But an <option> inside an <optgroup> inherits the gray text color,
  // which makes it look like it is disabled, so we override this here:
  option {
    color: get-font(color);
  }
  .yui3-spreadsheetFooter-results td {
    background: $white;
    color: $success-color;
    font-size: 16px;
    font-weight: bold;
    overflow: hidden;

    &.yui3-spreadsheetFooter-result {
      background: $success-bg
    }
  }
  .yui3-spreadsheet-editor {
    .error {
      background: $alert-bg;
      border-radius: 3px;
      box-shadow: 0 0 10px $gray;  // NOTE: non-standard color
      color: $alert-color;
      display: block;
      font-size: 12px;
      font-weight: bold;
      line-height: 16px;
      margin-top: 35px;
      padding: 5px 7px;
      position: absolute;
      max-width: 100px;

      &:before {
        border-top: 12px solid $alert-bg;
        border-left: 12px solid transparent;
        border-right: 12px solid transparent;
        content: ' ';
        display: block;
        margin-top: -12px;
        position: absolute;
      }
    }
    // Need this so error overlays on normal inputs and selects line up vertically.
    .yui3-select-field .error {
      margin-top: 32px;
    }
  }
}

.case-interview-spreadsheet-actions {
  div {
    width: 90px;
  }
  // TODO: replace with .btn-group instead
  button {
    display: inline-block;
    margin-left: 5px;

    &:first-child {
      margin-left: 0;
    }
  }
}

// Spreadsheet help
.case-interview-spreadsheet-help-container {

  // The button is moved out of the container so it appears to be on the same line
  // as the spreadsheet headline which should be the first thing in the overlay
  .case-interviewspreadsheet-help-toggle-container {
    float: right;
  }

  button.case-interviewspreadsheet-help-toggle {
    position: relative;
    top: -40px;
  }
}
// This contains the actual help balloon
.case-interview-spreadsheet-help-content {
  background: $info-bg;
  border-radius: 3px;
  display: inline-block;
  font-size: 12px;
  line-height: 18px;
  max-width: 500px;
  padding: 10px;
  height: 76px;
}
// Initial state for the help balloon, should be full width
.case-interview-spreadsheet-help-initial {
  display: block;
}
// The arrow pointing down towards the column header
.case-interview-spreadsheet-help-pointer {
  border-top: 12px solid $info-border-color;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  width: 0;
  height: 0;
  display: block;
}

.case-interview-spreadsheet-row-is-being-modified {
  color: get-brand(primary);

  th, td {
    background-color: $main-bg;
  }
}

// we don't want to mess with tabbing in IEs.
.yui3-spreadsheet-editor-clone {
  visibility: hidden;
}

// taken form the original yui3-datatable-scroll.css file and converted to match our needs

.yui3-spreadsheet-scrollable-x {
  _overflow-x: hidden;
  _position: relative;
}

.yui3-spreadsheet-scrollable-y,
.yui3-spreadsheet-scrollable-y .yui3-spreadsheet-x-scroller {
  _overflow-y: hidden;
  _position: relative;
}

.yui3-spreadsheet-y-scroller-container {
  overflow-x: hidden;
  position: relative;
}

.yui3-spreadsheet-scrollable-y .yui3-spreadsheet-content {
  position: relative;
}

.yui3-spreadsheet-scrollable-y .yui3-spreadsheet-table .yui3-spreadsheet-columns {
  visibility: hidden;
}

.yui3-spreadsheet-scroll-columns {
  position: absolute;
  width: 100%;
  z-index: 2;
}

.yui3-spreadsheet-y-scroller,
.yui3-spreadsheet-scrollable-x .yui3-spreadsheet-caption-table {
  width: 100%;
}

.yui3-spreadsheet-x-scroller {
  position: relative;
  overflow-x: scroll;
  overflow-y: hidden;
}

.yui3-spreadsheet-scrollable-y .yui3-spreadsheet-y-scroller {
  position: relative;
  overflow-x: hidden;
  overflow-y: scroll;
  z-index: 1;
  -webkit-overflow-scrolling: touch;
}

.yui3-spreadsheet-scrollbar {
  position: absolute;
  overflow-x: hidden;
  overflow-y: scroll;
  z-index: 2;
}

.yui3-spreadsheet-scrollbar div {
  position: absolute;
  width: 1px;
  visibility: hidden;
}

.yui3-skin-sam {
  .yui3-spreadsheet-scroll-columns,
  .yui3-spreadsheet-columns {
    // This previously contained border-collapse: separate and font-family: arial, sans-serif
    // causing 2 of the ugliness problems in ONSE-8582
    border-spacing: 0;
    margin: 0;
    padding: 0;
    top: 0;
    left: 0;
  }
  .yui3-spreadsheet-scroll-columns .yui3-spreadsheet-header {
    padding: 0;
  }
  .yui3-spreadsheet-scrollable-x .yui3-spreadsheet-y-scroller-container,
  .yui3-spreadsheet-x-scroller .yui3-spreadsheet-table,
  .yui3-spreadsheet-y-scroller .yui3-spreadsheet-table {
    border: 0;
  }
}

#yui3-css-stamp.skin-sam-datatable-scroll {
  display: none
}

// For data cells with configured width this will prevent the content from stretching the cell.
.case-interview-spreadsheet-data-cell {
  overflow: auto;
  width: auto !important;
}
