$caselist-item-bg: $white !default;
$case-header-bg: $white !default;

.caselist-item {
  float: left;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  margin: 0 0 20px 2%;
  padding: 30px 10px 10px;
  position: relative;
  width: 48%;
  z-index: inherit;
  border: none;
  border-radius: 3px;
  background: $caselist-item-bg;
  box-shadow: 0 3px 3px rgba($black, .1);
  @include transition(all .3s);

  @media screen and (min-width: 1100px) {
    width: 31.3%;
  }
  @media screen and (min-width: 1350px) {
    width: 23%;
  }
  &:hover,
  &.case-highlighted {
    box-shadow: 0 0 30px rgba($black, .3);
  }

  #case-result-value {
    font-size: 21px;
  }
  #case-result-sign {
    margin-right: 5px;
    margin-top: 0;
    font-size: 14px;
  }

  #case-result.case-result-negative #case-result-value {
    background: $case-result-negative-bg;
    color: $case-result-negative-font-color;
  }
}
.case-highlighted {
  box-shadow: 0 0 15px #999; // NOTE: non-standard color
}
.case-action-indicator {
  background: $light-bg url($action_indicator) 12px 12px no-repeat;
  height: 100%;
  left: 0;
  top: 0;
  opacity: .75;
  position: absolute;
  width: 100%;
  z-index: 1;
}
.case-header {
  background: $case-header-bg;
}
.case-title {
  line-height: 20px;
  margin-bottom: 15px;
}
.case-title-year {
  color: get-brand(primary);
  font-weight: bold;
  margin-right: 5px;
  font-size: 15px;
  text-align: center;
}
.case-title-last-modified-label {
  color: get-font(color);
  font-size: 9pt;
  float: right;
  position: relative;
  top: -13px;
}
.case-title-year-label {
  display: none;
}
.case-title-name {
  display: block;
  font-size: 17px;
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  clear: both;
}
.case-title-name-label {
  display: none;
}

.case-status {
  height: 80px;
}
.case-status-unedited,
.case-status-locked {
  height: 50px;
  text-align: center;
}
.case-status-unedited {
  font-weight: bold;
  line-height: 50px;
}
.case-status-locked {
  i.fa {
    font-size: 50px;
    line-height: 50px;
  }
  span {
    display: none;
  }
}
.case-details {
  margin-left: 1px;
  margin-top: 10px;
  overflow: hidden;

  li {
    border-radius: 3px;
    border: 1px solid $info-border-color;
    background: $info-bg;
    color: get-font(color);
    float: left;
    font-size: 14px;
    font-weight: bold;
    line-height: 1;
    margin-right: 5px;
    padding: 6px;
    text-align: center;
    text-transform: uppercase;
  }
}
