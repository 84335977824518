#body {
  @include transition(all .3s ease-in);
}

#app-container {
  background: $app-body-bg;
  min-width: 1024px;
  margin: 0 auto;
}

#app-body {
  max-width: $max-wrapper-width;
  margin-left: auto;
  margin-right: auto;
  padding-left: 10px;
  padding-right: 10px;
  border: none;
}


// Shared styling

// This will contain some styling features that are shared across the application.
// There should be a new SCSS module for this (app-common) later on.

// The namespace for these should be app-* or app-common-*

// Layout helpers
.app-pull-left {
  float: left !important;
}
.app-pull-right {
  float: right !important;
}

// Small text (for additional info that is less important)
.app-small-text {
  font-size: 13px;
  line-height: 18px;
}

// A bulleted list
.app-list {
  margin-left: 15px;

  li {
    margin-bottom: 5px;
  }
}
ol.app-list {
  list-style: decimal;
}
ul.app-list {
  list-style: square;
}