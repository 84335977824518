////
/// @author Tim Hartmann
/// @group transitions
////

/// @name transition
/// @param {string} $value
@mixin transition($value) {
  -webkit-transition: $value;
          transition: $value;
}

/// @name transition-property
/// @param {string} $property
@mixin transition-property($property) {
  -webkit-transition-property: $property;
          transition-property: $property;
}

/// @name transition-delay
/// @param {number} $value
@mixin transition-delay($value) {
  -webkit-transition-delay: $value;
          transition-delay: $value;
}

/// @name transition-duration
/// @param {number} $value
@mixin transition-duration($value) {
  -webkit-transition-duration: $value;
          transition-duration: $value;
}

/// @name transition-timing-function
/// @param {string} $type
@mixin transition-timing-function($type) {
  -webkit-transition-timing-function: $type;
          transition-timing-function: $type;
}