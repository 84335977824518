// Global styles
// These are defaults that apply to the entire application.
* {
  @include box-sizing(border-box);
  @include font-reset();
}

html {
  background: $white;
  // Keep (mobile) browsers from auto-adjusting text sizes
  font-size: 16px;
  -webkit-text-size-adjust: none;
  -ms-text-size-adjust: none;
}
body {
  color: get-font(color);
  font-family: get-font(baseFamily);
  font-size: get-font(size);
  line-height: 1.35;
  font-feature-settings: "liga", "kern";
  text-rendering: optimizeLegibility;

  .is-active-layer {
  }
}

a {
  color: $base-link-color;
  text-decoration: none;
}

// Remove focus outline -- by default, Firefox shows these (Webkit doesn't);
// and they are not removed by the CSS reset.
// NOTE! This may break the UI in places where we were relying on this default.
// For focus styles on inputs, see below
:focus {
  outline: none;
}

// FF has a light grey background color on inputs by default,
// which we don't want and is not handled by the CSS reset.
// Fixes ONSE-7155.
input {
  background: $form-input-bg;
}

input[type="radio"] {
  margin: 0 1px;
}

select {
  font-size: 14px;
}

input,
textarea {
  font-family: get-font(baseFamily);
}

button {
  @include box-sizing(border-box);
  overflow: visible;
  border: none;
  line-height: normal;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

// Checkboxes, radios and file inputs should have a transparent background
input[type="checkbox"],
input[type="radio"],
input[type="file"] {
  background: transparent;
}

// ONSE-9562: remove "clear input" X widget in IE10+
// See http://stackoverflow.com/questions/14007655/
::-ms-clear {
  display: none;
}

iframe {
  border: 0;
}

hr {
  border: 1px solid $base-border-color;
  border-top: none;
}


// Webkit browsers need explicit focus styling.
// (Firefox renders a blue focus ring on inputs, even though we removed the outline, above;
// this special outline is not accessible via the standard CSS outline property.
// In FF, the following causes 2 outlines on selects, FF's built-in one and the one we defined here.
// In smst-ff we therefore remove this, for selects.)
input[type="text"],
input[type="password"],
textarea,
select {
  &:focus {
    outline: 2px solid get-brand(primary);
  }
  // ONSE-8570
  // On Webkit at least, the focus outline is rendered over the input's border.
  // When a field is focused and we have an input with a unit, this looks broken and ugly.
  // So we remove the focus outline for focused interview fields.
  .case-interview-field-focused & {
    &:focus {
      outline: none;
    }
  }
}

// NA-52
// Remove default webkit input field decoration which clashes with our styling
// TODO: check if this causes problems on Safari or Chrome on Desktop!
// TODO: possibly add other input types: checkbox, radio, button, file
// NOTE: don't apply this to select -- this will mess up selects.
// http://stackoverflow.com/questions/2918707
input[type="text"],
input[type="password"],
textarea {
  -webkit-appearance: none;
}

// Form field element selectors currently in use:
// base                     input
// case-editor-assessment           textarea
// case-editor-interview-field          input[type="text"], textarea, select
// case-editor-interview-multifield-multiform select
// case-editor-interview-tool-routes      input
// case-editor-interview-tool-spreadsheet   input
// case-editor                  input
// dialogs                    input, input[type="password"], input[type="text"], select, textarea



// UI visibility states
.ui-display-none,
.app-a11y-label {       // accessibility label: hidden from GUI browsers, but available to screen readers and search engines
  display: none !important;
}
.ui-invisible,
.yui3-overlay-hidden {
  visibility: hidden !important;
}

