// This file will contain unified styling for all report tables.
// Currently, these styles are defined individually,
// so these tables look different wherever they appear, which is bad.

// Report tables appear:
// - on intermediate result pages in the Interview,
// - as previews on the Analysis page,
// - as print reports, also on the Analysis page (via "show print version")
// - as inline reports on the Summary page
// - ... where else?


// Container around the actual report table, so we can have padding and other styling
.case-editor-report-table-container {
  padding: 20px;
}
// These are report tables shown as previews on the Analysis page,
// after a report has been selected from the Report Selector
.case-editor-analysis-report-preview-body,
.case-editor-inline-report {
  list-style: none;

  tr.has-sum td {
    background: $main-bg;
  }
  td {
    padding: 5px;
  }
  h1, h2, h3, h4, h5, h6, strong, em {
    font-weight: bold;
  }
  h1 {
    font-size: 20px;
    line-height: 24px;
  }
  h2 {
    font-size: 16px;
  }
}

.case-editor-inline-report {
  overflow: auto;
}
