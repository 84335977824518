.display-content p {
  display: contents;
}

#case-editor-filing-final {
  #case-editor-filing-lexware h2 {
    background-size: 200px;
    background-position: left;
    padding-bottom: 50px;
}
}

#case-editor-filing-final {
  .case-editor-filing-module-decorated p {
    padding: 0px 25px;
  }

  .case-editor-filing-module {
    a.case-editor-filing-module-action {
      margin: 0px
    }
  }

  .case-editor-filing-module-columns-wrapper {
    margin-top: 0px !important
  }

  .case-editor-filing-module {
    > div {
      padding-top: 0px
    }
  }

  h2 {
    font-weight: bold
  }

  .case-editor-filing-module {
    ul, li {
      margin-bottom: 0px
    }
  }

  .app-success-info {
    i.fa-check {
      vertical-align: initial;
      padding-right: 5px;
      font-size: 18px
    }
  }

  #case-editor-filing-final {
    .case-editor-filing-module ul {
      div {
        border: none !important;
        list-style: none !important;
      }
    }
  }

  div, p {
    font-size: 18px;
  }

  div, p, h2 {
    line-height: 160%;
  }

  .case-editor-filing-module-decorated {
    padding: 0;
  }

  .text-center, .shariff-align-center ul {
    text-align: left !important;
    justify-content: left;
    align-items: center;
  }

  #case-editor-filing-final {
    .app-alert div {
      padding-left: 0px;
    }
  }

  #case-editor-filing-referral-marketing {
    img {
    margin-bottom: 20px
  }
  }
  .case-editor-filing-module .btn-ghost-blue {
    margin-bottom:10px;
  }

  .case-editor-filing-module-columns-wrapper {
    .case-editor-filing-module {
      width: 50%;
    }
  }
  .app-success-info:nth-child(3n+0) ul {
    list-style: none;
    color: #54B558;
  }

  .app-success-info:nth-child(3n+0) {
    background: white;
    margin: 20px 0 0;
    margin-top: 20px;
    margin-bottom: 0px;
    padding: 20px 0 40px
  }

  .app-success-info:nth-child(2), .app-alert {
    background: #eff8ff;
    border: 0px;
    margin: 40px 20px 0px;
    padding: 0px 0px 30px 0px;
  }

  .case-editor-filing-module
  > div {
    width: 100%;
    text-align: center;
  }
}
#case-editor-filing-final {
  .app-success-info-taxyear {
    background: #F8F8F9 !important;
    padding-bottom: 40px !important;
    padding-top: 40px !important;
    margin-top: 40px
  }
}

#case-editor-filing-final {
  .app-success-info-taxyear {
    background: #F8F8F9 !important;
    padding-bottom: 40px !important;
    padding-top: 40px !important;
    margin-top: 40px
  }
}

#case-editor-filing-final{
    .app-success-info {
      background: white;
      text-align: center;
      overflow: hidden;
      line-height: 120%;
      margin-bottom: 0;
      padding-bottom: 0;
    }

    i.fa-check-circle {
      display: inline-block;
      font-size: 90px;
      line-height: 1;
      padding-left: 1px;
      vertical-align: middle;
      color: #54B558
    }
}

  .case-editor-filing-module,
  .case-editor-filing-module-overlay {
    margin-bottom: 20px;
    overflow: hidden;
    position: relative; // Needed for .case-editor-filing-module-waiting

    & > i.fa {
      box-sizing: border-box;
      color: get-font(lightColor);
      float: left;
      font-size: 120px;
      line-height: 120px;
      padding-left: 5px;
      width: 20%;
    }
    & > div {
      float: left;
      width: 80%;
    }
    .case-editor-filing-module-action {
      margin: 20px 0;
    }
    h2, h3 {
      font-weight: bold;
      margin-bottom: 10px;
    }
    h2 {
      font-size: 16px;
    }
    p {
      margin-top: 10px;

      &:first-child {
        margin-top: 0;
      }
      a:not(.btn) {
        color: get-brand(primary);

        &:hover {
          border-bottom: 1px solid get-brand(primary);
        }
      }
    }
    ol {
      list-style: decimal;

      // Type optimization for "whatNextOverlay" list headings
      h3 {
        margin: 15px 0 0 0;
      }
    }
    ul {
      list-style: square;
    }
    ol, ul {
      margin-left: 20px;
      margin-bottom: 10px;

      &.case-editor-filing-intro-list {
        border-top: 1px solid $base-border-color;
        list-style: none;
        margin: 30px 0;

        li {
          border-bottom: 1px solid $base-border-color;
          padding: 10px 0;

          i.fa {
            margin-right: 5px;
          }
        }
      }
    }
    li {
      margin-bottom: 5px;
    }
    em, strong {
      font-weight: bold;
    }
    .case-editor-filing-module-columns-wrapper & {
      float: right;
      width: 45%;

      &:first-child {
        float: left;
      }
    }
    .case-editor-cta-bar-addition {
      margin-top: 5px;
    }
  }

  .case-editor-filing-module {
    h2 {
      font-size: 24px;
      font-weight: 400;
      line-height: 1.2;
    }
    > div {
      padding: 20px;
    }
    ol.case-editor-filing-intro-list {
      margin: 10px 0 0;
      border: none;

      li {
        padding: 10px 0 0;
        border: none;
        font-size: 16px;

        em {
          color: get-brand(primary);
        }
        .fa {
          vertical-align: 0;
          color: get-font(lightColor);
          font-size: 20px;
        }
      }
      + h3 {
        padding: 15px 0 0;
        color: get-font(color);
        font-size: 13px;
        text-align: right;
      }

      .li-small {
        padding-top: 0px;
        font-size: 11px;
        color: gray;
      }
    }
    .app-alert {
      margin-bottom: 20px;
      margin-top: 20px;
    }
  }

  // Full-Width without prefix
  .case-editor-filing-module--full {
    padding-bottom: 20px;
    padding-top: 20px;
    position: relative;
    overflow: visible;

    > div {
      float: none;
      padding: 0;
      width: auto;
      border: none;
    }
    .case-editor-filing-module__title {
      font-size: 32px;
      text-align: center;
    }
    .m-product-table__column__list {
      margin: 0;
      padding: 0;
      list-style: none;
    }
    #case-editor-waiting {
      bottom: auto;
      left: 50%;
      position: absolute;
      right: auto;
      top: 50%;
    }
  }

  // Constrain height of "What next?" overlay, so we don't get an eight miles high one
  .case-editor-filing-what-next {
    height: 500px;
    overflow: scroll;
  }
  .case-editor-filing-module-waiting {
    background: $white url($action_indicator) center 60px no-repeat;
    border: 1px solid $base-border-color;
    border-radius: 5px;
    box-shadow: 0 0 20px $gray;  // NOTE: non-standard color
    color: get-font(color);
    font-size: 16px;
    font-weight: bold;
    padding: 20px 20px 50px;
    position: absolute;
    text-align: center;
    top: 70px;
  }
  label.case-editor-filing-module-state-control {
    background: $main-bg;
    border: 1px solid transparent;
    border-radius: 3px;
    cursor: pointer;
    display: inline-block;
    font-size: 14px;
    font-weight: bold;
    margin: 20px 0;
    padding: 10px 15px;

    input {
      margin-right: 5px;
      vertical-align: 1px;
    }
    &:hover {
      border-color: $base-border-color;
    }
    &.case-editor-filing-module-state-control-disabled {
      color: $gray-medium;
      background:none;
      border-color: $base-border-color;
      font-weight: normal;
      cursor: default;
      cursor: not-allowed;

      &:hover {
        background: none;
      }
    }
  }
  img.case-editor-filing-signature-example {
    background: $light-bg;
    border: 1px solid $base-border-color;
    display: inline-block;
    margin-top: 20px;
    padding: 10px;
  }

  .case-editor-filing-module-columns-wrapper {
    margin-top: 50px;
    overflow: hidden;
  }

  .case-editor-filing-module-decorated {
    padding: 15px;

    h2 {
      background-position: center bottom;
      background-repeat: no-repeat;
    }
    h3 {
      margin: 0 0 5px 0;
    }
  }
  #case-editor-filing-survey h2 {
    background-image: url($filing_survey);
    padding-bottom: 260px;
  }
#case-editor-filing-referral-marketing{
  background: none
}
  #case-editor-filing-lexware h2 {
    background-image: url($filing-lexware-teaser);
    margin-bottom: 30px;
    padding-bottom: 120px;
  }
  #case-editor-filing-lexware p {
    height: 60px;
  }

  .case-editor-filing-module .case-editor-checkout-overview-cart {
    border: 1px solid darken(get-brand(primary), 17%);
    margin-top: 20px;
  }

  #case-filing {
  .case-editor-filing-module {
    margin-top: -40px
  }
  }
